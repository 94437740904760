import { QueryClient } from 'react-query'

export default function invalidateCarts(queryClient: QueryClient) {
   function invalidate() {
      queryClient.invalidateQueries('ordersHistory')
      queryClient.invalidateQueries('mainCart')
      queryClient.invalidateQueries('savedCarts')
      queryClient.invalidateQueries('minimums')
   }

   return invalidate()
}
