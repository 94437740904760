import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { fetchCompanyData, saveCompanyData } from './services'
import { CompanySettingsType } from './types'

export function useCompanyQuery(): UseQueryResult<CompanySettingsType> {
   return useQuery('companyData', fetchCompanyData, { staleTime: 10 * (60 * 1000) })
}

export function useCompanyMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, CompanySettingsType, unknown>(
      (payload) => {
         return saveCompanyData(payload)
      },
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('companyData')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
