import React from 'react'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import parse from 'html-react-parser'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useProfileQuery } from 'shared/store/useProfile'

import { UpdatePricesDiffType } from '../../../../types'

interface Props {
   handleClose: () => void
   updatePricesDiff: UpdatePricesDiffType
}

export default function AfterUpdateModal({ handleClose, updatePricesDiff }: Props) {
   const { t } = useTranslation('MAIN_CART')
   const { data } = useProfileQuery()

   function renderDiffs() {
      return updatePricesDiff.map((item) => {
         if (item.reason === 'PRICE') {
            return (
               <Typography variant="body1">
                  {parse(
                     t('MAIN_CART:afterUpdate.priceUpdate', {
                        productIndex: item.productIndex,
                        wholesaleName: item.wholesaleName,
                        priceBefore: item.priceBefore,
                        priceAfter: item.priceAfter,
                        priceType: t(`TRANSLATION:${data?.priceType}`),
                     })
                  )}
               </Typography>
            )
         }
         return (
            <Typography variant="body1">
               {parse(
                  t('MAIN_CART:afterUpdate.availabilityUpdate', {
                     productIndex: item.productIndex,
                     wholesaleName: item.wholesaleName,
                  })
               )}
            </Typography>
         )
      })
   }

   return (
      <>
         <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, textAlign: 'center' }}>
            {renderDiffs()}
         </DialogContent>
         <DialogActions>
            <ContainedButton sx={{ margin: '0 auto' }} variant="contained" onClick={handleClose}>
               {t('TRANSLATION:confirm')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
