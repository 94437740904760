import { Yup } from 'shared/lib'

const validPostCode = /^(?:[A-Za-z]{2})?(?:\d{4}|\d{5}|\d{6}|\d{2}-\d{3}|\d{3}\s?\d{2})$/

const validCity =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž])$/

export const newLocationSchema = Yup.object().shape({
   locationName: Yup.string().min(3).max(30).required(),
   street: Yup.string().max(100).required(),
   adminUuid: Yup.string().required(),
   houseNumber: Yup.string().max(15).required(),
   country: Yup.string(),
   county: Yup.string(),
   voivodeship: Yup.string(),
   zipCode: Yup.string().matches(validPostCode, 'validation.postCodePattern').required(),
   city: Yup.string().matches(validCity, 'validation.cityPattern').required(),
})

export const locationDetailsSchema = Yup.object().shape({
   locationSettings: Yup.object().shape({
      name: Yup.string().max(32).required(),
      address: Yup.object().shape({
         street: Yup.string().max(100).required(),
         zipCode: Yup.string().matches(validPostCode, 'validation.postCodePattern').required(),
         city: Yup.string().matches(validCity, 'validation.cityPattern').required(),
         houseNumber: Yup.string().max(15).required(),
         county: Yup.string().required(),
         voivodeship: Yup.string().required(),
      }),
   }),
   autoCartsSettings: Yup.object().shape({
      autoOrderPreferences: Yup.object().shape({
         addPartialQuantityToCart: Yup.boolean(),
         preferredWholesalesUuid: Yup.array(),
         priceConditionAmount: Yup.number(),
         priceConditionPercentage: Yup.number(),
      }),
      clientCartPreferences: Yup.object().shape({
         instantOrder: Yup.boolean(),
      }),
      markupSettings: Yup.object().shape({
         thresholds: Yup.array(),
         priceType: Yup.string().oneOf(['GROSS', 'NET']),
      }),
   }),
})
