import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'

import Typography from '@mui/material/Typography'
import { StyledCard, LogoBox } from './styles'
import { ModalType } from '../../types'

interface Props {
   handleModalType: (type: ModalType) => void
}

export default function NonCoopCard({ handleModalType }: Props) {
   const { user } = useLogin()

   function handleOnClick() {
      if (user.roles.includes(UserRoles.ULTRA)) {
         handleModalType('new')
      }
   }

   return (
      <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
         <StyledCard onClick={handleOnClick}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, textAlign: 'center' }}>
               <LogoBox>
                  <SmsOutlinedIcon sx={{ height: 25, width: 'auto' }} /> <Typography variant="h6">SMS Api</Typography>
               </LogoBox>
            </Box>
         </StyledCard>
      </Grid>
   )
}
