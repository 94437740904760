import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material'

import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { ModalInfoType } from '../../types'
import ChangePasswordModal from './components/ChangePasswordModal'
import { removeUserService } from '../../services'
import CreateUserModal from './components/CreateUserModal'
import EditModal from './components/EditModal'

interface Props {
   handleClose: () => void
   handleRefetch: () => void
   modalInfo?: ModalInfoType
}

export default function Modal({ handleClose, handleRefetch, modalInfo }: Props) {
   const { t } = useTranslation(['USERS_AND_SUBSCRIPTIONS', 'TRANSLATION'])
   const { addNotification } = useNotificationContext()
   const queryClient = useQueryClient()

   const theme = useTheme()

   function removeUser() {
      if (modalInfo) {
         removeUserService(modalInfo.userInfo.uuid)
            .then((res) => {
               if (res.status === 200) {
                  addNotification('successSave', 'success')
                  queryClient.invalidateQueries('locations')
                  handleRefetch()
                  handleClose()
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
      }
   }

   function renderContent() {
      switch (modalInfo?.modalType) {
         case 'DELETE':
            return (
               <>
                  <DialogContent>
                     <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {t('USERS_AND_SUBSCRIPTIONS:deleteSureText', {
                           userName: `${modalInfo.userInfo.name} ${modalInfo?.userInfo.surname}`,
                        })}
                     </Typography>
                  </DialogContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 1, mb: 2 }}>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:no')}
                     </OutlinedButton>
                     <ContainedButton variant="contained" onClick={removeUser}>
                        {t('TRANSLATION:yes')}
                     </ContainedButton>
                  </Box>
               </>
            )
         case 'CHANGEPASSWORD':
            return <ChangePasswordModal handleClose={handleClose} uuid={modalInfo?.userInfo.uuid} />
         case 'EDIT':
            return (
               <EditModal
                  locationUuids={modalInfo.userInfo.locationUuids}
                  modalInfo={{ ...modalInfo.userInfo }}
                  handleClose={handleClose}
                  handleRefetch={handleRefetch}
               />
            )
         case 'CREATEUSER':
            return <CreateUserModal handleRefetch={handleRefetch} handleClose={handleClose} />
         default:
            return null
      }
   }
   return (
      <Dialog open={!!modalInfo?.modalType} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
               {t(`${modalInfo?.modalType?.toLowerCase()}`)}
            </Typography>
         </DialogTitle>
         {renderContent()}
      </Dialog>
   )
}
