import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import { Form, FormikProvider, useFormik } from 'formik'
import Box from '@mui/material/Box'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { pythonHttpClient } from 'shared/lib'
import InputField from 'shared/components/InputField'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'

import { EditDescType } from '../../types'
import { editDescSchema } from './schema'

interface Props {
   open: boolean
   handleClose: () => void
   productUuid: string
}

export default function EditDescriptionModal({ open, handleClose, productUuid }: Props) {
   const { addNotification } = useNotificationContext()
   const [isLoading, setIsLoading] = useState(false)

   const formik = useFormik<EditDescType>({
      initialValues: {
         description: '',
         name: '',
      },
      validationSchema: editDescSchema,
      onSubmit: handleSave,
   })

   const { values, setValues, setFieldValue } = formik

   function handleSave() {
      setIsLoading(true)
      pythonHttpClient
         .patch(`products/${productUuid}/description`, {
            ...values,
         })
         .then((res) => {
            if (res.status === 204) {
               addNotification('successSave', 'success')
               handleClose()
            }
         })
         .catch(() => addNotification('failedSave', 'error'))
         .finally(() => setIsLoading(false))
   }

   function copyName() {
      if (values.name) {
         setFieldValue('description', values.name)
      }
   }

   useEffect(() => {
      setIsLoading(true)
      pythonHttpClient
         .get(`/products/${productUuid}/description`)
         .then((res) => {
            setValues({
               ...res.data,
            })
         })
         .finally(() => setIsLoading(false))
   }, [])

   return (
      <Dialog maxWidth="xl" open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography color="primary" variant="h4">
               Edytuj opis hurtowni
            </Typography>
         </DialogTitle>
         <DialogContent>
            <FormikProvider value={formik}>
               <Form>
                  <Box sx={{ py: 2, px: 4, minWidth: 500, display: 'flex', flexDirection: 'column', gap: 2 }}>
                     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <InputField
                           disabled={isLoading}
                           fullWidth
                           sx={{ minWidth: 100 }}
                           name="name"
                           label="Nazwa produktu"
                           multiline
                           rows={3}
                        />
                        <ContainedButton sx={{ alignSelf: 'center' }} variant="contained" onClick={copyName}>
                           <ArrowDownwardIcon />
                        </ContainedButton>
                        <InputField
                           disabled={isLoading}
                           fullWidth
                           sx={{ minWidth: 100 }}
                           name="description"
                           label="Opis produktu"
                           multiline
                           rows={3}
                        />
                     </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 1 }}>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        Anuluj
                     </OutlinedButton>
                     <ContainedButton disabled={isLoading} variant="contained" type="submit">
                        Zapisz
                     </ContainedButton>
                  </Box>
               </Form>
            </FormikProvider>
         </DialogContent>
      </Dialog>
   )
}
