import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'

import { LocationType } from 'shared/store/useLocations/types'
import StyledLocationTab from 'shared/components/LocationTab'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

interface Props {
   active?: LocationType
   openModal: () => void
   list?: LocationType[]
   onChangeLocation: (id: string) => void
}

export default function LocationsList({ openModal, list = [], onChangeLocation, active }: Props) {
   const { t } = useTranslation('LOCATIONS')
   const { user } = useLogin()

   return (
      <Grid item xs={12} md={2}>
         <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 6, mb: 1 }}>
            {active && (
               <Tabs sx={{ width: '100%' }} orientation="vertical" value={active.locationSettings.uuid}>
                  {list?.map((location) => (
                     <StyledLocationTab
                        activeLocationUuid={active.locationSettings.uuid}
                        locationUuid={location.locationSettings.uuid}
                        key={location.locationSettings.uuid}
                        value={location.locationSettings.uuid}
                        disabled={
                           (!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) &&
                              location.locationSettings.adminUuid !== user.uuid) ||
                           location.locationSettings.disabled
                        }
                        label={location.locationSettings.name}
                        onClick={() => onChangeLocation(location.locationSettings.uuid)}
                     />
                  ))}
                  {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && user.roles.includes(UserRoles.ULTRA) ? (
                     <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <Tooltip placement="bottom" title={t('newLocation') || ''}>
                           <Fab sx={{ width: 35, height: 35 }} color="primary" onClick={openModal}>
                              <AddIcon sx={{ color: 'white', fontSize: 26 }} />
                           </Fab>
                        </Tooltip>
                     </Box>
                  ) : null}
               </Tabs>
            )}
         </Box>
      </Grid>
   )
}
