import React, { useMemo, ReactNode } from 'react'
import { createTheme, StyledEngineProvider, ThemeOptions, ThemeProvider } from '@mui/material/styles'

import { CssBaseline } from '@mui/material'
import shape from './shape'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import sizes from './sizes'
import shadows, { customShadows } from './shadows'
import componentsOverrides from './overrides'

// Create a theme instance.

type ThemeConfigProps = {
   children: ReactNode
}

export function ThemeConfig({ children }: ThemeConfigProps) {
   const themeOptions: ThemeOptions = useMemo(
      () => ({
         palette: { ...palette.light },
         shape,
         typography,
         breakpoints,
         shadows: shadows.light,
         customShadows: customShadows.light,
         sizes,
      }),
      []
   )

   const theme = createTheme(themeOptions)
   theme.components = componentsOverrides(theme)

   return (
      <StyledEngineProvider injectFirst>
         <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
         </ThemeProvider>
      </StyledEngineProvider>
   )
}
