import React from 'react'
import { useTranslation } from 'react-i18next'

import { useRemoveCartMutation } from 'shared/store/useSavedCarts'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'

interface Props {
   uuid?: string
   handleClose: () => void
   cartName?: string
}

export default function DeleteModal({ uuid, handleClose, cartName }: Props) {
   const { mutate, isLoading } = useRemoveCartMutation()
   const { t } = useTranslation(['TRANSLATION', 'SAVED_CARTS'])

   function handleDelete() {
      if (uuid) {
         mutate(uuid, {
            onSettled: handleClose,
         })
      }
   }

   return (
      <div>
         {t('SAVED_CARTS:deleteText', { cartName })}
         <div style={{ width: '100%', marginTop: 15, display: 'flex', gap: 5, justifyContent: 'flex-end' }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton disabled={isLoading} variant="contained" onClick={() => handleDelete()}>
               {t('TRANSLATION:delete')}
            </ContainedButton>
         </div>
      </div>
   )
}
