import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { UnAuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'

export default function OrderReflinkComplete() {
   const { t } = useTranslation('UNAUTHORIZED')
   return (
      <>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: { md: 10, xs: 3 }, py: 1 }}>
            <LanguagePopover />
         </Box>
         <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20%', textAlign: 'center' }}>
            <Typography variant="h2" color="grayText">
               {t('orderReflinkComplete.title')}
            </Typography>
         </Box>
      </>
   )
}
