import { styled } from '@mui/material'
import Button from '@mui/material/Button'

const OutlinedButton = styled(Button)(({ theme }) => ({
   borderColor: theme.palette.primary.light,
   borderRadius: 3,
   textTransform: 'uppercase',
   fontSize: 11,
   fontWeight: 'bold',
   backgroundColor: 'white',
   color: theme.palette.primary.light,
}))

export default OutlinedButton
