import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { FormHelperText, StandardTextFieldProps } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'

interface Props extends StandardTextFieldProps {
   name: string
   label: string
   inputDisabled?: boolean
   options: {
      item: React.ReactElement
      value: string
   }[]
}

export default function SelectWholesaleField({ name, label, options, inputDisabled }: Props) {
   const { t } = useTranslation('SETTINGS')
   const [fieldProps, meta] = useField(name)

   return (
      <FormControl error={!!meta.error} component="fieldset" variant="outlined">
         <InputLabel>{label}</InputLabel>
         <Select
            label={label}
            error={Boolean(meta.error) && Boolean(meta.touched)}
            value={fieldProps.value}
            multiple
            sx={{ minWidth: 200 }}
            disabled={inputDisabled}
            MenuProps={{
               PaperProps: {
                  style: { maxHeight: '350px' },
               },
            }}
            onChange={fieldProps.onChange}
            name={name}
         >
            {options.map((option) => option.item)}
         </Select>
         <FormHelperText sx={{ color: 'red' }}>{meta.error && t(meta.error)}</FormHelperText>
      </FormControl>
   )
}
