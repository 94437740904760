import { styled } from '@mui/material/styles'

export const ContentStyle = styled('div')({
   margin: '0 auto',
   display: 'flex',
   height: 'calc(100vh - 212px - 97px)',
   flexDirection: 'column',
   justifyContent: 'center',
})

export const StyledLink = styled('span')(({ theme }) => ({
   marginLeft: 5,
   textDecoration: 'none',
   color: theme.palette.primary.dark,
   cursor: 'pointer',
   '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.darker,
   },
}))

export const StyledContactLink = styled('a')(({ theme }) => ({
   textDecoration: 'none',
   color: theme.palette.primary.dark,
   '&:hover': {
      color: theme.palette.primary.darker,
   },
}))
