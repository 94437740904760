export default function getFormattedDeliveryTime(minutes: number) {
   switch (true) {
      case minutes > 600:
         return '> 10 h'
      case minutes === 0:
         return '0 min'
      case minutes < 5:
         return '< 5 min'
      case minutes > 300:
         return '> 5 h'
      case minutes > 240:
         return '> 4 h'
      case minutes > 180:
         return '> 3 h'
      case minutes > 120:
         return '> 2 h'
      case minutes > 60:
         return '> 1 h'
      default:
         return `${minutes} min`
   }
}
