import { AxiosError } from 'axios'
import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

export function validateSmsCodeService(reflinkUuid: string, smsCode: string) {
   let errMessage = ''
   return httpClient
      .post<string>(`/client-carts/reflinks/${reflinkUuid}/verification-codes/${smsCode}`)
      .then((res) => ({ status: res.status, data: res.data }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT_CART_REFLINK validateSmsCode service', errMessage, 'error')
         throw error
      })
}
