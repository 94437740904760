import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

export function sendLogoService(logo: FormData) {
   let errMessage = ''
   return httpClient
      .put<Result>('companies/logo', logo)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS HISTORY changeOrderName service', errMessage, 'error')
         throw error
      })
}

export function removeLogoService() {
   let errMessage = ''
   return httpClient
      .delete<Result>('companies/logo')
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS HISTORY changeOrderName service', errMessage, 'error')
         throw error
      })
}
