import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import React from 'react'

import { useWatchedProductsQuery } from 'shared/store/useWatchedProducts'

import WatchedProduct from './components/WatchedProduct'
import { pl, en, de } from './locales'

export default function Watchlist() {
   const { data, isFetched } = useWatchedProductsQuery()

   const { t, i18n } = useTranslation('WATCHLIST')
   i18n.addResourceBundle('pl-PL', 'WATCHLIST', pl)
   i18n.addResourceBundle('en-EN', 'WATCHLIST', en)
   i18n.addResourceBundle('de-DE', 'WATCHLIST', de)

   function renderContent() {
      if (isFetched && data) {
         if (data?.length > 0) {
            return data.map((product) => (
               <WatchedProduct
                  key={product.uuid}
                  index={product.productIndex}
                  uuid={product.uuid}
                  name={product.productName}
                  date={product.createdDate}
                  productUuid={product.productUuid}
               />
            ))
         }
         return (
            <Box>
               <Typography variant="h5" color="primary">
                  {t('noResults')}
               </Typography>
            </Box>
         )
      }
      return (
         <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
         </Box>
      )
   }

   return (
      <Box>
         <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal', mb: 2 }}>
            {t('watchlistTitle')}
         </Typography>
         {renderContent()}
      </Box>
   )
}
