import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { ReflinksResult, CreateReflinkPayload } from './types'

export function fetchReflinks() {
   let errMessage = ''

   return httpClient
      .get<ReflinksResult>('/client-carts/reflinks')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT REFLINK fetchReflinks service', errMessage, 'error')
         throw error
      })
}

export function createReflink(payload: CreateReflinkPayload) {
   let errMessage

   return httpClient
      .post(`/client-carts/reflinks`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT REFLINK createReflink service', errMessage, 'error')
         throw error
      })
}

export function deleteReflink(reflinkUuid: string) {
   let errMessage

   return httpClient
      .delete(`/client-carts/reflinks/${reflinkUuid}`)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT REFLINK deleteReflink service', errMessage, 'error')

         throw error
      })
}
