import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'

import pageNotFound from 'assets/ilustrations/404.png'

const RootStyle = styled('div')(() => ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}))

export default function Page404() {
   const { t } = useTranslation('TRANSLATION')
   const location = useLocation()
   const navigate = useNavigate()

   useEffect(() => {
      if (window.localStorage.getItem('ACCESS_TOKEN')) {
         const timer = setTimeout(() => navigate('/dashboard'), 3000)
         return () => clearTimeout(timer)
      }
      const timer = setTimeout(() => navigate('/'), 3000)
      return () => clearTimeout(timer)
   }, [location, navigate])

   return (
      <RootStyle>
         <Container>
            <Box sx={{ maxWidth: 'sm', margin: 'auto', textAlign: 'center' }}>
               <Typography variant="h3" paragraph>
                  {t('404.errorTitle')}
               </Typography>

               <img src={pageNotFound} alt="pageNotFound" height="240px" width="auto" />
               <Typography sx={{ color: 'text.secondary' }}>{t('404.errorText')}</Typography>
               <Button
                  sx={{ color: 'white', mt: 2 }}
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  to={location.pathname.includes('dashboard') ? '/dashboard' : '/'}
               >
                  {t('404.errorButton')}
               </Button>
            </Box>
         </Container>
      </RootStyle>
   )
}
