import { styled } from '@mui/material'

export const StyledCenteredContent = styled('div')({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
})

export const ButtonContainer = styled('div')(({ theme }) => ({
   display: 'flex',
   gap: 12,
   [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
   },
   justifyContent: 'center',
}))

export const StyledTableWrapper = styled('div')({
   maxWidth: '100%',
   '.MuiTableCell-root': {
      padding: '6px 8px 6px 8px',
      textAlign: 'center',
   },
   '.MuiTableCell-head': {
      backgroundColor: 'rgba(33,147,224, 0.1)',
      color: 'grayText',
   },
})
