import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'

import { DeleteWatchedProductPayload } from 'shared/store/useWatchedProducts/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useRemoveWatchedProduct } from 'shared/store/useWatchedProducts'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import { StyledBox, StyledDeleteIcon, StyledTypography } from '../../styles'

interface Props {
   index: string
   uuid: string
   name: string
   date: string
   productUuid: string
}

export default function WatchedProduct({ index, name, uuid, date, productUuid }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WATCHLIST'])
   const theme = useTheme()
   const navigate = useNavigate()
   const [open, setOpen] = useState(false)
   const { mutate } = useRemoveWatchedProduct()

   function handleDelete() {
      const payload: DeleteWatchedProductPayload = {
         uuid,
      }
      mutate(payload)
   }

   return (
      <>
         <StyledBox onClick={() => navigate({ pathname: `/dashboard/product`, search: `?uuid=${productUuid}` })}>
            <Stack sx={{ paddingLeft: 3 }} spacing={5} direction={{ xs: 'column', md: 'row' }}>
               <Stack>
                  {name.length > 20 ? (
                     <Tooltip title={name} placement="top-end">
                        <StyledTypography variant="h6">
                           {name.length > 40 ? name.slice(0, 40).concat('...') : name}
                        </StyledTypography>
                     </Tooltip>
                  ) : (
                     <StyledTypography variant="h6">{name}</StyledTypography>
                  )}
                  <Stack spacing={{ md: 1, xs: 0 }} direction={{ xs: 'column', md: 'row' }}>
                     <Typography variant="subtitle2">{t('WATCHLIST:watchedProduct.indexLabel')}</Typography>
                     <StyledTypography variant="body2">{index}</StyledTypography>
                  </Stack>
                  <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                     <Typography variant="subtitle2">{t('WATCHLIST:watchedProduct.dateLabel')}</Typography>
                     <StyledTypography variant="body2">{date}</StyledTypography>
                  </Stack>
               </Stack>
            </Stack>
            <IconButton
               onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setOpen(true)
               }}
               sx={{ top: 5, right: 5, position: 'absolute' }}
            >
               <StyledDeleteIcon />
            </IconButton>
         </StyledBox>
         <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
               <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
                  {t('WATCHLIST:watchedProduct.deleteModalTitle')}
               </Typography>
            </DialogTitle>
            <DialogContent>
               <Typography variant="body1" sx={{ mt: 1, color: 'text.secondary' }}>
                  {t('WATCHLIST:watchedProduct.deleteModalContent')}
               </Typography>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
               <OutlinedButton variant="outlined" onClick={() => setOpen(false)}>
                  {t('TRANSLATION:cancel')}
               </OutlinedButton>
               <ContainedButton variant="contained" onClick={() => handleDelete()}>
                  {t('TRANSLATION:delete')}
               </ContainedButton>
            </DialogActions>
         </Dialog>
      </>
   )
}
