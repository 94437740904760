import { Product } from 'shared/store/useCartSummary/types'

export default function getProductName(product: Product) {
   if (product.cartProductName) {
      return product.cartProductName.split(' ').length === 1
         ? product.cartProductName.split(' ').slice(0, 1).join(' ')
         : product.cartProductName.split(' ').slice(0, 2).join(' ')
   }
   return product.index
}
