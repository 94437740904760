import React from 'react'

import { UserRoles } from 'shared/consts'
import useLogin from 'shared/store/useLogin'

interface Props {
   wholesalerName: string
   imgHeight: string
   imgWidth: string
}

export default function HiddenWholesalerLogo({ wholesalerName, imgHeight, imgWidth }: Props) {
   const { user } = useLogin()

   if (user.roles.includes(UserRoles.SALES_REP)) {
      return <span>{wholesalerName}</span>
   }
   return (
      <img
         src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${wholesalerName}.png`}
         height={imgHeight}
         width={imgWidth}
         alt={wholesalerName}
      />
   )
}
