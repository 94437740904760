import { styled } from '@mui/material'
import Tab from '@mui/material/Tab'

const StyledLocationTab = styled(Tab)(
   ({ locationUuid, activeLocationUuid }: { locationUuid: string; activeLocationUuid: string }) => ({
      minWidth: '100%',
      backgroundColor: locationUuid === activeLocationUuid ? 'rgba(228,238,247,255)' : 'transparent',
      lineBreak: 'loose',
      fontSize: 13,
      color: locationUuid === activeLocationUuid ? 'main' : 'grayText',
      '&:hover': {
         color: 'primary',
         backgroundColor: '#E4EEF7',
      },
   })
)

export default StyledLocationTab
