import { styled, keyframes } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import { IconButton } from '@mui/material'

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
   flexDirection: 'row',
   height: theme.sizes.headerSm,
   backgroundColor: theme.palette.background.default,
   zIndex: theme.zIndex.drawer + 1,
   position: 'fixed',
}))

export const StyledLink = styled('a')(({ theme }) => ({
   color: 'grayText',
   textDecoration: 'none',
   '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
   },
}))

const shakeAnimation = keyframes`
      0% { transform:translate(0,0) }
     1.78571% { transform:translate(3px,0) }
     3.57143% { transform:translate(0,0) }
     5.35714% { transform:translate(3px,0) }
     7.14286% { transform:translate(0,0) }
     8.92857% { transform:translate(3px,0) }
     10.71429% { transform:translate(0,0) }
     100% { transform:translate(0,0) }
   `

export const StyledIconButton = styled(IconButton)(({ unreadexist }: { unreadexist: boolean }) => ({
   animation: unreadexist ? `${shakeAnimation} 4.72s ease infinite` : 'none',
}))

export const StyledLogo = styled('img')(({ theme }) => ({
   display: 'block',
   maxHeight: theme.sizes.headerSm,
   maxWidth: 100,
   [theme.breakpoints.up('lg')]: {
      maxWidth: 180,
   },
   [theme.breakpoints.down('md')]: {
      display: 'none',
   },
}))

export const StyledSmallLogo = styled('img')(({ theme }) => ({
   display: 'block',
   maxHeight: theme.sizes.headerSm,
   maxWidth: 40,
   [theme.breakpoints.up('md')]: {
      display: 'none',
   },
   [theme.breakpoints.down('sm')]: {
      maxWidth: 30,
   },
}))
