import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

type CompanyData = {
   companyName: string
   houseNumber: string
   city: string
   street: string
   postalCode: string
   county: string
   voivodeship: string
}

export default async function getCompanyData(taxId: string): Promise<CompanyData> {
   let errMessage = ''
   return httpClient
      .get(`gus/${taxId}`)
      .then((res) => {
         return res.data
      })
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SHARED_SERVICES getCompanyData service', errMessage, 'error')
         throw error
      })
}
