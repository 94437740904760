import { Yup } from 'shared/lib'

export const schema = Yup.object().shape({
   deposit_index: Yup.string().nullable(),
   deposit_price: Yup.string().nullable(),
   wholesaler_index: Yup.string().required(),
   times: Yup.number().required().moreThan(0),
   catalog_price: Yup.number().nullable(),
   quantity: Yup.number().nullable(),
})
