import { styled } from '@mui/material'
import Button from '@mui/material/Button'

const ContainedButton = styled(Button)(({ theme }) => ({
   borderRadius: 3,
   textTransform: 'uppercase',
   fontSize: 11,
   fontWeight: 'bold',
   backgroundColor: theme.palette.primary.light,
   color: 'white',
}))

export default ContainedButton
