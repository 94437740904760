import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { changeMessageStatus, fetchMessages } from './services'
import { ChangeMessageStatusPayload, FetchMessagesResult } from './types'

export function useMessagesQuery(page: number, pageSize: number): UseQueryResult<FetchMessagesResult> {
   return useQuery('messages', () => fetchMessages(page, pageSize), { staleTime: 10 * (60 * 1000) })
}

export function usePagingMessages() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<FetchMessagesResult, AxiosError<ErrorType> | Error, { page: number; pageSize: number }, unknown>(
      ({ page, pageSize }) => fetchMessages(page, pageSize),
      {
         onSuccess: (data) => {
            queryClient.setQueryData('messages', data)
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useChangeMessageStatusMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, ChangeMessageStatusPayload, unknown>(
      (payload) => changeMessageStatus(payload),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('messages')
               queryClient.invalidateQueries('userProfile')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
