import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { useProfileQuery } from 'shared/store/useProfile'
import { UserRoles } from 'shared/consts'
import useLogin from 'shared/store/useLogin'

import AccessDenied from '../AccessDenied'

interface Props {
   component: React.ComponentType
   roles: UserRoles[]
   needSub?: boolean
}

function PrivateRoute({ component: RouteComponent, roles, needSub }: Props) {
   const { user } = useLogin()
   const { data: userData } = useProfileQuery()
   const isAuthenticated = user.loginStatus === 'logged'
   const userHasRequiredRole = isAuthenticated && roles.some((role) => user.roles.indexOf(role) >= 0)
   const userHasRequiredSub = !needSub || user.roles.includes(UserRoles.SUBSCRIPTION)

   // eslint-disable-next-line @typescript-eslint/no-empty-function
   useEffect(() => {}, [userData?.locationUuid])

   if (userHasRequiredRole && userHasRequiredSub) {
      return <RouteComponent />
   }

   if (isAuthenticated && (!userHasRequiredRole || !userHasRequiredSub)) {
      return <AccessDenied />
   }

   return <Navigate to="/" />
}

export default PrivateRoute
