import { styled } from '@mui/material'

const RadiusTableWrapper = styled('div')({
   borderRadius: '10px',
   overflow: 'hidden',
   width: 'max-content',
   boxShadow: '0 0 2px rgba(145,158,171,0.24), 0 16px 32px -4px rgba(145,158,171,0.24)',
})

export default RadiusTableWrapper
