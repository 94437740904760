export function sortBoolean(valueA: boolean, valueB: boolean) {
   if (valueA < valueB) {
      return -1
   }
   if (valueA > valueB) {
      return 1
   }
   return 0
}

export function sortByDate(valueA: string, valueB: string) {
   const dateA = new Date(valueA).getTime()
   const dateB = new Date(valueB).getTime()
   if (dateA < dateB) {
      return -1
   }
   if (dateA > dateB) {
      return 1
   }
   return 0
}

export function sortNumber(valueA: number, valueB: number) {
   if (valueA < valueB) {
      return -1
   }
   if (valueA > valueB) {
      return 1
   }
   return 0
}

export function sortAlphabetical(valueA: string | null, valueB: string | null) {
   if (!valueA) {
      return 1
   }

   if (!valueB) {
      return -1
   }

   if (valueA.toLowerCase() < valueB.toLowerCase()) {
      return -1
   }
   if (valueA.toLowerCase() > valueB.toLowerCase()) {
      return 1
   }
   return 0
}
