import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DoneIcon from '@mui/icons-material/Done'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import React from 'react'

import { useAddServiceMutation, useCartSummaryQuery } from 'shared/store/useCartSummary'
import { StyledTableHeadCell } from 'views/Authorized/views/ErpProductCard/styles'
import useAdditionalServicesQuery from 'shared/store/useAddtionalServices'
import { StyledTableCell } from 'shared/components/StyledTableComponents'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useProfileQuery } from 'shared/store/useProfile'

interface Props {
   handleClose: () => void
   cartUuid: string
}

export default function AddServiceModal({ handleClose, cartUuid }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { data: userData } = useProfileQuery()
   const { mutate, isLoading: isMutateLoading } = useAddServiceMutation()
   const { data: cart } = useCartSummaryQuery()
   const navigate = useNavigate()
   const { addNotification } = useNotificationContext()
   const { data } = useAdditionalServicesQuery(userData?.locationUuid)

   function addToCart(serviceUuid: string) {
      mutate(
         { cartUuid, serviceUuid },
         {
            onSuccess: () => addNotification('successSave', 'success'),
            onError: () => addNotification('failedSave', 'error'),
         }
      )
   }

   return (
      <>
         <DialogContent sx={{ minWidth: 400 }}>
            {!data ? (
               <Box sx={{ textAlign: 'center' }}>
                  <CircularProgress color="primary" />
               </Box>
            ) : (
               <Box>
                  <Table>
                     <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                        <TableRow>
                           <StyledTableHeadCell>{t('TRANSLATION:formFields.serviceName')}</StyledTableHeadCell>
                           <StyledTableHeadCell>{t('TRANSLATION:columnNames.netPrice')}</StyledTableHeadCell>
                           <StyledTableHeadCell>VAT</StyledTableHeadCell>
                           <StyledTableHeadCell>{t('TRANSLATION:columnNames.grossPrice')}</StyledTableHeadCell>
                           <StyledTableHeadCell />
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {data.additionalServices?.length > 0 ? (
                           data.additionalServices.map((item) => (
                              <TableRow>
                                 <StyledTableCell>{item.name}</StyledTableCell>
                                 <StyledTableCell>{item.formattedDefaultNetPrice}</StyledTableCell>
                                 <StyledTableCell>{item.taxRate}</StyledTableCell>
                                 <StyledTableCell>{item.formattedDefaultGrossPrice}</StyledTableCell>
                                 <StyledTableCell>
                                    <Button
                                       color={
                                          cart?.additionalServices.find((service) => item.uuid === service.uuid)
                                             ? 'success'
                                             : 'primary'
                                       }
                                       disabled={isMutateLoading}
                                       onClick={() => addToCart(item.uuid)}
                                       variant="outlined"
                                    >
                                       {cart?.additionalServices.find((service) => item.uuid === service.uuid) ? (
                                          <Box sx={{ fontSize: 12, display: 'flex', justifyItems: 'center', gap: 1 }}>
                                             {t('MAIN_CART:services.addedServiceButton')} <DoneIcon color="success" />
                                          </Box>
                                       ) : (
                                          t('MAIN_CART:services.addServiceButton')
                                       )}
                                    </Button>
                                 </StyledTableCell>
                              </TableRow>
                           ))
                        ) : (
                           <TableRow>
                              <StyledTableCell sx={{ color: 'GrayText' }} colSpan={5}>
                                 {t('MAIN_CART:services.noServices')}
                              </StyledTableCell>
                           </TableRow>
                        )}
                     </TableBody>
                  </Table>
               </Box>
            )}
         </DialogContent>
         <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:close')}
            </OutlinedButton>
            <ContainedButton variant="contained" onClick={() => navigate('/dashboard/additional-services')}>
               {t('MAIN_CART:services.addNewServices')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
