import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useSetActiveCartMutation } from 'shared/store/useSavedCarts'
import { useNavigate } from 'react-router-dom'

interface Props {
   cartName: string
   cartUuid: string
   handleClose: () => void
}

export default function ClientCartCreatedNotification({ cartName, cartUuid, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'NOTIFICATIONS'])
   const { mutate, isLoading } = useSetActiveCartMutation()
   const navigate = useNavigate()

   function handleSetAsMain() {
      mutate(cartUuid, {
         onSuccess: () => navigate('/dashboard/main-cart'),
      })
   }

   return (
      <div>
         <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
            {t('NOTIFICATIONS:clientCartCreatedText', { cartName })}
         </Typography>

         <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton variant="contained" disabled={isLoading} onClick={handleSetAsMain}>
               {t('NOTIFICATIONS:setAsMain')}
            </ContainedButton>
         </Box>
      </div>
   )
}
