import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'

import { LocationDetailsType } from 'shared/store/useLocations/types'
import useWholesalers from 'shared/store/useWholesalers'
import SwitchField from 'shared/components/SwitchField'
import InputField from 'shared/components/InputField'

import SelectWholesaleField from './components/SelectWholesaleField'
import createConfigService from './services/createConfigService'
import { WholesalesSelectOptionType } from '../../types'

interface Props {
   locationUuid: string
}

export default function AutoOrderingSettings({ locationUuid }: Props) {
   const { data, isFetched } = useWholesalers(locationUuid)
   const { values, setValues } = useFormikContext<LocationDetailsType>()
   const [options, setOptions] = useState<WholesalesSelectOptionType[]>([])
   const { t } = useTranslation(['LOCATIONS', 'TRANSLATION'])

   useEffect(() => {
      if (isFetched && data) {
         const tempOptions: WholesalesSelectOptionType[] = []
         data.cooperating
            .filter((item) => item.cooperationStatus === 'ACCEPTED')
            .map((item) => {
               return tempOptions.push({
                  item: (
                     <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} value={item.wholesaleUuid}>
                        <img
                           height={20}
                           src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${item.wholesaleName}.png`}
                           alt={item.wholesaleName}
                        />
                     </MenuItem>
                  ),
                  value: item.wholesaleUuid,
               })
            })
         setOptions(tempOptions)
      }
   }, [data])

   function renderContent() {
      if (data && isFetched) {
         if (data.cooperating) {
            return (
               <Box
                  sx={{
                     py: 2,
                     px: { md: '20%', xs: 5 },
                     minHeight: 400,
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     flexDirection: 'column',
                     gap: 3,
                  }}
               >
                  <Typography variant="h6" fontWeight="bold">
                     {t('LOCATIONS:chooseWholesales')}
                  </Typography>
                  <SelectWholesaleField
                     label={t('LOCATIONS:preferredWholesales')}
                     name="autoCartsSettings.autoOrderPreferences.preferredWholesalesUuid"
                     options={options}
                  />
                  <Typography variant="h6" fontWeight="bold">
                     {t('LOCATIONS:addIfPrice')}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                     <InputField
                        name="autoCartsSettings.autoOrderPreferences.priceConditionPercentage"
                        onFocus={(e) => e.target.select()}
                        type="Number"
                        inputProps={{
                           min: 0,
                        }}
                        fullWidth
                        label={`${t('TRANSLATION:columnNames.price')} (%)`}
                     />
                     <Typography variant="h6" fontWeight="bold">
                        {t('LOCATIONS:or')}
                     </Typography>
                     <InputField
                        name="autoCartsSettings.autoOrderPreferences.priceConditionAmount"
                        onFocus={(e) => e.target.select()}
                        type="Number"
                        fullWidth
                        inputProps={{ min: 0 }}
                        label={t('LOCATIONS:priceInCurrency')}
                     />
                  </Box>
                  <SwitchField
                     label={t('LOCATIONS:partialQuantity')}
                     name="autoCartsSettings.autoOrderPreferences.addPartialQuantityToCart"
                  />
                  <Typography variant="h6">Koszyki klientów</Typography>
                  <SwitchField
                     name="autoCartsSettings.clientCartPreferences.instantOrder"
                     label={t('LOCATIONS:instantOrder')}
                  />
               </Box>
            )
         }
         return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
               <Typography variant="h5">{t('LOCATIONS:noWholesales')}</Typography>
            </Box>
         )
      }
      return (
         <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <CircularProgress />
         </Box>
      )
   }

   useEffect(() => {
      if (values.autoCartsSettings.locationUuid === null) {
         createConfigService(locationUuid, {
            autoOrderPreferences: {
               preferredWholesalesUuid: [],
               priceConditionAmount: 0,
               priceConditionPercentage: 0,
               addPartialQuantityToCart: false,
            },
            clientCartPreferences: {
               instantOrder: false,
            },
         }).then((createRes) => {
            if (createRes.status === 204) {
               setValues({
                  ...values,
                  autoCartsSettings: {
                     autoOrderPreferences: {
                        preferredWholesalesUuid: [],
                        priceConditionAmount: 0,
                        priceConditionPercentage: 0,
                        addPartialQuantityToCart: false,
                     },
                     clientCartPreferences: {
                        instantOrder: false,
                     },
                     locationUuid,
                  },
               })
            }
         })
      }
   }, [locationUuid])

   return (
      <Box
         sx={{
            py: 2,
            px: { md: '25%', xs: 0 },
            minHeight: 400,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
         }}
      >
         {renderContent()}
      </Box>
   )
}
