import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'

import Typography from '@mui/material/Typography'
import { StyledCard, DeleteCorner, EditCorner, LogoBox } from './styles'
import { ModalType } from '../../types'

interface Props {
   handleModalType: (type: ModalType) => void
}

export default function CoopCard({ handleModalType }: Props) {
   return (
      <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
         <StyledCard>
            <DeleteCorner onClick={() => handleModalType('delete')}>
               <DeleteIcon />
            </DeleteCorner>
            <EditCorner onClick={() => handleModalType('edit')}>
               <EditIcon />
            </EditCorner>
            <LogoBox>
               <SmsOutlinedIcon color="primary" sx={{ height: 25, width: 'auto' }} />{' '}
               <Typography color="primary" variant="h6">
                  SMS Api
               </Typography>
            </LogoBox>
         </StyledCard>
      </Grid>
   )
}
