export enum LocalStorageKeys {
   ACCESS_TOKEN = 'ACCESS_TOKEN',
   REFRESH_TOKEN = 'REFRESH_TOKEN',
   EXPIRES_IN = 'EXPIRES_IN',
}

export enum UserRoles {
   MAIN_LOCALIZATION_ADMIN = 'MAIN_LOCALIZATION_ADMIN',
   PREMIUM = 'PREMIUM',
   SUPER_TESTER = 'SUPER_TESTER',
   BASIC = 'BASIC',
   DEMO = 'DEMO',
   PRO = 'PRO',
   TESTER = 'TESTER',
   ULTRA = 'ULTRA',
   REGISTERED_USER = 'REGISTERED_USER',
   SUBSCRIPTION = 'SUBSCRIPTION',
   PZU = 'PZU',
   INTERNAL = 'INTERNAL',
   SALES_REP = 'SALES_REP',
   LOCALIZATION_ADMIN = 'LOCALIZATION_ADMIN',
   DATA_MANAGER = 'DATA_MANAGER',
}

export enum ParametersEnum {
   LOGIN = 'loginParameter',
   PASSWORD = 'passwordParameter',
   ADDITIONAL_PARAMETER = 'additionalParameter',
}

export const MAX_PRODUCT_QUANTITY = 600
