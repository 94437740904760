// FacebookPixel.ts
export function initGoogleTag(tag: string) {
   const script = document.createElement('script')
   script.async = true
   script.src = 'https://www.googletagmanager.com/gtag/js?id=G-YQ1QMYY4QB'
   document.head.appendChild(script)

   script.onload = () => {
      //   ;(window as any).fbq('init', '786030555508218')
      ;(window as any).fbq('track', tag)
   }
}
