import { OrderProduct } from 'views/Authorized/views/OrdersHistory/types'

export default function getProductName(product: OrderProduct) {
   if (product.name) {
      return product.name.split(' ').length === 1
         ? product.name.split(' ').slice(0, 1).join(' ')
         : product.name.split(' ').slice(0, 2).join(' ')
   }
   return product.index
}
