import { Yup } from 'shared/lib'

const schema = Yup.object().shape({
   sae: Yup.string(),
   capacity: Yup.string(),
   standard: Yup.string(),
   producer: Yup.string(),
})

export default schema
