import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

export async function addToCartService(payload: AddToCartServicePayload, cartUuid: string) {
   let errMessage = ''

   return httpClient
      .post(`/carts/${cartUuid}/products`, payload)
      .then((res) => res)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SHARED_SERVICES addToCart service', errMessage, 'error')
         throw error
      })
}
