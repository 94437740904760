import React from 'react'
import { HourDiv } from './styles'

function translateDeliveryTime(minutes: number) {
   switch (true) {
      case minutes === 0:
         return (
            <HourDiv color="red" backgroundColor="rgba(255,0,0,0.29)">
               0 min
            </HourDiv>
         )
      case minutes < 5:
         return <HourDiv color="red" backgroundColor="rgba(255,0,0,0.29)">{`< 5 min`}</HourDiv>
      case minutes >= 60:
         // eslint-disable-next-line no-case-declarations
         const test = Math.floor(minutes / 60)
         return <HourDiv color="green" backgroundColor="rgba(89,255,0,0.39)">{`> ${test} h`}</HourDiv>
      default:
         return <HourDiv color="orange" backgroundColor="rgba(255,255,0,0.23)">{`${minutes} min`}</HourDiv>
   }
}

export default function DeliveryTime(minutes: number) {
   if (minutes > 0) {
      return translateDeliveryTime(minutes)
   }
   return null
}
