import { styled } from '@mui/material/styles'

export const StyledFooter = styled('footer')({
   backgroundColor: '#14182d',
   width: '100%',
})

export const StyledLogo = styled('img')(({ theme }) => ({
   maxHeight: theme.sizes.headerSm,
   maxWidth: 100,
   [theme.breakpoints.up('lg')]: {
      maxWidth: 140,
   },
}))

export const LinksContainer = styled('div')({
   color: '#cfd0d4',
})

export const StyledContactLink = styled('a')(({ theme }) => ({
   textDecoration: 'none',
   color: '#cfd0d4',
   fontWeight: 'bold',

   '&:hover': {
      color: theme.palette.primary.dark,
   },
}))

export const StyledIconLink = styled('a')({
   color: '#cfd0d4',
   display: 'block',

   '&:hover': {
      cursor: 'pointer',
      transition: 'transform .2s',
      transform: 'scale(1.2)',
   },

   svg: {
      width: 36,
      height: 36,
      display: 'block',
   },
})
