import { Yup } from 'shared/lib'

const validPassword = /^(?=.*[0-9])(?=.*[A-Z-äÄöÖüÜßčČřŠšŽžě])[a-zA-Z0-9äÄöÖüÜßčČřŠšŽžě!@#$%^&*()]{6,32}$/

export const resetPasswordSchema = Yup.object().shape({
   password: Yup.string().matches(validPassword, 'validation.passwordPattern').required(),
   confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'validation.confirmPassword'),
})
