import React, { useEffect, useState } from 'react'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import MailIcon from '@mui/icons-material/Mail'
import Tooltip from '@mui/material/Tooltip'
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled'
import Grid from '@mui/material/Grid'

import { WholesalerType } from 'shared/store/useWholesalers/types'
import { useResendCooperationEmailMutation } from 'shared/store/useWholesalers'

import { StyledCard, StatusCorner, DeleteCorner, EditTimesCorner, EditCorner, StyledImg, ExpiredCorner } from './styles'
import { WholesalerActionType } from '../../types'

type Props = {
   activeWholesaler: WholesalerType
   activeLocationUuid?: string
   handleWholesaler: (wholesalerId: string, type: WholesalerActionType) => void
}

export default function WholesalerCard({ activeWholesaler, handleWholesaler, activeLocationUuid }: Props) {
   const [selectedDepsCount, setSelectedDepsCount] = useState(0)
   const [handlingAction, setHandlingAction] = useState(false)
   const { t } = useTranslation('WHOLESALERS')
   const { mutate } = useResendCooperationEmailMutation()

   function handleAskForCooperation() {
      if (activeLocationUuid) {
         setHandlingAction(true)
         if (!handlingAction) {
            mutate(
               {
                  locationUuid: activeLocationUuid,
                  cooperationUuid: activeWholesaler.cooperationUuid,
               },
               {
                  onSettled: () => setHandlingAction(false),
               }
            )
         }
      }
   }

   useEffect(() => {
      if (activeWholesaler) {
         const selectedDeps = activeWholesaler.departments.filter((item) => item.type && item.name)
         setSelectedDepsCount(selectedDeps.length)
      }
   }, [activeWholesaler])

   return (
      <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
         {activeWholesaler.cooperationStatus === 'EXPIRED' ? (
            <Tooltip title={t('coopExpiredTooltip')}>
               <StyledCard>
                  <ExpiredCorner>
                     <HourglassDisabledIcon color="warning" sx={{ mt: '3px', ml: '4px' }} />
                  </ExpiredCorner>
                  <DeleteCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'deleteWholesaler')}>
                     <DeleteIcon />
                  </DeleteCorner>
                  <EditTimesCorner onClick={() => handleAskForCooperation()}>
                     <MailIcon />
                  </EditTimesCorner>
                  <EditCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'editWholesaler')}>
                     <EditIcon />
                  </EditCorner>
                  <Tooltip
                     title={`${activeWholesaler.wholesaleName} ${selectedDepsCount}/3 min: ${
                        activeWholesaler.minLogistics === null ? '-' : activeWholesaler.minLogistics
                     }`}
                  >
                     <StyledImg
                        src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${activeWholesaler.wholesaleName}.png`}
                        alt={activeWholesaler.wholesaleName}
                     />
                  </Tooltip>
               </StyledCard>
            </Tooltip>
         ) : (
            <StyledCard>
               <StatusCorner pendingcoop={`${activeWholesaler.cooperationStatus === 'PENDING'}`}>
                  {activeWholesaler.cooperationStatus === 'PENDING' ? (
                     <QueryBuilderIcon color="primary" sx={{ mt: '3px', ml: '4px' }} />
                  ) : (
                     <CheckIcon sx={{ color: 'green', mt: '3px', ml: '4px' }} />
                  )}
               </StatusCorner>
               <DeleteCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'deleteWholesaler')}>
                  <DeleteIcon />
               </DeleteCorner>
               <EditTimesCorner
                  sx={{
                     display: activeWholesaler.cooperationStatus === 'PENDING' ? 'none' : 'initial',
                  }}
                  onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'editTimes')}
               >
                  <AccessTimeIcon />
               </EditTimesCorner>
               <EditCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'editWholesaler')}>
                  <EditIcon />
               </EditCorner>
               <Tooltip
                  title={`${activeWholesaler.wholesaleName} ${selectedDepsCount}/3 min: ${
                     activeWholesaler.minLogistics === null ? '-' : activeWholesaler.minLogistics
                  }`}
               >
                  <StyledImg
                     src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${activeWholesaler.wholesaleName}.png`}
                     alt={activeWholesaler.wholesaleName}
                  />
               </Tooltip>
            </StyledCard>
         )}
      </Grid>
   )
}
