import { Yup } from 'shared/lib'

const validName =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž])$/

const schema = Yup.object().shape({
   userSettings: Yup.object().shape({
      preferences: Yup.object().shape({
         language: Yup.string().oneOf(['en-EN', 'pl-PL', 'de-DE']),
         time: Yup.string(),
         displayMinPopup: Yup.boolean(),
         priceDifferences: Yup.boolean(),
         dontShowLocationDialog: Yup.boolean(),
      }),
      name: Yup.string().required().min(3).max(32).matches(validName, 'validation.validName'),
      surname: Yup.string().required().min(3).max(32).matches(validName, 'validation.validSurname'),
      email: Yup.string().required().email(),
      phoneNumber: Yup.string().required(),
   }),
})

export default schema
