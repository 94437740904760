/* eslint-disable no-nested-ternary */
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CircularProgress from '@mui/material/CircularProgress'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import CloseIcon from '@mui/icons-material/Close'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableFooter from '@mui/material/TableFooter'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'

import { StyledTableCell, StyledContentTableCell } from 'shared/components/StyledTableComponents'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { Badge, Card, FormControlLabel, InputAdornment, Switch, Table } from '@mui/material'
import { sortAlphabetical } from 'shared/utils/helpers/sorters'
import { StyledSpan, ProductTableRow, ButtonWrapper, StyledBox, PayBox, StyledAvailabilityDot } from './styles'
import {
   DuplicatedProduct,
   FetchProductsResult,
   sortDirectionType,
   Product,
   SendDuplicatesPayload,
   FetchProductsPayload,
   VoteType,
} from './types'
import { pl, en, de } from './locales'
import { fetchProducts, getSubstitutesService, sendDuplicates, sendSubstituteVote } from './services'
import MergeProductsModal from './components/MergeProductsModal'
import DeleteModal from './components/DeleteModal'
import ScoreDetailsModal from './components/ScoreDetailsModal'

const columnNames = [
   { name: 'index', translateLabel: 'partIndex' },
   { name: 'name', translateLabel: 'productName' },
   { name: 'producer', translateLabel: 'producer' },
   { name: 'meanPrice', translateLabel: 'meanPrice' },
   { name: 'availability', translateLabel: 'availability' },
]

export default function ProductsList() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'PRODUCTS_LIST'])
   i18n.addResourceBundle('pl-PL', 'PRODUCTS_LIST', pl)
   i18n.addResourceBundle('en-EN', 'PRODUCTS_LIST', en)
   i18n.addResourceBundle('de-DE', 'PRODUCTS_LIST', de)
   const navigate = useNavigate()
   const [searchParams] = useSearchParams()
   const getSearchType = searchParams.get('searchType') as PropType<FetchProductsPayload, 'searchType'>
   const { addNotification } = useNotificationContext()
   const { user } = useLogin()
   const location = useLocation()
   const [duplicatedProducts, setDuplicatedProducts] = useState<DuplicatedProduct[]>([])
   const [sortDirection, setSortDirection] = useState<sortDirectionType>('asc')
   const [isSendingDuplicates, setIsSendingDuplicates] = useState(false)
   const [searchError, setSearchError] = useState(false)
   const [deleteModal, setDeleteModal] = useState<string>()
   const [data, setData] = useState<FetchProductsResult>()
   const [sortedProducts, setSortedProducts] = useState<PropType<FetchProductsResult, 'products'>>([])
   const [sortBy, setSortBy] = useState<Exclude<keyof Product, 'wholesaleName' | 'scoreComponents' | 'voteType'>>()
   const [openScoreDetails, setOpenScoreDetails] = useState<PropType<Product, 'scoreComponents'>>()
   const [itemsPerPage, setItemsPerPage] = useState(25)
   const [isLoading, setIsLoading] = useState(false)
   const [votingLoading, setVotingLoading] = useState(false)
   const [openModal, setOpenModal] = useState(false)
   const [activePage, setActivePage] = useState(0)
   const [producers, setProducers] = useState<string[]>([])
   const [producersFilter, setProducersFilter] = useState<string[]>([])
   const [onlyAvailable, setOnlyAvailable] = useState(false)

   const searchValue = searchParams.get('searchType') !== 'SUBSTITUTES' ? searchParams.get('value') || '' : ''

   function renderVotingCell(voteType: PropType<Product, 'voteType'>, productUuid: string) {
      if (getSearchType === 'SUBSTITUTES') {
         if (voteType !== 'MAIN') {
            return (
               <StyledContentTableCell
                  onClick={(e) => {
                     e.preventDefault()
                     e.stopPropagation()
                  }}
               >
                  {renderVotes(voteType, productUuid)}
               </StyledContentTableCell>
            )
         }
         return <StyledContentTableCell />
      }
      return null
   }

   function handleItemsPerPage(event: SelectChangeEvent<number>) {
      setActivePage(0)
      window.localStorage.setItem('itemsPerPage', event.target.value.toString())
      setItemsPerPage(event.target.value as number)
   }

   function handleVoting(replacementProductUuid: string, vote: Exclude<VoteType, 'MAIN'>) {
      setVotingLoading(true)
      sendSubstituteVote(searchParams.get('value') || '', replacementProductUuid, vote)
         .then(() => {
            addNotification('successSave', 'success')
            getSubstitutesData(true)
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
   }

   function renderVotes(voteType: VoteType, replacementProductUuid: string) {
      switch (voteType) {
         case null:
            return (
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title={t('PRODUCTS_LIST:voteUpTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'UP')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbUpOffAltOutlinedIcon color={!votingLoading ? 'success' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <Tooltip title={t('PRODUCTS_LIST:voteDownTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'DOWN')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbDownOutlinedIcon color={!votingLoading ? 'error' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <IconButton disabled>
                     <DoDisturbIcon />
                  </IconButton>
               </Box>
            )
         case 'DOWN':
            return (
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title={t('PRODUCTS_LIST:voteUpTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'UP')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbUpOffAltOutlinedIcon color={!votingLoading ? 'success' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <IconButton disabled>
                     <ThumbDownAltIcon color={!votingLoading ? 'error' : 'disabled'} />
                  </IconButton>
                  <Tooltip title={t('PRODUCTS_LIST:voteCancelTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, null)
                        }}
                        disabled={votingLoading}
                     >
                        <DoDisturbIcon />
                     </IconButton>
                  </Tooltip>
               </Box>
            )
         case 'UP':
            return (
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton disabled>
                     <ThumbUpAltIcon color={!votingLoading ? 'success' : 'disabled'} />
                  </IconButton>
                  <Tooltip title={t('PRODUCTS_LIST:voteDownTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'DOWN')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbDownOutlinedIcon color={!votingLoading ? 'error' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <Tooltip title={t('PRODUCTS_LIST:voteCancelTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, null)
                        }}
                        disabled={votingLoading}
                     >
                        <DoDisturbIcon />
                     </IconButton>
                  </Tooltip>
               </Box>
            )
         default:
            return null
      }
   }

   function returnSearchPhrase() {
      if (searchParams.get('searchType')) {
         switch (searchParams.get('searchType')) {
            case 'OIL':
               return `${searchParams.get('producer') || []} ${searchParams.get('sae') || ''} ${
                  searchParams.get('capacity') || ''
               }${searchParams.get('standard') || ''}`
            case 'INDEX':
            case 'OE':
            case 'EXTENSION':
            case 'WHOSALE_INDEX':
               return searchParams.get('value')
            case 'SUBSTITUTES':
               return `${searchParams.get('index')} - ${t('PRODUCTS_LIST:substitutes')}`
            default:
               return ''
         }
      }
      return ''
   }

   function handleOnClick(productUuid: string) {
      navigate({
         pathname: '/dashboard/product',
         search: createSearchParams({
            uuid: productUuid,
         }).toString(),
      })
   }

   function handleScrollClick(productUuid: string) {
      const urlStart = window.location.href.split('/')[0]
      const url = `${urlStart}/dashboard/product?uuid=${productUuid}`
      return window.open(url, '_blank')
   }

   function handleDuplicates(productUuid: string, productIndex: string, producerName: string, wholesaleCount: number) {
      if (duplicatedProducts.some((item) => item.uuid === productUuid)) {
         const newDuplicatedProducts: DuplicatedProduct[] = duplicatedProducts.filter(
            (item) => item.uuid !== productUuid
         )
         setDuplicatedProducts(newDuplicatedProducts)
      } else {
         const newDuplicatedProducts: DuplicatedProduct[] = [
            ...duplicatedProducts,
            { index: productIndex, uuid: productUuid, producer: producerName, wholesaleCount },
         ]

         setDuplicatedProducts(newDuplicatedProducts)
      }
   }

   function handleDuplicatesSend() {
      setIsSendingDuplicates(true)
      const payload: SendDuplicatesPayload = {
         products: duplicatedProducts,
      }
      sendDuplicates(payload)
         .then(() => {
            addNotification('duplicatesSend', 'success')
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => {
            setIsSendingDuplicates(false)
            setDuplicatedProducts([])
         })
   }

   function handleSortingChange(sortName: Exclude<keyof Product, 'wholesaleName' | 'scoreComponents' | 'voteType'>) {
      setSortBy(sortName)
      if (sortDirection === 'asc') {
         setSortDirection('desc')
      } else {
         setSortDirection('asc')
      }
   }

   function sortingType(a: string | number | boolean, b: string | number | boolean) {
      switch (sortDirection) {
         case 'desc':
            return a > b ? -1 : 1
         case 'asc':
            return a > b ? 1 : -1
         default:
            return 0
      }
   }
   function returnProductName(productName: string, productIndex: string) {
      if (productName) {
         if (productName.length > 100) {
            return `${productName.slice(0, 100)}...`
         }
         return productName
      }
      return productIndex
   }

   function returnAvailabilityDot(availability: number) {
      switch (true) {
         case availability === 0:
            return <StyledAvailabilityDot color="red" />
         case availability === 1:
            return <StyledAvailabilityDot color="orange" />
         case availability === -1:
            return <StyledAvailabilityDot color="gray" />
         case availability > 1:
            return <StyledAvailabilityDot color="green" />
         default:
            return null
      }
   }

   function getSubstitutesData(showUnverified?: boolean) {
      if (showUnverified) {
         getSubstitutesService(searchParams.get('value') || '', showUnverified)
            .then((res) => {
               setData(res)
               setSearchError(false)
            })
            .catch(() => {
               setSearchError(true)
               if (user.roles.includes(UserRoles.INTERNAL)) {
                  setData({ resultsCount: 0, hasMoreResults: false, products: [], searchSession: '' })
               }
            })
            .finally(() => {
               setVotingLoading(false)
               setIsLoading(false)
            })
      } else {
         getSubstitutesService(searchParams.get('value') || '')
            .then((res) => {
               setData(res)
               setSearchError(false)
            })
            .catch(() => {
               setSearchError(true)
               if (user.roles.includes(UserRoles.INTERNAL)) {
                  setData({ resultsCount: 0, hasMoreResults: false, products: [], searchSession: '' })
               }
            })
            .finally(() => {
               setIsLoading(false)
            })
      }
   }

   function fetchProductsData() {
      const searchPhrase = searchParams.get('value') || null
      const filters: PropType<FetchProductsPayload, 'filters'> = {}
      const sourceParams: PropType<FetchProductsPayload, 'sourceParams'> = {}
      setSearchError(false)

      const saeFromSearch = searchParams.get('sae') || null
      if (saeFromSearch) {
         filters.sae = saeFromSearch
      }
      const capacityFromSearch = searchParams.get('capacity') || null
      if (capacityFromSearch) {
         filters.capacity = capacityFromSearch
      }
      const standardFromSearch = searchParams.get('standard') || null
      if (standardFromSearch) {
         filters.standard = standardFromSearch
      }

      fetchProducts({
         searchType: getSearchType || 'INDEX',
         searchPhrase: searchPhrase ? searchPhrase.replace(/\(.*?\)/g, '') : searchPhrase,
         producers: searchParams.get('producer') ? [`${searchParams.get('producer')}`] : [],
         source: searchParams.get('catalogName') || null,
         sourceParams,
         filters,
      })
         .then((res) => {
            setData(res)
            setIsLoading(false)
            if (res.products.length === 1) {
               if (location.state) {
                  if (location.state.indexes) {
                     navigate(
                        {
                           pathname: '/dashboard/product',
                           search: createSearchParams({
                              uuid: res.products[0].uuid,
                           }).toString(),
                        },
                        {
                           state: {
                              indexes: location.state.indexes,
                              activeIndex: location.state.activeIndex || 0,
                           },
                        }
                     )
                  }
               } else {
                  navigate({
                     pathname: '/dashboard/product',
                     search: createSearchParams({
                        uuid: res.products[0].uuid,
                     }).toString(),
                  })
               }
            }
         })
         .catch(() => {
            setSearchError(true)
            setData(undefined)
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   useEffect(() => {
      setIsLoading(true)
      setSortBy(undefined)
      setDuplicatedProducts([])
      const filters: PropType<FetchProductsPayload, 'filters'> = {}
      const sourceParams: PropType<FetchProductsPayload, 'sourceParams'> = {}
      searchParams.forEach((value, key) => {
         if ((key === 'sae' || key === 'standard' || key === 'capacity') && value !== '') {
            filters[key] = value
         }
      })

      searchParams.forEach((value, key) => {
         if ((key === 'vin' || key === 'category' || key === 'model' || key === 'carBrand') && value !== '') {
            sourceParams[key] = value
         }
      })

      switch (getSearchType) {
         case 'OIL':
         case 'EXTENSION':
         case 'WHOLESALE_INDEX':
         case 'INDEX':
         case 'OE':
            fetchProductsData()
            break
         case 'SUBSTITUTES':
            getSubstitutesData()
            break
         default:
            break
      }

      setActivePage(0)
      if (window.localStorage.getItem('itemsPerPage')) {
         setItemsPerPage(Number(window.localStorage.getItem('itemsPerPage')))
      } else {
         setItemsPerPage(25)
      }
   }, [searchValue, searchParams, navigate])

   useEffect(() => {
      if (data) {
         if (producersFilter.length > 0) {
            if (!onlyAvailable) {
               const tempSortedProducts = [...data.products]
                  .sort((a, b) => (sortBy ? sortingType(a[sortBy], b[sortBy]) : 0))
                  .filter((product) => producersFilter.includes(product.producer))

               setSortedProducts([...tempSortedProducts])
            } else {
               const tempSortedProducts = [...data.products]
                  .sort((a, b) => (sortBy ? sortingType(a[sortBy], b[sortBy]) : 0))
                  .filter((product) => producersFilter.includes(product.producer))
                  .filter((product) => product.availability > 0)

               setSortedProducts([...tempSortedProducts])
            }
         }
         if (producersFilter.length === 0) {
            if (!onlyAvailable) {
               const tempSortedProducts = [...data.products].sort((a, b) =>
                  sortBy ? sortingType(a[sortBy], b[sortBy]) : 0
               )

               setSortedProducts([...tempSortedProducts])
            } else {
               const tempSortedProducts = [...data.products]
                  .sort((a, b) => (sortBy ? sortingType(a[sortBy], b[sortBy]) : 0))
                  .filter((product) => product.availability > 0)

               setSortedProducts([...tempSortedProducts])
            }
         }
      }
   }, [sortBy, sortDirection, itemsPerPage, activePage, producersFilter, onlyAvailable])

   useEffect(() => {
      if (data) {
         setOnlyAvailable(false)
         setProducersFilter([])
         setSortedProducts([...data.products])
         const tempProducers: string[] = []
         data.products.forEach((product) => {
            if (!tempProducers.includes(product.producer)) {
               tempProducers.push(product.producer)
            }
         })
         setProducers(tempProducers.sort((a, b) => sortAlphabetical(a, b)))
      }
   }, [data])

   useEffect(() => {
      const tempProducers: string[] = []
      if (data) {
         if (!onlyAvailable) {
            data.products.forEach((product) => {
               if (!tempProducers.includes(product.producer)) {
                  tempProducers.push(product.producer)
               }
            })
         } else {
            data.products
               .filter((product) => product.availability > 0)
               .forEach((product) => {
                  if (!tempProducers.includes(product.producer)) {
                     tempProducers.push(product.producer)
                  }
               })
         }
      }
      setProducers(tempProducers.sort((a, b) => sortAlphabetical(a, b)))
   }, [sortedProducts, onlyAvailable])

   return (
      <StyledBox>
         {data && sortedProducts && !isLoading ? (
            user.roles.includes(UserRoles.SUBSCRIPTION) || user.roles.includes(UserRoles.DEMO) ? (
               <>
                  <Card>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: { md: 'row', xs: 'column' },
                           justifyContent: 'space-between',
                           mb: { md: 3, xs: 1 },
                           padding: '24px 24px 0px 24px',
                           alignItems: 'center',
                           width: '100%',
                        }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              flexDirection: data.resultsCount >= 250 || data.resultsCount === 0 ? 'column' : 'row',
                              gap: 1,
                           }}
                        >
                           <Typography variant="body1" fontWeight="bold">
                              {t('PRODUCTS_LIST:searchedPhrase')}
                              <StyledSpan>{returnSearchPhrase()}</StyledSpan>
                           </Typography>
                           {data.resultsCount < 250 && data.resultsCount > 0 && (
                              <Typography variant="body1" fontWeight="bold">
                                 {t('PRODUCTS_LIST:found')} <StyledSpan> {data ? data.resultsCount : 0} </StyledSpan>
                              </Typography>
                           )}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                           <Badge color="error" badgeContent={t('PRODUCTS_LIST:news')}>
                              <Typography sx={{ p: 1 }} variant="body1" fontWeight="bold">
                                 {t('PRODUCTS_LIST:filterByProducer')}:
                              </Typography>
                           </Badge>
                           <Select
                              disabled={data.resultsCount === 0}
                              value={producersFilter}
                              onChange={(e) => {
                                 setActivePage(0)
                                 setProducersFilter(e.target.value as string[])
                              }}
                              sx={{ width: 200 }}
                              variant="standard"
                              label={t('PRODUCTS_LIST:filterByProducer')}
                              multiple
                              startAdornment={
                                 <InputAdornment position="start">
                                    <IconButton
                                       disabled={data.resultsCount === 0}
                                       onClick={() => setProducersFilter([])}
                                    >
                                       <CloseIcon />
                                    </IconButton>
                                 </InputAdornment>
                              }
                           >
                              {producers.map((producer) => (
                                 <MenuItem value={producer}>{producer}</MenuItem>
                              ))}
                           </Select>
                           <FormControlLabel
                              sx={{ fontWeight: 'bold' }}
                              label={
                                 <Typography variant="body1" fontWeight="bold">
                                    {t('PRODUCTS_LIST:onlyAvailable')}
                                 </Typography>
                              }
                              control={
                                 <Switch
                                    disabled={data.resultsCount === 0}
                                    checked={onlyAvailable}
                                    onChange={(e) => {
                                       setActivePage(0)
                                       setProducersFilter([])
                                       setOnlyAvailable(e.target.checked)
                                    }}
                                 />
                              }
                           />
                        </Box>
                     </Box>
                     <Table>
                        <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                           <TableRow>
                              {columnNames.map((column) => (
                                 <StyledTableCell key={column.name}>
                                    <TableSortLabel
                                       sx={{ color: 'grayText' }}
                                       onClick={() =>
                                          handleSortingChange(
                                             column.name as Exclude<
                                                keyof Product,
                                                'wholesaleName' | 'scoreComponents' | 'voteType'
                                             >
                                          )
                                       }
                                       active={sortBy === column.name}
                                       direction={sortDirection}
                                    >
                                       {t(`TRANSLATION:columnNames.${column.translateLabel}`)}
                                    </TableSortLabel>
                                 </StyledTableCell>
                              ))}
                              <StyledTableCell sx={{ color: 'grayText' }}>
                                 <Tooltip title={t('PRODUCTS_LIST:duplicatedTooltip') || ''}>
                                    <span>{t('PRODUCTS_LIST:duplicated')}</span>
                                 </Tooltip>
                              </StyledTableCell>
                              {user.roles.includes(UserRoles.DATA_MANAGER) && <StyledTableCell />}
                              {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                 <StyledTableCell>Podpięta hurtownia </StyledTableCell>
                              )}
                              {(user.roles.includes(UserRoles.DATA_MANAGER) ||
                                 user.roles.includes(UserRoles.INTERNAL)) && <StyledTableCell> Score </StyledTableCell>}
                              {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                 <StyledTableCell>Vote count</StyledTableCell>
                              )}
                              {getSearchType === 'SUBSTITUTES' && (
                                 <StyledTableCell sx={{ color: 'grayText' }}>
                                    {t('PRODUCTS_LIST:voteLabel')}
                                 </StyledTableCell>
                              )}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {sortedProducts.length > 0 && data && !isLoading ? (
                              sortedProducts
                                 .slice(itemsPerPage * activePage, itemsPerPage * activePage + itemsPerPage)
                                 .map((product) => (
                                    <ProductTableRow
                                       sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
                                       onClick={() => handleOnClick(product.uuid)}
                                       onMouseDown={(e) => {
                                          if (e.button === 1) handleScrollClick(product.uuid)
                                       }}
                                       key={product.uuid}
                                    >
                                       <StyledContentTableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                          {product.index.length > 30
                                             ? `${product.index.slice(0, 30)}...`
                                             : product.index}
                                          {product.wholesaleCount < 2 ? (
                                             <Tooltip
                                                sx={{ color: 'red' }}
                                                title={t('PRODUCTS_LIST:unmappedTooltip') || ''}
                                             >
                                                <PriorityHighRoundedIcon style={{ marginBottom: -5, fontSize: 20 }} />
                                             </Tooltip>
                                          ) : null}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell sx={{ textAlign: 'center', width: '50%' }}>
                                          {returnProductName(product.name, product.index)}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          <Tooltip title={product.producer}>
                                             <img
                                                style={{ verticalAlign: 'middle', height: 20, width: 'auto' }}
                                                src={`https://files.motorro.eu/img/?name=${product.producerNameWithoutSpecialChars.toLowerCase()}`}
                                                alt={product.producer}
                                             />
                                          </Tooltip>
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          {product.meanPrice !== 0 ? product.formattedMeanPrice : '-'}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          {returnAvailabilityDot(product.availability)}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell onClick={(e) => e.stopPropagation()}>
                                          <Checkbox
                                             color="primary"
                                             checked={!!duplicatedProducts.find((item) => item.uuid === product.uuid)}
                                             onChange={() =>
                                                handleDuplicates(
                                                   product.uuid,
                                                   product.index,
                                                   product.producer,
                                                   product.wholesaleCount
                                                )
                                             }
                                          />
                                       </StyledContentTableCell>
                                       {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                          <StyledContentTableCell>
                                             <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={(e) => {
                                                   e.stopPropagation()
                                                   e.preventDefault()
                                                   setDeleteModal(product.uuid)
                                                }}
                                             >
                                                Usuń
                                             </Button>
                                          </StyledContentTableCell>
                                       )}
                                       {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                          <StyledContentTableCell>
                                             <Typography variant="body2" fontWeight="bold">
                                                {product.wholesaleCount <= 1 && (product.wholesaleName || 'BRAK')}
                                             </Typography>
                                          </StyledContentTableCell>
                                       )}
                                       {(user.roles.includes(UserRoles.DATA_MANAGER) ||
                                          user.roles.includes(UserRoles.INTERNAL)) && (
                                          <StyledContentTableCell
                                             onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setOpenScoreDetails(product.scoreComponents)
                                             }}
                                          >
                                             <Typography variant="body2" fontWeight="bold">
                                                {product.customScore}
                                             </Typography>
                                          </StyledContentTableCell>
                                       )}
                                       {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                          <StyledContentTableCell>
                                             <Typography variant="body2" fontWeight="bold">
                                                {product.voteCount}
                                             </Typography>
                                          </StyledContentTableCell>
                                       )}
                                       {renderVotingCell(product.voteType, product.uuid)}
                                    </ProductTableRow>
                                 ))
                           ) : (
                              <ProductTableRow>
                                 <StyledContentTableCell colSpan={user.roles.includes(UserRoles.DATA_MANAGER) ? 12 : 8}>
                                    <Typography variant="h6">{t('TRANSLATION:noResults')}</Typography>
                                 </StyledContentTableCell>
                              </ProductTableRow>
                           )}
                        </TableBody>
                        <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                           <TableRow>
                              <StyledContentTableCell
                                 sx={{ textAlign: 'right', paddingRight: 7 }}
                                 colSpan={user.roles.includes(UserRoles.DATA_MANAGER) ? 12 : 8}
                              >
                                 {t('PRODUCTS_LIST:itemsPerPage')}
                                 <Select
                                    sx={{ mx: 1, color: 'grayText', fontSize: 14 }}
                                    variant="standard"
                                    disabled={sortedProducts.length === 0}
                                    value={itemsPerPage}
                                    onChange={(e) => handleItemsPerPage(e)}
                                 >
                                    <MenuItem value={10}> 10 </MenuItem>
                                    <MenuItem value={25}> 25 </MenuItem>
                                    <MenuItem value={50}> 50 </MenuItem>
                                 </Select>
                                 {t('PRODUCTS_LIST:displayed', {
                                    displayed: `${itemsPerPage * activePage + 1} - ${
                                       itemsPerPage * activePage + itemsPerPage > sortedProducts.length
                                          ? sortedProducts.length
                                          : itemsPerPage * activePage + itemsPerPage
                                    }`,
                                    all: sortedProducts.length,
                                 })}
                                 <IconButton
                                    disabled={sortedProducts.length === 0 || activePage === 0}
                                    onClick={() => setActivePage((page) => page - 1)}
                                 >
                                    <ChevronLeftIcon />
                                 </IconButton>
                                 <IconButton
                                    disabled={
                                       data
                                          ? sortedProducts.length === 0 ||
                                            activePage === Math.ceil(sortedProducts.length / itemsPerPage) - 1
                                          : false
                                    }
                                    onClick={() => setActivePage((page) => page + 1)}
                                 >
                                    <ChevronRightIcon />
                                 </IconButton>
                              </StyledContentTableCell>
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </Card>
                  {sortedProducts.length > 0 && data && !isLoading && (
                     <ButtonWrapper>
                        {user.roles.includes(UserRoles.DATA_MANAGER) ? (
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <Button
                                 variant="contained"
                                 color="error"
                                 sx={{ borderRadius: 0.5 }}
                                 disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                 onClick={() => setOpenModal(true)}
                              >
                                 Połącz produkty
                              </Button>
                              <ContainedButton
                                 variant="contained"
                                 disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                 onClick={handleDuplicatesSend}
                              >
                                 {t('PRODUCTS_LIST:sendDuplicates')}
                              </ContainedButton>
                              {data.hasMoreResults && (
                                 <ContainedButton onClick={() => getSubstitutesData(true)} variant="contained">
                                    {t('PRODUCTS_LIST:showAll')}
                                 </ContainedButton>
                              )}
                           </Box>
                        ) : (
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <ContainedButton
                                 variant="contained"
                                 disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                 onClick={handleDuplicatesSend}
                              >
                                 {t('PRODUCTS_LIST:sendDuplicates')}
                              </ContainedButton>
                              {data.hasMoreResults && (
                                 <ContainedButton onClick={() => getSubstitutesData(true)} variant="contained">
                                    {t('PRODUCTS_LIST:showAll')}
                                 </ContainedButton>
                              )}
                           </Box>
                        )}
                     </ButtonWrapper>
                  )}
               </>
            ) : (
               <PayBox>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 20 }}>
                     <Typography variant="h6" sx={{ mt: 1, color: 'red', textTransform: 'uppercase' }}>
                        {t('PRODUCTS_LIST:buySubscription')}
                     </Typography>
                     <ContainedButton onClick={() => navigate('/dashboard/payments')} variant="contained">
                        {t('PRODUCTS_LIST:payments')}
                     </ContainedButton>
                  </div>
               </PayBox>
            )
         ) : (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
               {searchError ? (
                  <Typography variant="h4" color="red" sx={{ margin: '0 auto' }}>
                     {t('PRODUCTS_LIST:searchError')}
                  </Typography>
               ) : (
                  <CircularProgress color="primary" />
               )}
            </div>
         )}
         <MergeProductsModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            duplicatedProducts={duplicatedProducts}
         />
         <DeleteModal productUuid={deleteModal || ''} handleClose={() => setDeleteModal(undefined)} />
         {openScoreDetails && (
            <ScoreDetailsModal scoreComponents={openScoreDetails} handleClose={() => setOpenScoreDetails(undefined)} />
         )}
      </StyledBox>
   )
}
