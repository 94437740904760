import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { ReflinkDataType } from '../types'

export function removeProductService(
   reflinkUuid: string,
   verificationHeader: string,
   productUuid: string,
   wholesaleUuid: string
) {
   let errMessage = ''
   return httpClient
      .delete<ReflinkDataType>(`/client-carts/reflinks/${reflinkUuid}/products`, {
         data: {
            productUuid,
            wholesaleUuid,
         },
         headers: {
            'X-Reflink-Verification-Code': verificationHeader,
         },
      })
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT_CART_REFLINK removeProduct service', errMessage, 'error')
         throw error
      })
}
