import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const BoldTypography = styled(Typography)({
   fontWeight: 'bold',
   color: 'grayText',
})

export const QuantityHolder = styled(Box)({
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'space-between',
   margin: '0 auto',
   padding: 4,
   paddingLeft: 20,
   paddingRight: 20,
   maxWidth: 120,
   border: '1px solid #C4C2C4',
   borderRadius: 10,
})

export const StyledQuantityButton = styled('button')(({ theme }) => ({
   border: 'none',
   background: 'rgba(0, 0, 0, 0)',
   fontWeight: 'bold',
   fontSize: 15,
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
   },
}))

export const TypographyLink = styled(Typography)(({ theme }) => ({
   color: theme.palette.primary.main,
   userSelect: 'none',
   '&:hover': {
      color: theme.palette.primary.darker,
      cursor: 'pointer',
      transform: 'scale(1.01)',
   },
}))

export const UnitHolder = styled(Box)({
   display: 'flex',
   flexDirection: 'column',
   gap: 0.5,
})
