import React, { useEffect, useState } from 'react'
import { Settings } from 'react-slick'
import Box from '@mui/material/Box'

import { SlidesArray, SlideObject } from './types'
import { Wrapper, SliderPagination, LoginSliderPagination, SliderDot, CenteredSlider } from './styles'

const SLIDER_CONFIG: Settings = {
   autoplay: true,
   dots: true,
   arrows: false,
   infinite: true,
   autoplaySpeed: 7000,
   slidesToShow: 1,
   slidesToScroll: 1,
   appendDots: (dots) => <SliderPagination>{dots}</SliderPagination>,
   customPaging: () => <SliderDot />,
}

const LOGIN_SLIDER_CONFIG: Settings = {
   autoplay: true,
   dots: true,
   arrows: false,
   infinite: true,
   autoplaySpeed: 7000,
   slidesToShow: 1,
   slidesToScroll: 1,
   appendDots: (dots) => <LoginSliderPagination>{dots}</LoginSliderPagination>,
   customPaging: () => <SliderDot />,
}

interface Props {
   variant: 'LOGIN' | 'HOMEPAGE'
}

const HomepageSlides: SlidesArray = [
   {
      slide: 'https://files.motorro.eu/img/slider/SPRZEGLA24_slider.png',
      url: '/dashboard/wholesalers',
   },
   {
      slide: 'https://files.motorro.eu/img/slider/MOTORRO polecaj.png',
      url: '/dashboard/contact',
   },
   { slide: 'https://files.motorro.eu/img/slider/banner_tap.png', url: '/dashboard/wholesalers' },

   {
      slide: 'https://files.motorro.eu/img/slider/MOTORRO koszyki_2.png',
      url: '/dashboard/contact',
   },
   {
      slide: 'https://files.motorro.eu/img/slider/baselinker.png',
      url: 'https://calendar.app.google/8dpVXvPr21oN38Jw5',
   },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_2.png', url: '/dashboard/wholesalers' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_3.png', url: '/dashboard/wholesalers' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_4.png', url: '/dashboard/wholesalers' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_5.png', url: 'https://nagrody.motorro.pl/' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_6.png', url: '/dashboard/wholesalers' },
]

const LoginSlides: SlidesArray = [
   {
      slide: 'https://files.motorro.eu/img/slider/SPRZEGLA24_slider.png',
      url: '/dashboard/wholesalers',
   },
   {
      slide: 'https://files.motorro.eu/img/slider/rejestracja baner 2.png',
   },
   {
      slide: 'https://files.motorro.eu/img/slider/MOTORRO polecaj.png',
   },
   { slide: 'https://files.motorro.eu/img/slider/banner_tap.png' },

   {
      slide: 'https://files.motorro.eu/img/slider/MOTORRO koszyki_2.png',
   },
   {
      slide: 'https://files.motorro.eu/img/slider/baselinker.png',
      url: 'https://calendar.app.google/8dpVXvPr21oN38Jw5',
   },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_2.png' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_3.png' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_4.png' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_5.png', url: 'https://nagrody.motorro.pl/' },
   { slide: 'https://files.motorro.eu/img/slider/new_slide_6.png' },
]

function BannersSlider({ variant }: Props) {
   const [activeSlides, setActiveSlides] = useState<SlidesArray>([])

   function renderSlider(el: SlideObject) {
      if (el.url) {
         return (
            <a href={el.url} target={el.url.includes('https') ? '_blank' : undefined} rel="noopener noreferrer">
               <Box
                  component="img"
                  src={el.slide}
                  alt={el.slide}
                  height={variant === 'HOMEPAGE' ? '70%' : '100%'}
                  width="100%"
               />
            </a>
         )
      }
      return (
         <Box
            component="img"
            src={el.slide}
            alt={el.slide}
            height={variant === 'HOMEPAGE' ? '70%' : '100%'}
            width="100%"
         />
      )
   }

   function renderSliderVariant() {
      switch (variant) {
         case 'HOMEPAGE':
            return (
               <CenteredSlider {...SLIDER_CONFIG}>
                  {activeSlides.map((el) => (
                     <div key={el.slide}>{renderSlider(el)}</div>
                  ))}
               </CenteredSlider>
            )
         case 'LOGIN':
            return (
               <CenteredSlider {...LOGIN_SLIDER_CONFIG}>
                  {activeSlides.map((el) => (
                     <div key={el.slide}>{renderSlider(el)}</div>
                  ))}
               </CenteredSlider>
            )
         default:
            return null
      }
   }

   useEffect(() => {
      if (variant === 'HOMEPAGE') {
         setActiveSlides(HomepageSlides)
      } else {
         setActiveSlides(LoginSlides)
      }
   }, [variant])

   return <Wrapper>{renderSliderVariant()}</Wrapper>
}

export default BannersSlider
