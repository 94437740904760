import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { BuySubscriptionPayload, Result } from '../types'

export default function buySubscriptionService(payload: BuySubscriptionPayload) {
   let errMessage = ''
   return httpClient
      .post<Result>('subscription/', payload)
      .then((res) => ({ status: res.status, redirectUrl: res.data.redirectUrl }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRICING buySubscription service', errMessage, 'error')
         throw error
      })
}
