import React from 'react'

import AuthorizedLanguagePopover from './AuthorizedLanguagePopover'
import UnauthorizedLanguagePopover from './UnauthorizedLanguagePopover'

export function AuthorizedVersion() {
   return <AuthorizedLanguagePopover />
}

export function UnAuthorizedVersion() {
   return <UnauthorizedLanguagePopover />
}
