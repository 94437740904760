import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { UnAuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import logo from 'assets/images/logo-large.png'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { RootStyle, StyledLogo } from './styles'

export default function Header() {
   const { t } = useTranslation('UNAUTHORIZED')
   const { user } = useLogin()

   return (
      <RootStyle>
         <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
               <Box flex={{ xs: 0 }} width={{ xs: '140px' }}>
                  <Link to="/">
                     <StyledLogo src={logo} alt="logo" />
                  </Link>
               </Box>
               <Box flex={{ xs: 0 }}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                     {!user.roles.includes(UserRoles.DEMO) && !window.location.href.includes('demo') && (
                        <Link style={{ fontSize: '11px', textDecoration: 'none', color: 'white' }} to="/register">
                           <ContainedButton variant="contained" sx={{ mr: 3 }}>
                              {t('header.registerTitle')}
                           </ContainedButton>
                        </Link>
                     )}
                     <LanguagePopover />
                  </Stack>
               </Box>
            </Stack>
         </Container>
      </RootStyle>
   )
}
