import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { FetchOilParamsResults } from './types'

export function fetchOilParams(): Promise<FetchOilParamsResults> {
   let errMessage

   return httpClient
      .get('products/oils/getChoices')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('OIL PARAMS fetchOilParams service', errMessage, 'error')
         throw error
      })
}
