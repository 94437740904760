import React, { useEffect, useRef, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { useCompanyMutation, useCompanyQuery } from 'shared/store/useCompanyDetails'
import { findCountryKeyByName } from 'shared/utils/helpers/findCountryName'
import { CompanySettingsType } from 'shared/store/useCompanyDetails/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import useCountriesQuery from 'shared/store/useCountries'
import SelectField from 'shared/components/SelectField'
import InputField from 'shared/components/InputField'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { httpClient } from 'shared/lib'

import UploadLogoAdmin from '../UploadLogoAdmin'
import { companySchema } from '../../schema'

export default function CompanyForm() {
   const { t } = useTranslation(['TRANSLATION', 'COMPANY_SETTINGS'])
   const { data } = useCountriesQuery()
   const { user } = useLogin()
   const [isEditingName, setIsEditingName] = useState(false)
   const { data: companyData } = useCompanyQuery()
   const queryClient = useQueryClient()
   const { mutate, isLoading } = useCompanyMutation()
   const textFieldRef = useRef<HTMLInputElement>(null)
   const [options, setOptions] = useState<{ voivodeshipOptions: OptionType[]; countyOptions: OptionType[] }>({
      voivodeshipOptions: [],
      countyOptions: [],
   })

   const formik = useFormik<CompanySettingsType>({
      initialValues: {
         companyName: '',
         taxID: '',
         typeService: {
            workshopPositions: 0,
            shopPositions: 0,
            shop: false,
            workshop: false,
         },
         address: {
            street: '',
            zipCode: '',
            city: '',
            country: '',
            houseNumber: '',
            county: '',
            voivodeship: '',
         },
      },
      validationSchema: companySchema,
      onSubmit: handleSubmit,
   })

   function handleSubmit() {
      if (data && companyData) {
         if (user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && values) {
            const newCompanyData: CompanySettingsType = {
               ...values,
               typeService: {
                  ...values.typeService,
                  shop: values.typeService.shopPositions > 0,
                  workshop: values.typeService.workshopPositions > 0,
               },
               address: {
                  ...values.address,
                  country: findCountryKeyByName(data, values.address.country || 'Poland') || 'Poland',
               },
            }
            mutate(newCompanyData, {
               onSuccess: () => {
                  queryClient.invalidateQueries('userSettings')
               },
            })
         }
      }
   }

   const { values, setTouched, setValues, setFieldValue, validateForm } = formik

   useEffect(() => {
      if (
         options.countyOptions.length > 0 &&
         !options.countyOptions.some((item) => item.name === values?.address.county)
      ) {
         setFieldValue('address.county', '')
      }
   }, [options])

   useEffect(() => {
      if (companyData && companyData && data) {
         setValues({
            ...companyData,
         })
         if (companyData) {
            setFieldValue('address.country', data[companyData.address.country].name)
         }
      }
   }, [companyData, data, httpClient])

   useEffect(() => {
      if (data && values && companyData) {
         const selectedCountry = data[companyData.address.country]
         if (selectedCountry && selectedCountry.name === 'Polska') {
            if (selectedCountry.voivodeships && values.address.voivodeship) {
               setOptions({
                  voivodeshipOptions: Object.keys(selectedCountry.voivodeships).map((item) => ({
                     name: item,
                     value: item,
                  })),
                  countyOptions: selectedCountry.voivodeships[values.address.voivodeship].map((item) => ({
                     name: item,
                     value: item,
                  })),
               })
            }
         }
      }
   }, [data, values, companyData])

   useEffect(() => {
      validateForm()
   }, [])

   useEffect(() => {
      if (data) {
         const allFieldsTouched: { [key: string]: boolean } = Object.keys(values).reduce(
            (acc, key) => {
               acc[key] = true
               return acc
            },
            {} as { [key: string]: boolean }
         )

         setTouched(allFieldsTouched)
      }
   }, [data, values])

   useEffect(() => {
      if (isEditingName && textFieldRef.current) {
         textFieldRef.current.focus()
      }
   }, [isEditingName])

   return (
      <Grid container spacing={3}>
         <Grid item xl={3} lg={3} sm={12} xs={12}>
            <Card sx={{ p: 3, textAlign: 'center' }}>
               <UploadLogoAdmin />
            </Card>
         </Grid>
         <Grid item xl={9} lg={9} sm={12} xs={12}>
            <Card sx={{ display: 'flex', flexDirection: 'column', py: 3, px: 7 }}>
               <FormikProvider value={formik}>
                  <Form>
                     <Box
                        sx={{
                           mb: 5,
                           ml: 2,
                           textAlign: 'center',
                        }}
                     />

                     <Stack direction="column" sx={{ px: 3 }} spacing={{ md: 3, xs: 2 }}>
                        <Stack direction={{ md: 'row', xs: 'column' }} alignItems="center" spacing={{ md: 3, xs: 2 }}>
                           {isEditingName ? (
                              <InputField
                                 name="companyName"
                                 variant="standard"
                                 inputRef={textFieldRef}
                                 onBlur={() => {
                                    setIsEditingName(false)
                                    handleSubmit()
                                 }}
                                 disabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN)}
                                 inputProps={{
                                    sx: {
                                       fontSize: 20,
                                       fontWeight: 'bold',
                                    },
                                 }}
                              />
                           ) : (
                              <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                 <Typography
                                    onClick={() => setIsEditingName(true)}
                                    sx={{ fontSize: 20, fontWeight: 'bold' }}
                                 >
                                    {values.companyName}
                                 </Typography>
                                 {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && (
                                    <IconButton onClick={() => setIsEditingName(true)}>
                                       <EditIcon />
                                    </IconButton>
                                 )}
                              </Box>
                           )}
                        </Stack>
                        <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 3, xs: 2 }}>
                           <InputField disabled name="taxID" label={t('TRANSLATION:formFields.taxID')} />
                           <InputField disabled name="address.country" label={t('TRANSLATION:formFields.country')} />
                        </Stack>
                        <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 3, xs: 2 }}>
                           {values?.address.country === 'Polska' ? (
                              <SelectField
                                 searcher
                                 noMinWidth
                                 fullWidth
                                 label={t('TRANSLATION:formFields.voivodeship')}
                                 inputDisabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || isLoading}
                                 inputVariant="outlined"
                                 name="address.voivodeship"
                                 options={options.voivodeshipOptions}
                              />
                           ) : (
                              <InputField
                                 label={t('TRANSLATION:formFields.voivodeship')}
                                 fullWidth
                                 name="address.voivodeship"
                                 disabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || isLoading}
                              />
                           )}
                           {values?.address.country === 'Polska' ? (
                              <SelectField
                                 searcher
                                 label={t('TRANSLATION:formFields.county')}
                                 inputVariant="outlined"
                                 fullWidth
                                 noMinWidth
                                 name="address.county"
                                 inputDisabled={
                                    !formik.values?.address.voivodeship ||
                                    !user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ||
                                    isLoading
                                 }
                                 options={options.countyOptions}
                              />
                           ) : (
                              <InputField
                                 fullWidth
                                 label={t('TRANSLATION:formFields.county')}
                                 name="address.county"
                                 disabled={
                                    !formik.values?.address.voivodeship ||
                                    !user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ||
                                    isLoading
                                 }
                              />
                           )}
                           <InputField name="address.city" label={t('TRANSLATION:formFields.city')} />
                        </Stack>
                        <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 3, xs: 2 }}>
                           <InputField
                              name="address.zipCode"
                              label={t('TRANSLATION:formFields.postCode')}
                              disabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || isLoading}
                           />
                           <InputField
                              label={t('TRANSLATION:formFields.street')}
                              name="address.street"
                              disabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || isLoading}
                           />
                           <InputField
                              label={t('TRANSLATION:formFields.houseNumber')}
                              name="address.houseNumber"
                              disabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || isLoading}
                           />
                        </Stack>
                        <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 3, xs: 2 }}>
                           <InputField
                              type="number"
                              label={t('TRANSLATION:formFields.workshopEmployees')}
                              inputProps={{
                                 min: 0,
                              }}
                              disabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || isLoading}
                              name="typeService.workshopPositions"
                              required
                           />
                           <InputField
                              type="number"
                              label={t('TRANSLATION:formFields.shopEmployees')}
                              inputProps={{
                                 min: 0,
                              }}
                              disabled={!user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || isLoading}
                              name="typeService.shopPositions"
                              required
                           />
                        </Stack>
                     </Stack>
                     {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                           <ContainedButton disabled={isLoading || !formik.isValid} type="submit" variant="contained">
                              {t('TRANSLATION:save')}
                           </ContainedButton>
                        </Box>
                     )}
                  </Form>
               </FormikProvider>
            </Card>
         </Grid>
      </Grid>
   )
}
