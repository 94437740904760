import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import { useFormikContext } from 'formik'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import React from 'react'

import { LocationDetailsType } from 'shared/store/useLocations/types'
import SelectField from 'shared/components/SelectField'
import InputField from 'shared/components/InputField'
import { UserRoles } from 'shared/consts'

import { LocationAddressOptionType } from '../../types'

interface Props {
   mainLocationUuid: string
   roles: UserRoles[]
   openDeleteLocationModal: () => void
   options: LocationAddressOptionType
}

export default function LocationDetailsForm({ mainLocationUuid, roles, options, openDeleteLocationModal }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const { values } = useFormikContext<LocationDetailsType>()

   return (
      <>
         <Typography variant="h6" color="primary" sx={{ pt: 3 }}>
            {t('LOCATIONS:tabsLabels.locationData')}
         </Typography>
         <Stack sx={{ pl: 2, pt: 3 }} direction="column" spacing={{ xs: 2, md: 3 }}>
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
               <InputField
                  name="locationSettings.name"
                  disabled={values?.locationSettings.uuid === mainLocationUuid}
                  required
                  label={t('TRANSLATION:formFields.locationName')}
               />

               <InputField
                  name="locationSettings.address.country"
                  required
                  disabled
                  label={t('TRANSLATION:formFields.country')}
               />
            </Stack>
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
               {values.locationSettings.address.country === 'Polska' ? (
                  <SelectField
                     searcher
                     noMinWidth
                     inputVariant="outlined"
                     fullWidth
                     name="locationSettings.address.voivodeship"
                     label={t('TRANSLATION:formFields.voivodeship')}
                     options={options.voivodeshipOptions}
                  />
               ) : (
                  <InputField
                     fullWidth
                     name="locationSettings.address.voivodeship"
                     label={t('TRANSLATION:formFields.voivodeship')}
                  />
               )}
               {values.locationSettings.address.country === 'Polska' ? (
                  <SelectField
                     searcher
                     fullWidth
                     noMinWidth
                     inputVariant="outlined"
                     name="locationSettings.address.county"
                     disabled={!values.locationSettings.address.voivodeship}
                     label={t('TRANSLATION:formFields.county')}
                     options={options.countyOptions}
                  />
               ) : (
                  <InputField
                     fullWidth
                     name="locationSettings.address.county"
                     disabled={!values.locationSettings.address.voivodeship}
                     label={t('TRANSLATION:formFields.county')}
                  />
               )}
               <InputField name="locationSettings.address.city" required label={t('TRANSLATION:formFields.city')} />
            </Stack>
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
               <InputField
                  name="locationSettings.address.zipCode"
                  required
                  label={t('TRANSLATION:formFields.postCode')}
               />
               <InputField name="locationSettings.address.street" label={t('TRANSLATION:formFields.street')} required />
               <InputField
                  name="locationSettings.address.houseNumber"
                  required
                  label={t('TRANSLATION:formFields.houseNumber')}
               />
            </Stack>
         </Stack>
         <Typography variant="h6" color="primary" sx={{ py: 3 }}>
            {t('LOCATIONS:shippingData')}
         </Typography>
         <Stack sx={{ pl: 2 }} direction={{ md: 'row', sm: 'column', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
            <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
               <InputField
                  name="locationSettings.shippingAddress.city"
                  disabled={values?.locationSettings.uuid !== mainLocationUuid}
                  required
                  label={t('TRANSLATION:formFields.city')}
               />
               <InputField
                  name="locationSettings.shippingAddress.street"
                  disabled={values?.locationSettings.uuid !== mainLocationUuid}
                  required
                  label={t('TRANSLATION:formFields.street')}
               />
            </Stack>
            <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
               <InputField
                  name="locationSettings.shippingAddress.zipCode"
                  disabled={values?.locationSettings.uuid !== mainLocationUuid}
                  required
                  label={t('TRANSLATION:formFields.postCode')}
               />

               <InputField
                  name="locationSettings.shippingAddress.houseNumber"
                  disabled={values?.locationSettings.uuid !== mainLocationUuid}
                  required
                  label={t('TRANSLATION:formFields.houseNumber')}
               />
            </Stack>
         </Stack>
         <Typography variant="h6" color="primary" sx={{ py: 3 }}>
            {t('LOCATIONS:clientPricingData')}
         </Typography>
         <Stack sx={{ pl: 2 }} direction={{ md: 'row', sm: 'column', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
            <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
               <InputField
                  name="locationSettings.orderReflinkSettings.companyName"
                  label={t('TRANSLATION:formFields.companyName')}
               />
               <InputField
                  name="locationSettings.orderReflinkSettings.phoneNumber"
                  label={t('TRANSLATION:formFields.phoneNumber')}
               />
            </Stack>
            <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
               <InputField
                  name="locationSettings.orderReflinkSettings.address"
                  label={t('TRANSLATION:formFields.address')}
               />
               <Tooltip title={t('LOCATIONS:defaultCartDescTooltip') || ''}>
                  <div>
                     <InputField
                        name="locationSettings.orderReflinkSettings.defaultCartDescription"
                        label={t('LOCATIONS:defaultCartDesc')}
                        multiline
                     />
                  </div>
               </Tooltip>
            </Stack>
         </Stack>
         <Box sx={{ my: 2 }}>
            {values?.locationSettings.uuid === mainLocationUuid ||
            !roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? null : (
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="h5">{t('LOCATIONS:deleteLocation')}</Typography>
                  <Typography variant="body1">{t('LOCATIONS:deleteLocationWarning')}</Typography>
                  <Button
                     onClick={openDeleteLocationModal}
                     variant="outlined"
                     sx={{ alignSelf: 'flex-start', fontSize: 12 }}
                     color="error"
                  >
                     {t('TRANSLATION:delete')}
                  </Button>
               </Box>
            )}
         </Box>
      </>
   )
}
