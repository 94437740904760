import { FetchCartSummaryResult } from 'shared/store/useCartSummary/types'
import generateReflinkService from '../services/generateReflinkService'
import { ModalInfoType } from '../types'

interface Props {
   summaryData: FetchCartSummaryResult | undefined
   setModalInfo: (value: React.SetStateAction<ModalInfoType | undefined>) => void
   onFailed: () => void
}

export default function generateReflink({ summaryData, setModalInfo, onFailed }: Props) {
   if (summaryData) {
      generateReflinkService(summaryData.cartDetailsDTO.cartUuid)
         .then((res) => {
            if (res.status === 200) {
               setModalInfo({
                  modalType: 'REFLINK',
                  reflinkURL: res.reflink,
                  notificationSentDate: summaryData.cartDetailsDTO.notificationSentDate,
               })
            }
         })
         .catch(() => {
            onFailed()
         })
   }
}
