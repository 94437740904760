import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { ReflinkDataType } from '../types'

export function addProductService(
   reflinkUuid: string,
   verificationHeader: string,
   productUuid: string,
   quantity: number,
   productIndex?: string
) {
   let errMessage = ''
   return httpClient
      .post<ReflinkDataType>(
         `/client-carts/reflinks/${reflinkUuid}/products`,
         {
            productUuid,
            productIndex,
            quantity,
         },
         {
            headers: {
               'X-Reflink-Verification-Code': verificationHeader,
            },
         }
      )
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT_CART_REFLINK addProduct service', errMessage, 'error')
         throw error
      })
}
