// FacebookPixel.ts
export function initFacebookPixelCompleteRegister() {
   const script = document.createElement('script')
   script.async = true
   script.src = 'https://connect.facebook.net/en_US/fbevents.js'
   document.head.appendChild(script)

   script.onload = () => {
      ;(window as any).fbq('init', '786030555508218')
      ;(window as any).fbq('track', 'CompleteRegistration')
   }
}

export function initFacebookPixelPageView() {
   const script = document.createElement('script')
   script.async = true
   script.src = 'https://connect.facebook.net/en_US/fbevents.js'
   document.head.appendChild(script)

   script.onload = () => {
      ;(window as any).fbq('init', '786030555508218')
      ;(window as any).fbq('track', 'PageView')
   }
}
