import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { ProcessFileServiceType } from './types'

type Result = {
   status: number
}

export default function processFileService(payload: ProcessFileServiceType) {
   let errMessage

   return httpClient
      .post<Result>('carts/auto', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CARTFROMCSV processFile service', errMessage, 'error')
         throw error
      })
}
