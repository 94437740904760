import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import InputField from 'shared/components/InputField'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { AddLocationPayload } from 'shared/store/useLocations/types'
import { useAddLocationMutation, useLocationsQuery } from 'shared/store/useLocations'
import SelectField from 'shared/components/SelectField'
import getCompanyUsersService from 'shared/services/getCompanyUsers'
import useCountriesQuery from 'shared/store/useCountries'

import { newLocationSchema } from '../../../../schema'

interface Props {
   handleClose: () => void
   country?: string
}

export default function NewLocationModal({ handleClose, country }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const { mutate, isLoading } = useAddLocationMutation()
   const { data } = useLocationsQuery()
   const { data: countriesData } = useCountriesQuery()
   const [adminOptions, setAdminOptions] = useState<OptionType[]>([])
   const [options, setOptions] = useState<{
      voivodeshipOptions: OptionType[]
      countyOptions: OptionType[]
   }>({
      voivodeshipOptions: [],
      countyOptions: [],
   })

   const formik = useFormik<NewLocationInitialValues>({
      initialValues: {
         locationName: '',
         adminUuid: '',
         county: '',
         voivodeship: '',
         houseNumber: '',
         country: 'Poland',
         city: '',
         street: '',
         zipCode: '',
      },
      validationSchema: newLocationSchema,
      onSubmit: handleSubmit,
   })

   const { values, setFieldValue } = formik

   function handleSubmit() {
      if (data) {
         const newSettings: AddLocationPayload = {
            address: {
               ...values,
            },
            ...values,
         }
         mutate(newSettings, {
            onSuccess: () => {
               handleClose()
            },
         })
      }
   }

   useEffect(() => {
      getCompanyUsersService(false).then((res) => {
         if (res.status === 200) {
            const tempUsers = res.data.map((user) => ({
               name: `${user.name} ${user.surname}`,
               value: user.uuid,
            }))
            setAdminOptions([...tempUsers])
         }
      })
   }, [])

   useEffect(() => {
      setFieldValue('country', country)
   }, [])

   useEffect(() => {
      if (countriesData && values && values.country === 'Poland') {
         const selectedCountry = countriesData[values.country]
         if (selectedCountry) {
            setOptions({
               voivodeshipOptions: values.country
                  ? Object.keys(selectedCountry.voivodeships).map((item) => ({
                       name: item,
                       value: item,
                    }))
                  : [],
               countyOptions: values.voivodeship
                  ? selectedCountry.voivodeships[values.voivodeship].map((item) => ({
                       name: item,
                       value: item,
                    }))
                  : [],
            })
         }
      }
   }, [countriesData, values])

   useEffect(() => {
      formik.setFieldValue('county', '')
   }, [values.voivodeship])

   useEffect(() => {
      formik.setFieldValue('voivodeship', '')
      formik.setFieldValue('county', '')
   }, [values.country])

   return (
      <div>
         <FormikProvider value={formik}>
            <Form>
               <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
                  {t('LOCATIONS:newLocationDialogContent')}
               </Typography>
               <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                  <InputField<NewLocationInitialValues>
                     name="locationName"
                     required
                     fullWidth={false}
                     sx={{ minWidth: 260 }}
                     variant="standard"
                     label={t('TRANSLATION:formFields.locationName')}
                  />
                  <InputField name="country" disabled variant="standard" label={t('TRANSLATION:formFields.country')} />
               </Stack>
               {values.country === 'Poland' ? (
                  <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3 }} spacing={3}>
                     <SelectField
                        searcher
                        name="voivodeship"
                        variant="standard"
                        label={t('TRANSLATION:formFields.voivodeship')}
                        options={options.voivodeshipOptions}
                     />
                     <SelectField
                        searcher
                        name="county"
                        variant="standard"
                        disabled={!formik.values.voivodeship}
                        label={t('TRANSLATION:formFields.county')}
                        options={options.countyOptions}
                     />
                  </Stack>
               ) : (
                  <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3 }} spacing={3}>
                     <InputField
                        variant="standard"
                        name="voivodeship"
                        label={t('TRANSLATION:formFields.voivodeship')}
                     />
                     <InputField
                        variant="standard"
                        name="county"
                        disabled={!formik.values.voivodeship}
                        label={t('TRANSLATION:formFields.county')}
                     />
                  </Stack>
               )}
               <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3 }} spacing={3}>
                  <InputField<NewLocationInitialValues>
                     name="city"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.city')}
                  />
                  <InputField<NewLocationInitialValues>
                     name="street"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.street')}
                  />
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3 }} spacing={3}>
                  <InputField<NewLocationInitialValues>
                     name="zipCode"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.postCode')}
                  />
                  <InputField<NewLocationInitialValues>
                     name="houseNumber"
                     required
                     variant="standard"
                     label={t('TRANSLATION:formFields.houseNumber')}
                  />
               </Stack>
               <Stack direction={{ xs: 'column', md: 'row' }} sx={{ mt: 3, justifyContent: 'center' }} spacing={3}>
                  <SelectField
                     name="adminUuid"
                     options={adminOptions}
                     required
                     variant="standard"
                     label={t('LOCATIONS:newLocationAdminSelect')}
                  />
               </Stack>
               <Box
                  sx={{
                     width: '100%',
                     display: 'flex',
                     gap: 2,
                     pt: 5,
                     justifyContent: 'flex-end',
                     alignContent: 'bottom',
                  }}
               >
                  <OutlinedButton onClick={handleClose} variant="outlined">
                     {t('TRANSLATION:cancel')}
                  </OutlinedButton>
                  <ContainedButton disabled={isLoading || !formik.isValid} type="submit" variant="contained">
                     {t('TRANSLATION:save')}
                  </ContainedButton>
               </Box>
            </Form>
         </FormikProvider>
      </div>
   )
}
