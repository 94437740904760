import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Fade from '@mui/material/Fade'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import PhotoBrowser from 'shared/components/PhotoBrowser'

import { GetAvailabilityDot } from '../../helpers/getAvailabilityDot'
import { TabPanelProps, FetchErpProductType } from '../../types'
import {
   BlackTypography,
   GrayTypography,
   InformationsContainer,
   ProductDetailsContainer,
   StyledImage,
   TabsContainer,
} from '../../styles'

interface Props {
   data: FetchErpProductType
   searchPhrase: string
}

export default function ProductInfo({ data, searchPhrase }: Props) {
   const [activeTab, setActiveTab] = useState(0)
   const { t } = useTranslation(['TRANSLATION', 'ERP_PRODUCT_CARD'])
   const [openPhoto, setOpenPhoto] = useState(false)

   return (
      <Box>
         <Typography variant="h4" sx={{ mb: 1.5, textTransform: 'uppercase' }}>
            {data.index || searchPhrase} {GetAvailabilityDot(data.status)}
         </Typography>
         <ProductDetailsContainer>
            <StyledImage
               onClick={() => setOpenPhoto(data.imagesLinks.length > 0)}
               alt="partPhoto"
               height="180px"
               width="auto"
               src={
                  data.imagesLinks.length > 0
                     ? `${process.env.REACT_APP_FILES_URL}/assets/producers/${data.imagesLinks[0]}`
                     : `${process.env.REACT_APP_FILES_URL}/img/no-product-image.jpg`
               }
            />
            <InformationsContainer>
               <GrayTypography>
                  {`${t('TRANSLATION:columnNames.partIndex')}: `}
                  <BlackTypography>{data.index || searchPhrase || t('ERP_PRODUCT_CARD:noData')}</BlackTypography>
               </GrayTypography>
               <GrayTypography>
                  {`${t('TRANSLATION:columnNames.producer')}: `}
                  <BlackTypography>{data.producer || t('ERP_PRODUCT_CARD:noData')}</BlackTypography>
               </GrayTypography>
            </InformationsContainer>
            <TabsContainer>
               <Box>
                  <Tabs
                     TabIndicatorProps={{
                        style: { height: 4, borderRadius: 5 },
                     }}
                     sx={{
                        borderBottom: '2px solid #D3D3D3',
                        '& button': { color: 'grayText', fontWeight: 'normal' },
                        '& button.Mui-selected': { color: 'black', fontWeight: 'bold' },
                     }}
                     variant="fullWidth"
                     value={activeTab}
                     onChange={(event, value) => setActiveTab(value)}
                  >
                     <Tab label={t('ERP_PRODUCT_CARD:productDescription')} />
                     <Tab label={t('ERP_PRODUCT_CARD:dimensions')} />
                     <Tab label={t('ERP_PRODUCT_CARD:application')} />
                     <Tab label={t('ERP_PRODUCT_CARD:instructions')} />
                  </Tabs>
                  <TabPanel value={activeTab} index={0}>
                     {data.description || t('ERP_PRODUCT_CARD:noDesc')}
                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>
                     {/* in future there would be dimensions, when Sebastian add backend */}
                     {t('ERP_PRODUCT_CARD:noDimensions')}
                  </TabPanel>
                  <TabPanel value={activeTab} index={2}>
                     {/* in future there would be applications, when Sebastian add backend */}
                     {t('ERP_PRODUCT_CARD:noApplication')}
                  </TabPanel>
                  <TabPanel value={activeTab} index={3}>
                     {/* in future there would be instructions, when Sebastian add backend */}
                     {t('ERP_PRODUCT_CARD:noInstructions')}
                  </TabPanel>
               </Box>
            </TabsContainer>
         </ProductDetailsContainer>
         <PhotoBrowser open={openPhoto} handleClose={() => setOpenPhoto(false)} imagesLinks={data.imagesLinks} />
      </Box>
   )
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index } = props
   return (
      <Box sx={{ marginY: 3, fontSize: 14 }}>
         {value === index && (
            <Fade in>
               <div>{children}</div>
            </Fade>
         )}
      </Box>
   )
}
