import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

import Footer from '../Footer'
import Header from '../Header'

function UnauthorizedLayout() {
   return (
      <>
         <Header />
         <Box component="main" minHeight="calc(100vh - 212px)" mt={8}>
            <Outlet />
         </Box>
         <Footer />
      </>
   )
}

export default UnauthorizedLayout
