import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import { LocationType } from 'shared/store/useLocations/types'
import { useLocationsQuery } from 'shared/store/useLocations'
import { httpClient } from 'shared/lib'

import LocationsList from './components/LocationsList'
import ServicesList from './components/ServicesList'
import { pl, en, de } from './locales'

export default function AdditionalServices() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'SERVICES'])
   const [activeLocation, setActiveLocation] = useState<LocationType>()
   const { data } = useLocationsQuery()

   i18n.addResourceBundle('pl-PL', 'SERVICES', pl)
   i18n.addResourceBundle('en-EN', 'SERVICES', en)
   i18n.addResourceBundle('de-DE', 'SERVICES', de)

   const handleOnChangeLocation = (uuid: string) => {
      if (data) {
         const newActiveLocation = data.locations.find((item) => item.locationSettings.uuid === uuid)
         setActiveLocation(newActiveLocation)
      }
   }

   useEffect(() => {
      if (data) {
         setActiveLocation(data.locations[0])
      }
   }, [data, httpClient])

   return (
      <Card sx={{ py: 2 }}>
         <Typography variant="h4" color="primary" sx={{ pb: 3, pl: 2, fontWeight: 'normal' }}>
            {t('SERVICES:title')}
         </Typography>
         {activeLocation ? (
            <Grid container>
               <LocationsList
                  active={activeLocation}
                  onChangeLocation={handleOnChangeLocation}
                  list={data?.locations}
               />
               <ServicesList activeLocation={activeLocation} />
            </Grid>
         ) : (
            <div style={{ width: '100%', textAlign: 'center' }}>
               <CircularProgress color="primary" />
            </div>
         )}
      </Card>
   )
}
