import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { initFacebookPixelCompleteRegister } from 'shared/lib/FacebookPixel'
import { initGoogleTag } from 'shared/lib/GoogleTag'

export default function DemoRegisterCompleted() {
   const { t } = useTranslation('UNAUTHORIZED')
   const navigate = useNavigate()

   const [pixelInitialized, setPixelInitialized] = useState(false)

   useEffect(() => {
      if (!pixelInitialized) {
         initFacebookPixelCompleteRegister()
         setPixelInitialized(true)
         initGoogleTag('completeDemoRegister')
      }
      setTimeout(() => navigate('/'), 20000)
   }, [pixelInitialized])

   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            paddingX: { md: 40, xs: 10 },
            pt: 30,
         }}
      >
         <Typography sx={{ textAlign: 'center' }} variant="h4" color="primary">
            {t('demo.demoRegisterComplete')}
         </Typography>
         <Typography variant="h6">{t('demo.demoRegisterTooltip')}</Typography>
         <Typography variant="h6">{t('demo.demoWarning1')}</Typography>
         <Typography variant="h6">{t('demo.demoWarning2')}</Typography>
      </Box>
   )
}
