import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'

import MuiPhone from 'shared/components/PhoneInput'
import InputField from 'shared/components/InputField'

interface Props {
   handlePhoneNumber: (value: string, country: string) => void
}

export function FirstStep({ handlePhoneNumber }: Props) {
   const { t } = useTranslation('TRANSLATION')
   const { values } = useFormikContext<RegisterInitialValues>()

   return (
      <Container>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <InputField label={t('formFields.name')} name="firstName" />
            </Grid>
         </Grid>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <InputField label={t('formFields.surname')} name="lastName" />
            </Grid>
         </Grid>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <InputField label={t('formFields.email')} name="email" />
            </Grid>
         </Grid>
         <Grid sx={{ marginBottom: 4 }} container>
            <Grid item xs={12} md={12}>
               <MuiPhone
                  variant="outlined"
                  value={values.telephoneNumber}
                  onChange={(value, country) => handlePhoneNumber(value, country)}
               />
            </Grid>
         </Grid>
      </Container>
   )
}
