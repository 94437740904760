import { AxiosError } from 'axios'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import consoleMessage from '../utils/helpers/consoleMessage'

export async function fetchCooperatingWholesalersData(productUuid: string): Promise<FetchCooperationDataResult> {
   let errMessage = ''

   return httpClient
      .get<FetchCooperatingWholesalersDataType>(`/products/${productUuid}/offline-availability-in-cooperating`)
      .then((response) => {
         if (response.data) {
            return {
               availableWholesalers: response.data.filter((item) => item.status === 'OK_OFFLINE_AVAILABILITY'),
               unAvailableWholesalers: response.data.filter((item) => item.status === 'PRODUCT_NOT_EXIST_IN_WHOLESALE'),
            }
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SHARED_SERVICES fetchCooperatingWholesalersData service', errMessage, 'error')
         throw error
      })
}
