import { useFormik, FormikProvider, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Base64 } from 'js-base64'

import { useNotificationContext } from 'shared/context/Notifications'
import mapDeliveryTypes from 'shared/utils/helpers/mapDeliveryTypes'
import SelectField from 'shared/components/SelectField'
import InputField from 'shared/components/InputField'
import { Yup } from 'shared/lib'

import { ParametersEnum } from 'shared/consts'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { DepartmentType, EndReflinkPayload, ReflinkInfoType, ReflinkInitialValues } from '../../types'
import { endReflinkService, rejectReflinkService } from '../../services'
import ConfirmRejectModal from '../ConfirmRejectModal'
import schema from './schema'

type Props = {
   formDetails: ReflinkInfoType
   token: string
}

type Option = {
   name: string
   value: string
}

export default function CooperationDetailsForm({ formDetails, token }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'COOPERATION_REFLINK'])
   const { addNotification } = useNotificationContext()
   const [updatedSchema, setUpdatedSchema] = useState<Yup.ObjectSchema<any>>()
   const [priceOptions, setPriceOptions] = useState<Option[]>([])
   const [departments, setDepartments] = useState<Option[]>([])
   const [openModal, setOpenModal] = useState(false)
   const navigate = useNavigate()

   const statusOptions: Option[] = [
      { value: 'NEW', name: t('COOPERATION_REFLINK:statusNew') },
      { value: 'ACTIVE', name: t('COOPERATION_REFLINK:statusActive') },
      { value: 'INACTIVE', name: t('COOPERATION_REFLINK:statusInactive') },
   ]

   const currencyOptions: Option[] = [
      { value: 'PLN', name: 'PLN' },
      { value: 'EUR', name: 'EUR' },
      { value: 'CZK', name: 'CZK' },
   ]

   const formik = useFormik<ReflinkInitialValues>({
      initialValues: {
         loginParameter: '',
         passwordParameter: '',
         status: 'NEW',
         conditions: '',
         deliveryType: '',
         currency: 'PLN',
         firstDepartment: '',
         secondDepartment: '',
         thirdDepartment: '',
         deliveryDepartment: '',
         logisticMinimums: 0,
      },
      validationSchema: updatedSchema,
      onSubmit: handleSubmit,
   })

   const { setValues, values, isValid } = formik

   function getDepartments() {
      if (formDetails.cooperation.departments.length > 0) {
         return {
            firstDepartment:
               formDetails.cooperation.departments.find((item) => item.type === 'FIRST')?.departmentUuid || '',
            secondDepartment:
               formDetails.cooperation.departments.find((item) => item.type === 'SECOND')?.departmentUuid || '',
            thirdDepartment:
               formDetails.cooperation.departments.find((item) => item.type === 'THIRD')?.departmentUuid || '',
            deliveryDepartment: formDetails.cooperation.deliveryDepartment || '',
         }
      }
      return {
         firstDepartment: formDetails.wholesale.defaultDepartments?.firstDepartmentUuid || '',
         secondDepartment: formDetails.wholesale.defaultDepartments?.secondDepartmentUuid || '',
         thirdDepartment: formDetails.wholesale.defaultDepartments?.thirdDepartmentUuid || '',
         deliveryDepartment: formDetails.wholesale.defaultDepartments?.deliveryDepartmentUuid || '',
      }
   }

   useEffect(() => {
      if (formDetails) {
         const options: Option[] = [{ value: '', name: '' }]

         formDetails.wholesale.departments
            .filter((item) => item.uuid !== '')
            .map((option) => options.push({ value: option.uuid, name: option.name }))

         setDepartments(options)
         setValues({
            loginParameter: Base64.decode(formDetails.cooperation.login || ''),
            passwordParameter: Base64.decode(formDetails.cooperation.password || ''),
            additionalParameter: formDetails.cooperation.additionalParameter
               ? Base64.decode(formDetails.cooperation.additionalParameter)
               : '',
            currency: 'PLN',
            deliveryType: formDetails.cooperation.deliveryType || '',
            priceVariant: formDetails.cooperation.priceVariant || '',
            logisticMinimums: formDetails.cooperation.minLogistics ? formDetails.cooperation.minLogistics : 0,
            status: 'NEW',
            conditions: '',
            ...getDepartments(),
         })
      }
   }, [formDetails])

   function handleSubmit() {
      const tempDepartments: DepartmentType[] = []
      if (values.firstDepartment) {
         tempDepartments.push({ type: 'FIRST', departmentUuid: values.firstDepartment })
      }
      if (values.secondDepartment) {
         tempDepartments.push({ type: 'SECOND', departmentUuid: values.secondDepartment })
      }
      if (values.thirdDepartment) {
         tempDepartments.push({ type: 'THIRD', departmentUuid: values.thirdDepartment })
      }
      const payload: EndReflinkPayload = {
         login: Base64.encode(values.loginParameter),
         password: Base64.encode(values.passwordParameter),
         additionalParameter: values.additionalParameter || null,
         message: values.conditions,
         minLogistics: values.logisticMinimums,
         status: values.status,
         minLogisticsCurrency: values.currency,
         deliveryType: values.deliveryType || null,
         deliveryDepartment: values.deliveryDepartment,
         priceVariant: values.priceVariant || null,
         departments: tempDepartments,
      }

      endReflinkService(token, payload)
         .then((res) => {
            if (res?.status === 204) {
               navigate('/cooperation-form-completed')
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(
                  error.response?.data?.errors[0].code ||
                     error.response?.data?.errors[0].defaultMessage ||
                     'failedSave',
                  'error'
               )
            } else {
               addNotification('failedSave', 'error')
            }
         })
   }

   function handleReject() {
      rejectReflinkService(token)
         .then((res) => {
            if (res?.status === 204) {
               setOpenModal(false)
               navigate('/cooperation-form-completed')
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
   }

   useEffect(() => {
      const newOptions: Option[] = []
      if (formDetails.wholesale.priceVariants) {
         Object.entries(formDetails.wholesale.priceVariants).forEach(([key, value]) => {
            newOptions.push({ value: key, name: value })
         })
      }
      setPriceOptions(newOptions)
   }, [formDetails.wholesale.priceVariants])

   useEffect(() => {
      if (formDetails) {
         let newSchema = schema
         Object.entries(formDetails.wholesale.parameters).forEach(([key, value]) => {
            if (key === 'LOGIN') {
               if (value.required) {
                  newSchema = newSchema.shape({
                     loginParameter: Yup.string().required(),
                  })
               }
            } else if (key === 'PASSWORD') {
               if (value.required) {
                  newSchema = newSchema.shape({
                     passwordParameter: Yup.string().required(),
                  })
               }
            } else if (key === 'ADDITIONAL_PARAMETER') {
               if (value.required) {
                  newSchema = newSchema.shape({
                     additionalParameter: Yup.string().required(),
                  })
               }
            }
         })
         if (Object.keys(formDetails.wholesale.priceVariants).length > 0) {
            newSchema = newSchema.shape({
               priceVariant: Yup.string().required(),
            })
         }
         setUpdatedSchema(newSchema)
      }
   }, [formDetails.wholesale.parameters])

   return (
      <FormikProvider value={formik}>
         <Form>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 5, md: 10 } }}>
               <div style={{ width: '65%', display: 'flex', flexDirection: 'column', gap: 50 }}>
                  <Box sx={{ display: 'flex', gap: 5, flexDirection: { xs: 'column', md: 'row' } }}>
                     {Object.entries(formDetails.wholesale.parameters).map(([key, value]) => (
                        <div style={{ width: '100%' }}>
                           <InputField
                              required={value.required}
                              name={ParametersEnum[key as keyof typeof ParametersEnum]}
                              variant="standard"
                              type={value.hideInput ? 'password' : 'text'}
                              label={value.name}
                           />
                        </div>
                     ))}
                     <SelectField name="status" variant="standard" label="Status" options={statusOptions} />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 5, flexDirection: { xs: 'column', md: 'row' } }}>
                     <InputField
                        fullWidth={false}
                        name="logisticMinimums"
                        variant="standard"
                        type="Number"
                        label={t('COOPERATION_REFLINK:logisticMinimums')}
                        required
                     />
                     <SelectField defaultValue="PLN" options={currencyOptions} name="currency" label="Waluta minimum" />
                     {formDetails.wholesale.deliveryTypes &&
                        Object.entries(formDetails.wholesale.deliveryTypes).length > 0 && (
                           <SelectField
                              options={mapDeliveryTypes(formDetails.wholesale.deliveryTypes)}
                              label={t('TRANSLATION:formFields.deliveryType')}
                              name="deliveryType"
                           />
                        )}
                     {priceOptions.length > 0 && (
                        <SelectField
                           options={priceOptions}
                           label={t('COOPERATION_REFLINK:priceVariant')}
                           name="priceVariant"
                        />
                     )}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 5, flexDirection: { xs: 'column', md: 'row' } }}>
                     <Box sx={{ display: 'flex', m: 0 }}>
                        <SelectField
                           searcher
                           name="firstDepartment"
                           label={t('COOPERATION_REFLINK:firstDepartment')}
                           options={departments?.filter(
                              (item) =>
                                 (item.value !== values.secondDepartment && item.value !== values.thirdDepartment) ||
                                 item.value === ''
                           )}
                           maxRows={5}
                        />
                     </Box>
                     <Box sx={{ display: 'flex', m: 0 }}>
                        <SelectField
                           searcher
                           name="secondDepartment"
                           label={t('COOPERATION_REFLINK:secondDepartment')}
                           options={departments?.filter(
                              (item) =>
                                 (item.value !== values.firstDepartment && item.value !== values.thirdDepartment) ||
                                 item.value === ''
                           )}
                           maxRows={5}
                        />
                     </Box>
                     <Box sx={{ display: 'flex', m: 0 }}>
                        <SelectField
                           searcher
                           name="thirdDepartment"
                           label={t('COOPERATION_REFLINK:thirdDepartment')}
                           options={departments?.filter(
                              (item) =>
                                 (item.value !== values.firstDepartment && item.value !== values.secondDepartment) ||
                                 item.value === ''
                           )}
                           maxRows={5}
                        />
                     </Box>
                     <Box sx={{ display: 'flex', m: 0 }}>
                        <SelectField
                           required
                           name="deliveryDepartment"
                           label={t('COOPERATION_REFLINK:deliveryDepartment')}
                           options={departments?.filter(
                              (item) =>
                                 item.value === values.firstDepartment ||
                                 item.value === values.secondDepartment ||
                                 item.value === values.thirdDepartment ||
                                 item.value === ''
                           )}
                           maxRows={5}
                        />
                     </Box>
                  </Box>
               </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
               <InputField
                  sx={{ minWidth: 350 }}
                  multiline
                  rows={5}
                  name="conditions"
                  label={t('COOPERATION_REFLINK:conditions')}
               />
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                  <Button
                     variant="contained"
                     color="error"
                     disabled={!!values.passwordParameter && !!values.loginParameter}
                     onClick={() => setOpenModal(true)}
                     type="button"
                     sx={{ alignSelf: 'flex-end', color: 'white', mt: 2 }}
                  >
                     {t('COOPERATION_REFLINK:reject')}
                  </Button>
                  <Button
                     type="submit"
                     disabled={!isValid}
                     variant="contained"
                     sx={{ alignSelf: 'flex-end', mt: 2, color: 'white' }}
                  >
                     {t('COOPERATION_REFLINK:send')}
                  </Button>
               </Box>
            </Box>
         </Form>
         <ConfirmRejectModal
            openModal={openModal}
            handleReject={handleReject}
            handleClose={() => setOpenModal(false)}
         />
      </FormikProvider>
   )
}
