import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import i18n, { t } from 'i18next'
import React, { useCallback } from 'react'
import { UseMutateFunction } from 'react-query'

import { UserRoles } from 'shared/consts'
import { UserSession, StateType, RepeatLoginPayload, SwapLoginPayload } from 'shared/store/useLogin/types'

interface Props {
   open: boolean
   onClose: () => void
   user: StateType
   reLogin: UseMutateFunction<StateType, unknown, RepeatLoginPayload, unknown>
   changeLogin: UseMutateFunction<StateType, unknown, SwapLoginPayload, unknown>
}

interface AdminPanelProps {
   sessions: UserSession[]
   onChangeSession: (fingerPrint: string, userUuid: string, sessionType: Nullable<string>) => void
   onLogin: () => void
}

interface UserPanelProps {
   sessions: UserSession[]
   onChangeSession: (fingerPrint: string, userUuid: string, sessionType: Nullable<string>) => void
}

function returnSessionType(sessionType: string) {
   switch (sessionType) {
      case 'BASELINKER':
         return t('UNAUTHORIZED:noAccessModal.baselinkerType')
      case 'APP':
         return t('UNAUTHORIZED:noAccessModal.appType')
      case 'EXTERNAL_API':
         return t('UNAUTHORIZED:noAccessModal.externalApiType')
      default:
         return t('UNAUTHORIZED:noAccessModal.appType')
   }
}

function AdminPanel({ sessions, onChangeSession, onLogin }: AdminPanelProps) {
   return (
      <Box width="60%" mx="auto" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
         {sessions.map((session) => (
            <Button
               variant="contained"
               color="secondary"
               key={session.userUuid}
               onClick={() => onChangeSession(session.fingerprint, session.userUuid, session.sessionType)}
            >
               {`${session.userDetails} (${returnSessionType(session.sessionType)})`}
            </Button>
         ))}
         <Button variant="contained" color="secondary" size="large" onClick={onLogin}>
            {i18n.t('UNAUTHORIZED:noAccessModal.loginWithoutSub')}
         </Button>
      </Box>
   )
}

function UserPanel({ sessions, onChangeSession }: UserPanelProps) {
   return (
      <Box width="60%" mx="auto" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
         {sessions.map((session) => (
            <Button
               variant="contained"
               color="secondary"
               size="large"
               key={session.userUuid}
               onClick={() => onChangeSession(session.fingerprint, session.userUuid, session.sessionType || null)}
            >
               {`${session.userDetails} (${returnSessionType(session.sessionType)})`}
            </Button>
         ))}
      </Box>
   )
}

export default function ActiveSessionsModal({ open, onClose, user, reLogin, changeLogin }: Props) {
   const renderModalContent = useCallback(() => {
      if (user.loginStatus === 'pending') {
         if (user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN)) {
            return <AdminPanel sessions={user.sessions} onChangeSession={onChangeSession} onLogin={onLogin} />
         }
         return <UserPanel sessions={user.sessions} onChangeSession={onChangeSession} />
      }
      return null
   }, [user.loginStatus, user.roles])

   function onChangeSession(fingerPrint: string, userUuid: string, sessionType: Nullable<string>) {
      changeLogin({ fingerprint: fingerPrint, userUuid, sessionType })
   }

   function onLogin() {
      if (user.loginStatus === 'pending') {
         const { accessToken, refreshToken } = user
         if (refreshToken) {
            reLogin({ accessToken, refreshToken })
         }
      }
   }

   return (
      <Dialog open={open} onClose={onClose}>
         <DialogTitle sx={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
            <Typography>{i18n.t('UNAUTHORIZED:noAccessModal.zeroFreeSubscriptions')}</Typography>
         </DialogTitle>
         <DialogContent>{renderModalContent()}</DialogContent>
      </Dialog>
   )
}
