import { Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'

export const StyledLink = styled(RouterLink)(({ theme }) => ({
   textDecoration: 'none',
   color: 'grayText',
   '&:hover': {
      color: theme.palette.primary.main,
   },
}))

export const StyledContactLink = styled('a')(({ theme }) => ({
   textDecoration: 'none',
   color: 'grayText',
   '&:hover': {
      color: theme.palette.primary.main,
   },
}))

export const StyledTypography = styled(Typography)({
   color: 'grayText',
   fontSize: 15,
   marginTop: 2,
   marginLeft: -2,
})
