import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

export const LocationTabContainer = styled(Box)({
   display: 'flex',
   justifyContent: 'space-between',
   flexDirection: 'column',
   paddingBottom: 15,
   paddingLeft: 30,
   paddingRight: 30,
   minHeight: 400,
})

export const RootStyle = styled(Card)(({ theme }) => ({
   [theme.breakpoints.up('xs')]: {
      display: 'flex',
   },
}))
