import { httpClient } from 'shared/lib'
import { FetchAwardedProducersResults } from './types'

export function fetchAwardedProducers(): Promise<FetchAwardedProducersResults> {
   return httpClient
      .get('producers/awarded/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch(() => {
         throw new Error('apiErrors.500')
      })
}
