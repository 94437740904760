import React, { useEffect, useState } from 'react'
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Snackbar from '@mui/material/Snackbar'
import { useQueryClient } from 'react-query'
import Alert from '@mui/material/Alert'
import { css } from '@emotion/react'

import { createProductsAvailabilityMap } from 'shared/utils/helpers/createProductAvailabilityMap'
import { reportMissingProductService } from 'shared/services/reportMissingProduct'
import { useNotificationContext } from 'shared/context/Notifications'
import columns from 'shared/data/WholesaleAvailabilityColumns/data'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { useCartSummaryQuery } from 'shared/store/useCartSummary'
import { addToCartService } from 'shared/services/addToCart'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { BuyNowPayload, EditModal, Modal, ProductDetails, RemoveModal } from '../../types'
import RemoveWholesalerModal from '../RemoveWholesalerModal'
import EditWholesalerModal from '../EditWholesalerModal'
import { buyNowService } from '../../services'
import { StyledTableWrapper } from './styles'
import ModalComponent from './Modal'
import Actions from './Actions'

interface Props {
   data: CooperatingWholesaler[]
   details?: ProductDetails
   productID: Nullable<string>
}

export default function MainTable({ data, details, productID }: Props) {
   const [wholesalerEditModal, setWholesalerEditModal] = useState<EditModal | undefined>()
   const [wholesalerRemoveModal, setWholesalerRemoveModal] = useState<RemoveModal | undefined>()
   const [modal, setModal] = useState<Modal | undefined>()
   const [isLoading, setIsLoading] = useState(false)
   const { data: userData } = useProfileQuery()
   const { data: cartSummary } = useCartSummaryQuery()
   const { user } = useLogin()
   const [orderSummaryData, setOrderSummaryData] = useState<BuyNowSummaryType>()
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const { addNotification } = useNotificationContext()
   const navigate = useNavigate()
   const [filteredColumns, setFilteredColumns] = useState<MUIDataTableColumn[]>([])
   const queryClient = useQueryClient()

   const options: MUIDataTableOptions = {
      responsive: 'vertical',
      selectableRows: 'none',
      print: false,
      search: false,
      download: false,
      filter: false,
      viewColumns: false,
      fixedHeader: false,
      textLabels: {
         body: {
            columnHeaderTooltip: (column) => `${t('TRANSLATION:sortBy')} ${column.label}`,
         },
      },
      setRowProps: (_, dataIndex: number) => ({
         sx: css`
            & > td:not(:first-of-type):not(:last-of-type) {
               filter: ${data[dataIndex].status !== 'OK_ONLINE_AVAILABILITY' || !data[dataIndex].netPrice
                  ? 'blur(4px)'
                  : 'none'};
            }
            ,
            & > td {
               filter: ${data[dataIndex].status === 'OK_ONLINE_AVAILABILITY' &&
               data[dataIndex].netPrice &&
               (data[dataIndex].totalAvailability === 0 ||
                  (data[dataIndex].totalAvailability !== null && data[dataIndex].totalAvailability! < 0) ||
                  data[dataIndex].totalAvailability === null)
                  ? 'grayScale(1)'
                  : 'none'};
            }
         `,
      }),
      pagination: false,
      customSort: (tableData: any[], colIndex: number, order: string) => {
         const sortedData = tableData.slice().sort((obj1, obj2) => {
            if (typeof obj1.data[colIndex] === 'number' && typeof obj2.data[colIndex] === 'number') {
               return order === 'asc'
                  ? obj1.data[colIndex] - obj2.data[colIndex]
                  : obj2.data[colIndex] - obj1.data[colIndex]
            }
            return 0
         })

         return sortedData
      },
      sortOrder: {
         name: 'formattedNetPrice',
         direction: 'asc',
      },
   }

   async function onBuyNow(payload: BuyNowPayload) {
      if (productID) {
         setIsLoading(true)
         await buyNowService({
            cartName: payload.cartName,
            product: {
               ...payload,
               productsAvailabilityMap: createProductsAvailabilityMap(data),
               productUuid: productID,
               searchSession: '',
               uuid: '',
            },
         })
            .then(({ status, data: buyNowResponse }) => {
               setIsLoading(false)
               if (status === 200) {
                  queryClient.invalidateQueries('ordersHistory')
                  queryClient.invalidateQueries('minimums')
                  queryClient.invalidateQueries('mainCart')
                  setOrderSummaryData(buyNowResponse)
                  setModal(undefined)
                  if (buyNowResponse.product.find((item) => item.status !== 'AVAILABLE')) {
                     setTimeout(() => navigate('/dashboard/order-summary', { state: buyNowResponse }), 4000)
                  }
               }
            })
            .catch((error) => {
               setIsLoading(false)
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
      }
   }
   async function onAddToCart(payload: AddToCartPayload): Promise<{ status: number }> {
      return new Promise((resolve) => {
         if (productID && cartSummary) {
            setIsLoading(true)
            addToCartService(
               {
                  ...payload,
                  productsAvailabilityMap: createProductsAvailabilityMap(data),
                  productUuid: productID,
                  searchSession: '',
                  uuid: '',
               },
               cartSummary.cartDetailsDTO.cartUuid
            )
               .then(({ status }) => {
                  if (status === 200) {
                     addNotification('productAddedToCart', 'success', 'BOTTOM')
                  }
                  resolve({ status })
               })
               .catch((error) => {
                  if (isAxiosError<ErrorType>(error)) {
                     addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
                  } else {
                     addNotification('failedSave', 'error')
                  }
               })
               .finally(() => {
                  invalidateCarts(queryClient)
                  setIsLoading(false)
               })
         }
      })
   }

   async function onReportMissingProduct(payload: MissingProductsService) {
      if (productID) {
         setIsLoading(true)
         await reportMissingProductService({
            productUuid: payload.productUuid,
            wholesalerUuid: payload.wholesalerUuid,
         })
            .then(({ status }) => {
               if (status === 201) {
                  addNotification('productReported', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   useEffect(() => {
      const updatedColumns = [...columns]
      data.forEach((row) => {
         if (row.convertedNetPrice === null) {
            updatedColumns.forEach((column) => {
               if (column.name === 'formattedConvertedNetPrice' || column.name === 'formattedConvertedGrossPrice') {
                  // eslint-disable-next-line no-param-reassign
                  column.options = { ...column.options, display: false }
               }
            })
         } else {
            updatedColumns.forEach((column) => {
               if (column.name === 'formattedConvertedNetPrice' || column.name === 'formattedConvertedGrossPrice') {
                  // eslint-disable-next-line no-param-reassign
                  column.options = { ...column.options, display: true }
               }
            })
         }
      })
      setFilteredColumns(updatedColumns)
   }, [data])

   useEffect(() => {
      setModal(undefined)
   }, [userData?.locationUuid])

   return data.length ? (
      <>
         <StyledTableWrapper>
            <MUIDataTable
               title=""
               data={data}
               columns={[
                  ...filteredColumns,
                  {
                     name: 'actions',
                     options: {
                        sort: false,
                        filter: false,
                        label: '',
                        customBodyRenderLite: (dataIndex: number) => {
                           const wholesalerData = data[dataIndex]
                           return (
                              <Actions
                                 status={wholesalerData.status}
                                 totalAvailability={wholesalerData.totalAvailability}
                                 wholesaleName={wholesalerData.wholesaleName}
                                 isLoading={isLoading}
                                 netPrice={wholesalerData.netPrice}
                                 errorMessage={wholesalerData.errorMessage}
                                 onBuy={() => setModal({ type: 'BUY', data: wholesalerData })}
                                 onAddToCart={() => setModal({ type: 'ORDER', data: wholesalerData })}
                                 onEditWholesaler={() =>
                                    setWholesalerEditModal({
                                       data: wholesalerData,
                                       productUuid: productID || '',
                                    })
                                 }
                                 onRemoveWholesaler={() =>
                                    setWholesalerRemoveModal({
                                       data: wholesalerData,
                                       productUuid: productID || '',
                                    })
                                 }
                                 onReportMissingProduct={() =>
                                    onReportMissingProduct({
                                       wholesalerUuid: wholesalerData.wholesaleUuid,
                                       productUuid: wholesalerData.productUuid,
                                    })
                                 }
                              />
                           )
                        },
                     },
                  },
               ].map((el) => ({
                  ...el,
                  key: el.name,
                  label: t(`TRANSLATION:columnNames.${el.name}`),
               }))}
               options={options}
            />
         </StyledTableWrapper>
         <ModalComponent
            onClose={() => setModal(undefined)}
            modalData={modal}
            producer={details?.producer}
            partIndex={details?.index}
            onBuy={onBuyNow}
            onAddToCart={onAddToCart}
            isLoading={isLoading}
         />
         {user.roles.includes(UserRoles.DATA_MANAGER) && (
            <EditWholesalerModal
               modalInfo={wholesalerEditModal}
               handleClose={() => {
                  setWholesalerEditModal(undefined)
               }}
            />
         )}
         {user.roles.includes(UserRoles.DATA_MANAGER) && (
            <RemoveWholesalerModal
               modalInfo={wholesalerRemoveModal}
               handleClose={() => setWholesalerRemoveModal(undefined)}
            />
         )}
         <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={!!orderSummaryData}
            autoHideDuration={10000}
            onClose={() => setOrderSummaryData(undefined)}
         >
            <Alert
               sx={{
                  fontWeight: 'bold',
                  pt: 0,
                  '&:hover': {
                     cursor: 'pointer',
                     userSelect: 'none',
                  },
               }}
               severity={orderSummaryData?.product.find((item) => item.status !== 'AVAILABLE') ? 'warning' : 'success'}
               onClick={() => navigate('/dashboard/order-summary', { state: orderSummaryData })}
            >
               {orderSummaryData?.product.find((item) => item.status !== 'AVAILABLE')
                  ? t('PRODUCT_DETAILS:tables.buyNowProblem')
                  : t('PRODUCT_DETAILS:tables.buyNowSuccess')}
               <IconButton
                  onClick={(event) => {
                     event.stopPropagation()
                     setOrderSummaryData(undefined)
                  }}
               >
                  <CloseIcon sx={{ fontSize: 20 }} />
               </IconButton>
            </Alert>
         </Snackbar>
      </>
   ) : null
}
