import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import ContainedButton from 'shared/components/Buttons/ContainedButton'

type Props = {
   wholesaleName: string
   handleClose: () => void
}

export default function CooperationRejectedNotification({ wholesaleName, handleClose }: Props) {
   const { t } = useTranslation('NOTIFICATIONS')

   return (
      <Stack direction="column" spacing={2} sx={{ minWidth: 500, padding: 1 }}>
         <Typography variant="body1">{t('coopRejectedText', { wholesaleName })}</Typography>
         <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <ContainedButton variant="contained" onClick={handleClose}>
               OK
            </ContainedButton>
         </div>
      </Stack>
   )
}
