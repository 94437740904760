import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

export async function reportMissingProductService(payload: MissingProductsService) {
   let errMessage

   return httpClient
      .post('/missingProducts/', payload)
      .then((res) => res)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SHARED_SERVICES reportMissingProduct service', errMessage, 'error')
         throw error
      })
}
