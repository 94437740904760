import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { pythonHttpClient } from 'shared/lib'

import { EditModal } from '../../types'

interface Props {
   modalInfo: EditModal | undefined
   handleClose: () => void
}

export default function RemoveWholesalerModal({ modalInfo, handleClose }: Props) {
   const { addNotification } = useNotificationContext()

   function handleDelete() {
      if (modalInfo) {
         pythonHttpClient
            .delete(`products/${modalInfo.productUuid}/wholesales/${modalInfo.data.wholesaleUuid}`)
            .then((res) => {
               if (res.status === 204) {
                  addNotification('successSave', 'success')
                  handleClose()
               }
            })
      }
   }

   return (
      <Dialog open={!!modalInfo} onClose={handleClose}>
         <DialogTitle color="primary">{`Czy na pewno chcesz usunąć z ${modalInfo?.data.wholesaleName}`}</DialogTitle>
         <DialogActions>
            <OutlinedButton type="button" variant="outlined" onClick={handleClose}>
               Anuluj
            </OutlinedButton>
            <ContainedButton onClick={handleDelete} variant="contained">
               Zapisz
            </ContainedButton>
         </DialogActions>
      </Dialog>
   )
}
