import i18n from 'locales/i18n'
import { WholesalerType } from 'shared/store/useWholesalers/types'

export default function mapDeliveryTypes(deliveryTypes: PropType<WholesalerType, 'deliveryTypes'>) {
   if (deliveryTypes) {
      if (deliveryTypes === null) {
         return []
      }
      return Object.entries(deliveryTypes).reduce(
         (result, [mainKey, subObject]) => {
            const subObjectEntries = Object.entries(subObject)

            const foundSubEntry = subObjectEntries.find(([subKey]) => subKey === i18n.language)

            const { 0: subKey, 1: subValue } = foundSubEntry || subObjectEntries[1]
            result.push({ name: subValue, value: mainKey })

            return result
         },
         [] as Array<{ name: string; value: string }>
      )
   }
   return []
}
