import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { useSettingsQuery } from 'shared/store/useSettings'

interface Props {
   value: any
   tableMeta: any
   priceType: 'GROSS' | 'NET'
}

export default function PriceComponent({ value, tableMeta, priceType }: Props) {
   const { t } = useTranslation('PRODUCT_DETAILS')
   const { data } = useSettingsQuery()

   function returnDeposit() {
      switch (priceType) {
         case 'GROSS': {
            if (typeof tableMeta.rowData[10] === 'number') return tableMeta.rowData[10]
            return tableMeta.rowData[9]
         }
         case 'NET': {
            if (typeof tableMeta.rowData[3] === 'number') return tableMeta.rowData[3]
            return tableMeta.rowData[2]
         }
         default: {
            return null
         }
      }
   }

   function ReturnMinObject() {
      const wholesaleWithLowestPrice = tableMeta.currentTableData.reduce((minObject: any, currentObject: any) => {
         if (currentObject.data[1] !== null && currentObject.data[16] !== null) {
            if (minObject === null || currentObject.data[1] < minObject.data[1]) {
               return currentObject
            }
         }
         return minObject
      }, null)

      return wholesaleWithLowestPrice
   }

   function calculatePriceDifference() {
      const lowestPriceWholesale: any = ReturnMinObject()
      const indexOfPrice = tableMeta.rowData[5] === null ? 1 : 5
      let percentageDifference = 0
      if (lowestPriceWholesale) {
         const difference = tableMeta.rowData[indexOfPrice] - lowestPriceWholesale.data[indexOfPrice]
         percentageDifference = (difference / lowestPriceWholesale.data[indexOfPrice]) * 100
      }
      if (percentageDifference !== 0 && percentageDifference !== -100 && value !== 0) {
         return percentageDifference.toFixed(2)
      }
      return null
   }

   return (
      <Box
         sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
         }}
      >
         <span
            style={{
               textDecoration: 'none',
               color: tableMeta.rowData[18] > Number(calculatePriceDifference()) ? '#00CE42' : 'grayText',
               fontSize: 10,
               fontWeight: 'bold',
            }}
         >
            {data?.userSettings.preferences.priceDifferences === 'true' &&
               priceType === 'NET' &&
               tableMeta.rowData[18] !== 0 &&
               tableMeta.rowData[18] !== null &&
               `+${tableMeta.rowData[18]}%`}
         </span>
         <Tooltip
            disableHoverListener={!returnDeposit() || !value}
            title={t('tables.depositTooltip', { price: returnDeposit() }) || ''}
         >
            <Typography
               sx={{
                  cursor: returnDeposit() && value && 'pointer',
                  textDecoration: returnDeposit() && value && 'underline dotted',
                  textUnderlineOffset: 4,
               }}
            >
               {value || 0}
            </Typography>
         </Tooltip>
         <span
            style={{
               textDecoration: 'none',
               color: 'grayText',
               fontSize: 10,
               fontWeight: 'bold',
            }}
         >
            {data?.userSettings.preferences.priceDifferences === 'true' &&
               calculatePriceDifference() &&
               priceType === 'NET' &&
               tableMeta.rowData[16] !== 0 &&
               tableMeta.rowData[16] !== null &&
               `+${calculatePriceDifference()}%`}
         </span>
      </Box>
   )
}
