import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import CompanyForm from './components/CompanyForm'
import { pl, en, de } from './locales'

export default function Company() {
   const { t, i18n } = useTranslation(['COMPANY_SETTINGS'])

   i18n.addResourceBundle('pl-PL', 'COMPANY_SETTINGS', pl)
   i18n.addResourceBundle('en-EN', 'COMPANY_SETTINGS', en)
   i18n.addResourceBundle('de-DE', 'COMPANY_SETTINGS', de)

   return (
      <Box>
         <Typography variant="h4" color="primary" sx={{ pb: 3, fontWeight: 'normal' }}>
            {t('COMPANY_SETTINGS:companyDataLabel')}
         </Typography>
         <CompanyForm />
      </Box>
   )
}
