import { AxiosError } from 'axios'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import consoleMessage from '../utils/helpers/consoleMessage'

export async function fetchNotCooperatingWholesalersData(productUuid: string): Promise<FetchNoCooperationDataResult> {
   let errMessage = ''

   return httpClient
      .get<FetchNotCooperatingWholesalersDataType>(`/products/${productUuid}/offline-availability-in-not-cooperating`)
      .then((response) => {
         if (response.data) {
            return {
               notCooperatingWholesalers: response.data,
            }
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SHARED_SERVICES fetchNotCooperatingWholesalersData service', errMessage, 'error')
         throw error
      })
}
