import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'

import { StyledCard, DeleteCorner, EditCorner, StyledImg } from './styles'
import { ModalType } from '../../types'

interface Props {
   handleModalType: (type: ModalType) => void
}

export default function CoopCard({ handleModalType }: Props) {
   return (
      <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
         <StyledCard>
            <DeleteCorner onClick={() => handleModalType('delete')}>
               <DeleteIcon />
            </DeleteCorner>
            <EditCorner onClick={() => handleModalType('edit')}>
               <EditIcon />
            </EditCorner>
            <StyledImg src={`${process.env.REACT_APP_FILES_URL}/img/integrations/baselinker.png`} alt="Baselinker" />
         </StyledCard>
      </Grid>
   )
}
