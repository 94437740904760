/* eslint-disable no-restricted-syntax */
import { CountriesType } from 'shared/store/useCountries/types'

export function findCountryKeyByName(data: CountriesType, countryName: string) {
   if (data) {
      for (const key of Object.keys(data)) {
         if (data[key].name === countryName) {
            return key
         }
      }
   }
   return 'Poland'
}
