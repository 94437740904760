import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { fetchSettingsData, saveSettingsData } from './services'
import { SettingsResponseType } from './types'

async function getSettingsData() {
   const request = await fetchSettingsData().then((response) => ({
      ...response,
      userSettings: {
         ...response.userSettings,
         preferences: {
            ...response.userSettings.preferences,
         },
      },
   }))

   return request
}

export function useSettingsQuery(): UseQueryResult<SettingsResponseType> {
   return useQuery('userSettings', getSettingsData, { staleTime: 60 * (60 * 1000) })
}

export function useSettingsMutation(isSilent: boolean) {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, Error | AxiosError<ErrorType>, SettingsType, unknown>(
      (payload) => {
         return saveSettingsData(payload)
      },
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('userSettings')
               if (!isSilent) {
                  addNotification('successSave', 'success')
               }
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  if (!isSilent) {
                     addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
                  }
               } else if (!isSilent) {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
