import { Yup } from 'shared/lib'

const validName =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßčÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž])$/

export const schema = Yup.object().shape({
   name: Yup.string().min(3).max(32).matches(validName, 'validation.validName').required(),
   surname: Yup.string().min(3).max(32).matches(validName, 'validation.validSurname').required(),
   email: Yup.string().min(3).max(64).email().required(),
   phoneNumber: Yup.string().required(),
   acceptRegulations: Yup.boolean().required().isTrue(),
})
