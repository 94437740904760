import AppBar from '@mui/material/AppBar'
import { styled, alpha } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

export const RootStyle = styled(AppBar)(({ theme }) => ({
   boxShadow: 'none',
   position: 'sticky',
   backdropFilter: 'blur(6px)',
   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
   backgroundColor: alpha(theme.palette.background.default, 0.72),
   padding: '20px 0',
   [theme.breakpoints.up('lg')]: {
      width: '100%',
   },
}))

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
   minHeight: 64,
   [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 5),
   },
}))

export const StyledLogo = styled('img')(({ theme }) => ({
   display: 'block',
   maxHeight: theme.sizes.headerSm,
   maxWidth: 120,
   [theme.breakpoints.up('lg')]: {
      maxWidth: 190,
   },
}))
