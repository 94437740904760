export function handleLocationAllowedChange(
   locationUuid: string,
   allowedLocations: string[],
   setAllowedLocations: (allowedLocations: string[]) => void
) {
   let tempAllowedLocations = [...allowedLocations]

   if (tempAllowedLocations.includes(locationUuid)) {
      tempAllowedLocations = tempAllowedLocations.filter((item) => item !== locationUuid)
   } else {
      tempAllowedLocations.push(locationUuid)
   }
   setAllowedLocations(tempAllowedLocations)
}
