import React, { useEffect } from 'react'
import { MUIDataTableMeta } from 'mui-datatables'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import { UserRoles } from 'shared/consts'
import useLogin from 'shared/store/useLogin'
import { useTranslation } from 'react-i18next'

interface Props {
   value: string
   tableMeta?: MUIDataTableMeta
}

export default function SalerLogo({ value, tableMeta }: Props) {
   const { user } = useLogin()
   const { t } = useTranslation('TRANSLATION')

   // eslint-disable-next-line @typescript-eslint/no-empty-function
   useEffect(() => {}, [user.roles])

   // eslint-disable-next-line no-nested-ternary
   return value ? (
      user?.roles.includes(UserRoles.SALES_REP) ? (
         <span>{value}</span>
      ) : (
         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
               style={{ verticalAlign: 'middle', height: 20, width: 'auto' }}
               src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${value}.png`}
               alt={value}
            />
            <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'center' }}>
               {tableMeta?.rowData[1] && tableMeta?.rowData[22] && (
                  <Tooltip title={tableMeta.rowData[22]}>
                     <InfoIcon sx={{ '&:hover': { cursor: 'pointer' } }} color="primary" />
                  </Tooltip>
               )}
               {tableMeta?.rowData[20] && (
                  <Tooltip title={t('TRANSLATION:requirePrepayment')}>
                     <AttachMoneyIcon color="success" />
                  </Tooltip>
               )}
            </Box>
         </Box>
      )
   ) : (
      <p>No Logo</p>
   )
}
