import { Product } from '../types'

function getProductName(product: Product) {
   if (product.cartProductName) {
      return product.cartProductName.split(' ').length === 1
         ? product.cartProductName.split(' ').slice(0, 1).join(' ')
         : product.cartProductName.split(' ').slice(0, 2).join(' ')
   }
   return product.index
}

function getSum(products: Product[], priceType: 'NET' | 'GROSS') {
   if (products) {
      return priceType === 'NET'
         ? products
              .filter((item) => item.includedProductInOrder)
              .reduce(
                 (acc, current) => acc + current.cartWholesaleDTO.clientNetPrice * current.cartWholesaleDTO.quantity,
                 0
              )
              .toFixed(2)
         : products
              .filter((item) => item.includedProductInOrder)
              .reduce(
                 (acc, current) => acc + current.cartWholesaleDTO.clientGrossPrice * current.cartWholesaleDTO.quantity,
                 0
              )
              .toFixed(2)
   }
   return '-'
}

export { getProductName, getSum }
