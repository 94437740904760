import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

type Result = {
   status: number
}

export function sendResetEmailService(payload: SendResetEmailPayload) {
   let errMessage = ''

   return httpClient
      .post<Result>('users/reset-password', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT_CART_REFLINK confirmOrder service', errMessage, 'error')
         throw error
      })
}
