import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useNotificationContext } from 'shared/context/Notifications'
import Hidden from 'shared/components/Hidden'
import { httpClient } from 'shared/lib'

import MainCartDesktop from './components/Desktop'
import MainCartMobile from './components/Mobile'
import { pl, en, de } from './locales'

export default function MainCart() {
   const { addNotification } = useNotificationContext()
   const [isOrdering, setIsOrdering] = useState(false)
   const { i18n } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const navigate = useNavigate()
   const queryClient = useQueryClient()

   async function onOrderSubmit() {
      setIsOrdering(true)
      await httpClient
         .post('/orders/')
         .then((res) => {
            if (res.status === 200) {
               navigate('/dashboard/order-summary', { state: res.data })
            } else if (res.status === 204) {
               addNotification('processingOrder', 'success')
            }
         })
         .catch(() => addNotification('failedOrder', 'error'))
         .finally(() => {
            queryClient.invalidateQueries('ordersHistory')
            queryClient.invalidateQueries('mainCart')
            queryClient.invalidateQueries('savedCarts')
            queryClient.invalidateQueries('minimums')
            setIsOrdering(false)
         })
   }

   i18n.addResourceBundle('pl-PL', 'MAIN_CART', pl)
   i18n.addResourceBundle('en-EN', 'MAIN_CART', en)
   i18n.addResourceBundle('de-DE', 'MAIN_CART', de)

   return (
      <>
         <Hidden width="mdDown">
            <MainCartDesktop handleOrderSubmit={onOrderSubmit} isOrdering={isOrdering} />
         </Hidden>
         <Hidden width="mdUp">
            <MainCartMobile handleOrderSubmit={onOrderSubmit} isOrdering={isOrdering} />
         </Hidden>
      </>
   )
}
