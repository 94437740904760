import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import pricingPlans from 'shared/data/PricingPlans'
import { httpClient } from 'shared/lib'

import { PricesResultType, SubscriptionDurationType } from './types'
import checkCouponService from './services/checkCouponService'
import PricingPlanCard from './components/PricingPlanCard'
import { RootStyleMain, StyledButton } from './styles'
import { en, pl, de } from './locales'

export default function Pricing() {
   const { i18n, t } = useTranslation('PRICING')
   const [prices, setPrices] = useState<PricesResultType>()
   const [isLoading, setIsLoading] = useState(false)
   const [rebate, setRebate] = useState(1)
   const [coupon, setCoupon] = useState('')

   const { addNotification } = useNotificationContext()
   const [duration, setDuration] = useState<SubscriptionDurationType>('YEAR')
   i18n.addResourceBundle('pl-PL', 'PRICING', pl)
   i18n.addResourceBundle('en-EN', 'PRICING', en)
   i18n.addResourceBundle('de-DE', 'PRICING', de)

   function handleSubmit() {
      setIsLoading(true)

      checkCouponService(coupon)
         .then((res) => {
            if (res.status === 200) {
               addNotification('PRICING:successDiscountCode', 'success')
               setCoupon('')
               if (res.data) {
                  if (res.data.couponType === 'REBATE') {
                     const rebateNumber = Number(res.data.rebateCouponDTO.rebatePercent)
                     setRebate(rebateNumber)
                  }
               }
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   useEffect(() => {
      httpClient
         .get<PricesResultType>('subscription/prices')
         .then((res) => {
            setPrices(res.data)
         })
         .catch(() => {
            addNotification(t('loadingFailed'), 'error')
         })
   }, [httpClient])

   return (
      <RootStyleMain>
         <Container maxWidth="lg">
            <Box sx={{ display: 'flex', gap: 10 }}>
               <Typography sx={{ mt: 2, mb: -2, color: 'primary.main' }} variant="h2" align="center" paragraph>
                  {t('pricingLabel')}
               </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                  <Typography
                     variant="body1"
                     sx={{
                        py: 0.5,
                        mt: 1,
                        px: 1.5,
                        backgroundColor: 'rgb(0,224,19,0.6)',
                        borderRadius: 1.4,
                        color: 'darkgreen',
                        fontWeight: 'bold',
                     }}
                  >
                     -20%
                  </Typography>
                  <StyledButton
                     onClick={() => setDuration('YEAR')}
                     sx={{
                        color: duration === 'YEAR' ? 'white' : 'grayText',
                        backgroundColor: duration !== 'YEAR' ? 'white' : 'primary',
                        minWidth: 300,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                     }}
                     variant={duration === 'YEAR' ? 'contained' : 'text'}
                  >
                     {t('yearPlan')}
                  </StyledButton>
               </Box>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                  <Typography
                     variant="body1"
                     sx={{
                        py: 0.5,
                        mt: 1,
                        px: 1.5,
                        backgroundColor: 'rgb(0,224,19,0.6)',
                        borderRadius: 1.4,
                        color: 'darkgreen',
                        fontWeight: 'bold',
                     }}
                  >
                     -30%
                  </Typography>
                  <StyledButton
                     onClick={() => setDuration('TWO_YEARS')}
                     sx={{
                        color: duration === 'TWO_YEARS' ? 'white' : 'grayText',
                        backgroundColor: duration !== 'TWO_YEARS' ? 'white' : 'primary',
                        minWidth: 300,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        fontSize: 14,
                     }}
                     variant={duration === 'TWO_YEARS' ? 'contained' : 'text'}
                  >
                     {t('twoYearsPlan')}
                  </StyledButton>
               </Box>
            </Box>
            <Grid container sx={{ my: 1 }} spacing={3}>
               {prices &&
                  pricingPlans.map((card) => (
                     <Grid item xs={12} md={4} key={card.subscription}>
                        <PricingPlanCard
                           rebate={rebate}
                           prices={prices}
                           durationTime={duration}
                           card={card}
                           coupon={coupon}
                        />
                     </Grid>
                  ))}
            </Grid>
            {prices && (
               <Box sx={{ mb: 2, mt: 3 }}>
                  <Stack direction="row" alignItems="center" justifyContent={{ md: 'flex-start', xs: 'center' }}>
                     <TextField
                        onChange={(e) => setCoupon(e.target.value)}
                        fullWidth={false}
                        value={coupon}
                        variant="standard"
                        type="text"
                        label={t('discountLabel')}
                     />
                     <ContainedButton
                        disabled={isLoading || !coupon}
                        sx={{ fontSize: 13, marginLeft: 2, marginTop: 1.5 }}
                        variant="contained"
                        onClick={handleSubmit}
                     >
                        {t('enterButtonLabel')}
                     </ContainedButton>
                  </Stack>
               </Box>
            )}
            <Typography color="grayText" variant="body2">
               {t('relog')}
            </Typography>
         </Container>
      </RootStyleMain>
   )
}
