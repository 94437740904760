import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Box from '@mui/material/Box'

import { CreateReflinkPayload } from 'shared/store/useClientReflinks/types'
import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useAddReflinkMutation } from 'shared/store/useClientReflinks'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import MuiPhone from 'shared/components/PhoneInput'

interface Props {
   handleClose: () => void
}

type AddReflinkType = {
   phoneNumber: string
   cartName: string
}

export default function AddReflinkModalContent({ handleClose }: Props) {
   const [addReflinkData, setAddReflinkData] = useState<AddReflinkType>({
      phoneNumber: '',
      cartName: '',
   })
   const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false)
   const { mutate, isLoading } = useAddReflinkMutation()
   const { t } = useTranslation(['TRANSLATION', 'CLIENTS_CARTS'])

   function handlePhoneNumber(value: string, country: string) {
      setAddReflinkData({
         ...addReflinkData,
         phoneNumber: value,
      })
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   function createReflink() {
      const payload: CreateReflinkPayload = {
         ...addReflinkData,
      }
      mutate(payload, {
         onSuccess: () => {
            handleClose()
         },
      })
   }

   return (
      <>
         <DialogContent>
            <Box sx={{ px: 1, pt: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
               <TextField
                  label={t('TRANSLATION:columnNames.cartName')}
                  onChange={(e) => setAddReflinkData({ ...addReflinkData, cartName: e.target.value })}
               />
               <MuiPhone
                  label={t('CLIENTS_CARTS:clientPhoneNumber')}
                  variant="outlined"
                  value={addReflinkData.phoneNumber}
                  onChange={(value, country) => handlePhoneNumber(value, country)}
               />
            </Box>
         </DialogContent>
         <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton
               sx={{ alignSelf: 'center' }}
               variant="contained"
               onClick={createReflink}
               disabled={phoneNumberError || !addReflinkData.phoneNumber || isLoading}
            >
               {t('TRANSLATION:save')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
