import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function SessionEnd() {
   const { t } = useTranslation('UNAUTHORIZED')
   const [reason, setReason] = useState<'subscription' | 'expired' | 'loggedOut'>('expired')
   const navigate = useNavigate()
   const [searchParams] = useSearchParams()
   useEffect(() => {
      const loggedOutReason = searchParams.get('reason')
      if (loggedOutReason) {
         switch (loggedOutReason) {
            case 'subscription': {
               setReason('subscription')
               break
            }
            case 'expired': {
               setReason('expired')
               break
            }
            case 'loggedOut': {
               setReason('loggedOut')
               break
            }
            default:
               setReason('expired')
         }
      }
      const timer = setTimeout(() => navigate('/'), 6000)
      return () => clearTimeout(timer)
   }, [navigate, searchParams])

   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            alignItems: 'center',
            pt: 20,
            width: '100%',
         }}
      >
         <Typography variant="h4">{reason && t(`sessionEnd.logoutReason.${reason}`)}</Typography>
         <Typography variant="h5">{t('sessionEnd.subtitle')}</Typography>
      </Box>
   )
}
