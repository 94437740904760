import { useFormik, Form, FormikProvider } from 'formik'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import Stack from '@mui/material/Stack'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import useLogin from 'shared/store/useLogin'
import { Yup } from 'shared/lib'

import { sendResetEmailService } from './services'

const VALIDATION_SCHEMA = Yup.object().shape({
   email: Yup.string().email().min(3).max(64).required(),
})

type InitialValues = {
   email: string
}

export default function RemindPasswordForm() {
   const { t } = useTranslation(['TRANSLATION', 'HOMEPAGE'])
   const { isLoading } = useLogin()
   const [recaptcha, setRecaptcha] = useState('')
   const { addNotification } = useNotificationContext()
   const formik = useFormik<InitialValues>({
      initialValues: {
         email: '',
      },
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: handleSubmit,
   })
   const recaptchaRef = useRef<ReCAPTCHA>(null)

   const { values, errors } = formik

   function handleSubmit() {
      const payload = {
         email: values.email,
         recaptcha,
      }
      sendResetEmailService(payload)
         .then((res) => {
            if (res.status === 200) {
               addNotification(t('HOMEPAGE:remindForm.successSend'), 'success')
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data.errors[0].code || t('HOMEPAGE:remindForm.failedSend'), 'error')
            } else {
               addNotification(t('HOMEPAGE:remindForm.failedSend'), 'error')
            }
         })
         .finally(() => {
            if (recaptchaRef.current) {
               recaptchaRef.current.reset()
            }
            setRecaptcha('')
         })
   }
   return (
      <FormikProvider value={formik}>
         <Form autoComplete="off" noValidate>
            <Stack spacing={3}>
               <InputField
                  name="email"
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label={t('TRANSLATION:formFields.email')}
               />
               <ReCAPTCHA
                  sitekey="6LebPrIUAAAAAEtQ6YCkPH5RO-V1WNeAn5-dNCOc"
                  ref={recaptchaRef}
                  onChange={(value) => setRecaptcha(value || '')}
               />
               <ContainedButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={Boolean(errors.email) || isLoading || !recaptcha}
                  sx={{ mt: 4 }}
               >
                  {t('HOMEPAGE:remindForm.remind')}
               </ContainedButton>
            </Stack>
         </Form>
      </FormikProvider>
   )
}
