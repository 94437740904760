import React, { useEffect, useState } from 'react'
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { css } from '@emotion/react'

import { createProductsAvailabilityMap } from 'shared/utils/helpers/createProductAvailabilityMap'
import { useCartSummaryQuery, useRemoveProductMutation } from 'shared/store/useCartSummary'
import { reportMissingProductService } from 'shared/services/reportMissingProduct'
import { RemoveProductPayload } from 'shared/store/useCartSummary/types'
import { useNotificationContext } from 'shared/context/Notifications'
import columns from 'shared/data/WholesaleAvailabilityColumns/data'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { addToCartService } from 'shared/services/addToCart'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { StyledTableWrapper } from './styles'
import { Modal } from '../../../types'
import ModalComponent from './Modal'
import Actions from './Actions'

interface Props {
   data: CooperatingWholesaler[]
   productToRemoveUuid: string
   defaultQuantity: number
   removeFromUnavailable: boolean
   productID: Nullable<string>
   handleModalClose: () => void
}

export default function MainTable({
   data,
   productID,
   productToRemoveUuid,
   removeFromUnavailable,
   defaultQuantity,
   handleModalClose,
}: Props) {
   const [isLoading, setIsLoading] = useState(false)
   const [modal, setModal] = useState<Modal | undefined>()
   const { data: cartSummary } = useCartSummaryQuery()
   const { mutate } = useRemoveProductMutation()
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const { addNotification } = useNotificationContext()
   const [filteredColumns, setFilteredColumns] = useState<MUIDataTableColumn[]>([])
   const queryClient = useQueryClient()

   const options: MUIDataTableOptions = {
      responsive: 'simple',
      selectableRows: 'none',
      print: false,
      search: false,
      download: false,
      filter: false,
      viewColumns: false,
      fixedHeader: false,
      textLabels: {
         body: {
            columnHeaderTooltip: (column) => `${t('TRANSLATION:sortBy')} ${column.label}`,
         },
      },
      setRowProps: (_, dataIndex: number) => ({
         sx: css`
            & > td:not(:first-of-type):not(:last-of-type) {
               filter: ${data[dataIndex].status !== 'OK_ONLINE_AVAILABILITY' || !data[dataIndex].netPrice
                  ? 'blur(4px)'
                  : 'none'};
            }
            ,
            & > td {
               filter: ${data[dataIndex].status === 'OK_ONLINE_AVAILABILITY' &&
               data[dataIndex].netPrice &&
               (data[dataIndex].totalAvailability === 0 || data[dataIndex].totalAvailability === null)
                  ? 'grayScale(1)'
                  : 'none'};
            }
         `,
      }),
      pagination: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customSort: (tableData: any[], colIndex: number, order: string) => {
         const sortedData = tableData.slice().sort((obj1, obj2) => {
            if (typeof obj1.data[colIndex] === 'number' && typeof obj2.data[colIndex] === 'number') {
               return order === 'asc'
                  ? obj1.data[colIndex] - obj2.data[colIndex]
                  : obj2.data[colIndex] - obj1.data[colIndex]
            }
            return 0
         })

         return sortedData
      },
      sortOrder: {
         name: 'formattedNetPrice',
         direction: 'asc',
      },
   }

   async function onSwapProduct(payload: AddToCartPayload): Promise<{ status: number }> {
      return new Promise((resolve) => {
         if (productID && cartSummary) {
            setIsLoading(true)
            if (productToRemoveUuid) {
               const removePayload: RemoveProductPayload = {
                  uuid: productToRemoveUuid,
                  cartUuid: cartSummary.cartDetailsDTO.cartUuid,
                  removeFromUnavailable: removeFromUnavailable || false,
               }
               mutate(removePayload, {
                  onSuccess: () => {
                     addToCartService(
                        {
                           ...payload,
                           productsAvailabilityMap: createProductsAvailabilityMap(data),
                           productUuid: productID,
                           searchSession: '',
                           uuid: '',
                        },
                        cartSummary.cartDetailsDTO.cartUuid
                     )
                        .then(({ status }) => {
                           if (status === 200) {
                              invalidateCarts(queryClient)
                              handleModalClose()
                              resolve({ status })
                           }
                        })
                        .catch((error) => {
                           if (isAxiosError<ErrorType>(error)) {
                              addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
                           } else {
                              addNotification('failedSave', 'error')
                           }
                        })
                        .finally(() => {
                           setIsLoading(false)
                        })
                  },
               })
            }
         }
      })
   }

   async function onReportMissingProduct(payload: MissingProductsService) {
      if (productID) {
         setIsLoading(true)
         await reportMissingProductService({
            productUuid: payload.productUuid,
            wholesalerUuid: payload.wholesalerUuid,
         })
            .then(({ status }) => {
               if (status === 201) {
                  addNotification('productReported', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   useEffect(() => {
      const updatedColumns = [...columns]
      data.forEach((row) => {
         if (row.convertedNetPrice === null) {
            updatedColumns.forEach((column) => {
               if (column.name === 'formattedConvertedNetPrice' || column.name === 'formattedConvertedGrossPrice') {
                  // eslint-disable-next-line no-param-reassign
                  column.options = { ...column.options, display: false }
               }
            })
         } else {
            updatedColumns.forEach((column) => {
               if (column.name === 'formattedConvertedNetPrice' || column.name === 'formattedConvertedGrossPrice') {
                  // eslint-disable-next-line no-param-reassign
                  column.options = { ...column.options, display: true }
               }
            })
         }
      })
      setFilteredColumns(updatedColumns)
   }, [data])

   return data.length ? (
      <>
         <StyledTableWrapper>
            <MUIDataTable
               title=""
               data={data}
               columns={[
                  ...filteredColumns,
                  {
                     name: 'actions',
                     options: {
                        sort: false,
                        filter: false,
                        customHeadLabelRender: () => <div />,
                        customBodyRenderLite: (dataIndex: number) => {
                           const wholesalerData = data[dataIndex]
                           return (
                              <Actions
                                 status={wholesalerData.status}
                                 totalAvailability={wholesalerData.totalAvailability}
                                 isLoading={isLoading}
                                 netPrice={wholesalerData.netPrice}
                                 errorMessage={wholesalerData.errorMessage}
                                 onReportMissingProduct={() =>
                                    onReportMissingProduct({
                                       wholesalerUuid: wholesalerData.wholesaleUuid,
                                       productUuid: wholesalerData.productUuid,
                                    })
                                 }
                                 onSwapProduct={() => setModal({ data: wholesalerData, defaultQuantity })}
                              />
                           )
                        },
                     },
                  },
               ].map((el) => ({
                  ...el,
                  key: el.name,
                  label: t(`TRANSLATION:columnNames.${el.name}`),
               }))}
               options={options}
            />
         </StyledTableWrapper>
         <ModalComponent
            onClose={() => setModal(undefined)}
            modalData={modal}
            onSwapProduct={onSwapProduct}
            isLoading={isLoading}
         />
      </>
   ) : null
}
