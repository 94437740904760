import { Yup } from 'shared/lib'

const validName =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž])$/
const validPassword =
   /^(?=.*[0-9])(?=.*[A-Z-äÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž])[a-zA-Z0-9äÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž!@#$%^&*()]{6,32}$/

const changePasswordSchema = Yup.object().shape({
   password: Yup.string().matches(validPassword, 'validation.passwordPattern').required(),
   confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'validation.confirmPassword'),
})

const editUserSchema = Yup.object().shape({
   name: Yup.string().min(3).max(32).matches(validName, 'validation.validName').required(),
   surname: Yup.string().min(3).max(32).matches(validName, 'validation.validSurname').required(),
   phoneNumber: Yup.string().required(),
   uuid: Yup.string().required(),
   email: Yup.string().min(3).max(64).email().required(),
})

const createUserSchema = Yup.object().shape({
   name: Yup.string().min(3).max(32).matches(validName, 'validation.validName').required(),
   surname: Yup.string().min(3).max(32).matches(validName, 'validation.validSurname').required(),
   phoneNumber: Yup.string().required(),
   email: Yup.string().min(3).max(64).email().required(),
})

export { editUserSchema, changePasswordSchema, createUserSchema }
