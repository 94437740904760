export function createProductsAvailabilityMap(data: CooperatingWholesaler[]) {
   return data
      .filter((wholesaler) => !!wholesaler.netPrice)
      .reduce<ProductsAvailabilityMapType>(
         (acc, currentItem) =>
            Object.assign(acc, {
               [currentItem.wholesaleUuid]: {
                  WHOLESALE_UUID: currentItem.wholesaleUuid,
                  NAME: currentItem.wholesaleName,
                  PRICE: currentItem.normalizedPrice,
                  DEPARTMENT1: currentItem.firstDepartmentAvailability || 0,
                  DEPARTMENT2: currentItem.secondDepartmentAvailability || 0,
                  DEPARTMENT3: currentItem.thirdDepartmentAvailability || 0,
                  TOTAL_AVAILABILITY: currentItem.totalAvailability || 0,
                  STATUS: currentItem.status,
               },
            }),
         {}
      )
}
