import { styled } from '@mui/material'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'

export const StyledTableRow = styled(TableRow)({
   backgroundColor: 'rgba(255,255,255,0.2)',
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.03)',
      cursor: 'pointer',
   },
})

export const StyledTitleTableCell = styled(TableCell)({
   color: '#637381',
   fontWeight: 'bold',
   textAlign: 'center',
})

export const StyledFooterTableCell = styled(TableCell)({
   padding: '2px 4px 2px 4px',
   color: '#637381',
   fontWeight: 'bold',
   textAlign: 'center',
})

export const StyledTableCell = styled(TableCell)({
   padding: '6px 4px 6px 4px',
   textAlign: 'center',
   fontWeight: 'bold',
})

export const StyledCollapseTableCell = styled(TableCell)({
   textAlign: 'center',
   color: 'grayText',
})

export const StyledPrintingTableCell = styled(TableCell)({
   padding: 0,
   textAlign: 'center',
   fontWeight: 'bold',
})

export const QuantityHolder = styled(Box)({
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'space-between',
   margin: '0 auto',
   padding: 4,
   paddingLeft: 20,
   paddingRight: 20,
   maxWidth: 120,
   border: '1px solid #C4C2C4',
   borderRadius: 10,
})

export const StyledQuantityButton = styled('button')(({ theme }) => ({
   border: 'none',
   background: 'rgba(0, 0, 0, 0)',
   fontWeight: 'bold',
   fontSize: 13,
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
   },
}))

export const StyledTitleRow = styled(TableRow)({
   backgroundColor: '#EBEBEB',
})

export const StyledDeleteIcon = styled(ClearIcon)({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: 'red',
   },
})

const HourDiv = styled('div')({
   margin: '0 auto',
   padding: 5,
   maxWidth: '120px',
   borderRadius: 5,
   textAlign: 'center',
   fontWeight: 'bold',
})

export const GreenHour = styled(HourDiv)({
   backgroundColor: 'rgba(89, 255, 0, 0.39)',
   color: 'green',
})

export const YellowHour = styled(HourDiv)({
   backgroundColor: 'rgba(255, 255, 0, 0.23)',
   color: 'orange',
})

export const RedHour = styled(HourDiv)({
   backgroundColor: 'rgba(255, 0, 0, 0.29)',
   color: 'red',
})

export const StyledCopyIcon = styled(FileCopyOutlinedIcon)(({ theme }) => ({
   marginLeft: 3,
   marginTop: 1,
   color: 'grayText',
   fontSize: 14,
   '&:hover': {
      color: theme.palette.primary.light,
   },
}))

export const StyledInput = styled('input')({
   width: '80px',
   border: 'none',
   backgroundColor: 'rgba(0,0,0,0)',
   '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold',
   },
})

export const StyledAvailableParagraph = styled('p')({
   marginTop: 0,
   marginBottom: 0,
   fontSize: 12,
   opacity: 0.5,
   color: 'GrayText',
   fontWeight: 'lighter',
})
