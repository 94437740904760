import { AxiosError } from 'axios'
import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

export default function swapUserLocationService(locationUuid: string) {
   let errMessage

   return httpClient
      .post<{ status: number }>(`locations/swap/${locationUuid}`)
      .then((res) => ({ status: res.status, data: res.data }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SHARED_SERVICES swapUserLocation service', errMessage, 'error')
         throw error
      })
}
