import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { GetSubscriptionInfoResult, EditUserPayload, ChangePasswordPayload, CreateUserPayload } from '../types'

export function removeUserService(userUuid: string) {
   let errMessage = ''
   return httpClient
      .delete<Result>(`companies/users/${userUuid}`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('USERS_AND_SUBSCRIPTION removeUserService service', errMessage, 'error')
         throw error
      })
}

export function getSubscriptionEndDateService() {
   let errMessage = ''
   return httpClient
      .get<GetSubscriptionInfoResult>('subscription/info')
      .then((res) => {
         return res.data
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('USERS_AND_SUBSCRIPTION getSubscriptionEndDate service', errMessage, 'error')
         throw error
      })
}

export function editUserService(payload: EditUserPayload, userUuid: string) {
   let errMessage = ''
   return httpClient
      .put<Result>(`companies/users/${userUuid}`, payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('USERS_AND_SUBSCRIPTION editUser service', errMessage, 'error')
         throw error
      })
}

export function changeUserPasswordService(payload: ChangePasswordPayload, userUuid: string) {
   let errMessage = ''
   return httpClient
      .put<Result>(`companies/users/${userUuid}/change-password`, payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('USERS_AND_SUBSCRIPTION changeUserPassword service', errMessage, 'error')
         throw error
      })
}

export function addUserService(payload: CreateUserPayload) {
   let errMessage = ''
   return httpClient
      .post<Result>('companies/users', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('USERS_AND_SUBSCRIPTION addUser service', errMessage, 'error')
         throw error
      })
}
