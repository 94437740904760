import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { UnAuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'
import logo from 'assets/images/logo-large.png'
import Footer from '../../components/Footer'

import { en, pl, de } from './locales'

export default function CooperationFormCompleted() {
   const { t, i18n } = useTranslation('FORM_COMPLETED')
   const navigate = useNavigate()
   i18n.addResourceBundle('en-EN', 'FORM_COMPLETED', en)
   i18n.addResourceBundle('pl-PL', 'FORM_COMPLETED', pl)
   i18n.addResourceBundle('de-DE', 'FORM_COMPLETED', de)

   useEffect(() => {
      const timer = setTimeout(() => navigate('/'), 10000)
      return () => clearTimeout(timer)
   }, [])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
         <Container sx={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end', pt: 5 }}>
            <LanguagePopover />
         </Container>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               gap: 3,
               paddingTop: '10%',
               minHeight: 'calc(100vh - 139px)',
               textAlign: 'center',
               color: 'text.secondary',
            }}
         >
            <img style={{ width: 400, height: 'auto', margin: '0 auto' }} src={logo} alt="logo" />
            <Typography variant="h3">{t('thanks')}</Typography>
            <Typography variant="h4">{t('redirect')}</Typography>
         </Box>
         <Footer />
      </Box>
   )
}
