import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { AddServicePayload, AdditionalServicesResponseType } from './types'
import { addService, deleteService, editService, fetchServices } from './services'

export default function useAdditionalServicesQuery(
   locationUuid?: string
): UseQueryResult<AdditionalServicesResponseType> {
   return useQuery(['services', 'list', locationUuid], () => fetchServices(locationUuid), {
      enabled: !!locationUuid,
   })
}

export function useAddServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      AxiosError<ErrorType> | Error,
      { payload: AddServicePayload; locationUuid: string },
      unknown
   >(({ payload, locationUuid }) => addService(payload, locationUuid), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('services')
            addNotification('successSave', 'success')
         }
      },
      onError: (error) => {
         if (error) {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         }
      },
   })
}

export function useEditServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      AxiosError<ErrorType> | Error,
      { payload: AddServicePayload; locationUuid: string; serviceUuid: string },
      unknown
   >(({ payload, locationUuid, serviceUuid }) => editService(payload, locationUuid, serviceUuid), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('services')
            addNotification('successSave', 'success')
         }
      },
      onError: (error) => {
         if (error) {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         }
      },
   })
}

export function useDeleteServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, { locationUuid: string; serviceUuid: string }, unknown>(
      ({ locationUuid, serviceUuid }) => deleteService(locationUuid, serviceUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('services')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
