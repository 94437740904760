import { useQuery, UseQueryResult } from 'react-query'
import { fetchAwardedProducers } from './services'
import { AwardedProducers } from './types'

async function getAwardedProducers() {
   const request = await fetchAwardedProducers().then((response) =>
      response.awardedProducers.map((producer) => ({
         ...producer,
      }))
   )

   return request
}

export default function useAwardedProducers(): UseQueryResult<AwardedProducers> {
   return useQuery('awardedProducers', getAwardedProducers, { staleTime: 60 * (60 * 1000) })
}
