import { useQuery, UseQueryResult } from 'react-query'
import { fetchMinimums } from './services'
import { FetchMinimumsResult } from './types'

export function useLogisticMinimumsQuery(hideUnchanged: boolean): UseQueryResult<FetchMinimumsResult> {
   return useQuery(['minimums', hideUnchanged], () => fetchMinimums(hideUnchanged), {
      staleTime: 3600000,
      refetchOnWindowFocus: 'always',
   })
}
