import React, { useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'
import PrintIcon from '@mui/icons-material/Print'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import LinkIcon from '@mui/icons-material/Link'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useQueryClient } from 'react-query'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'

import { useLogisticMinimumsQuery } from 'shared/store/useLogisticMinimums'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { useProfileQuery } from 'shared/store/useProfile'
import InputField from 'shared/components/InputField'
import {
   useCartSummaryQuery,
   useNewCartMutation,
   useRemoveProductMutation,
   useUpdateCartInfoMutation,
   useUpdatePricesMutation,
   useUpdateProductMutation,
} from 'shared/store/useCartSummary'
import useLogin from 'shared/store/useLogin'
import {
   RemoveProductPayload,
   UpdateProductType,
   UpdateCartInfoPayload,
   FetchCartSummaryResult,
   Product,
} from 'shared/store/useCartSummary/types'
import { UserRoles } from 'shared/consts'

import { StyledTitleTableCell, StyledTitleRow, StyledTableCell, StyledFooterTableCell } from '../../styles'
import returnMinimumDeliveryTableCell from '../../helpers/returnMinimumDeliveryTableCell'
import { CartDetailsType, ModalInfoType, UpdatePricesDiffType } from '../../types'
import { UserTableHeaders, ClientTableHeaders } from '../../data/TableHeaders'
import AdditionalServicesDesktop from './AdditionalServicesDesktop'
import UnavailableProductTable from './UnavailableProductTable'
import generateReflink from '../../helpers/generateReflink'
import TableForClientDesktop from './TableForClientDesktop'
import MainTableDesktop from './MainTableDesktop'
import schema from '../../schema'
import Modal from '../Modal'

interface Props {
   handleOrderSubmit: () => void
   isOrdering: boolean
}

export default function MainCartDesktop({ handleOrderSubmit, isOrdering }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()
   const [clientView, setClientView] = useState(false)
   const [oldData, setOldData] = useState<FetchCartSummaryResult>()
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()
   const { data: summaryData, isFetched, isFetching } = useCartSummaryQuery()
   const { data: logisticMinimums, isFetched: isLogisticMinimumsFetched } = useLogisticMinimumsQuery(false)
   const { mutate: createNewCartMutate, isLoading: createCartIsLoading } = useNewCartMutation()
   const { mutate: updatePricesMutate, isLoading: updatePriceLoading } = useUpdatePricesMutation()
   const { mutate: updateCartInfoMutate, isLoading: updateCartIsLoading } = useUpdateCartInfoMutation()
   const { mutate: removeProductFromCartMutate } = useRemoveProductMutation()
   const { mutate, isLoading: isProductUpdating } = useUpdateProductMutation()
   const { user } = useLogin()
   const { data: profileData } = useProfileQuery()

   function updateProduct(uuid: string, quantity?: number, includeProductInOrder?: boolean, netClientPrice?: number) {
      if (summaryData) {
         const mutatePayload: UpdateProductType = {
            payload: {
               quantity,
               netClientPrice,
               includeProductInOrder,
            },
            cartUuid: summaryData.cartDetailsDTO.cartUuid,
            cartProductUuid: uuid,
         }
         mutate(mutatePayload, {
            onSuccess: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         })
      }
   }

   const formik = useFormik<CartDetailsType>({
      initialValues: {
         cartDescription: '',
         cartName: '',
         cartUuid: '',
      },
      validationSchema: schema,
      onSubmit: () => {},
   })

   const { setValues, values } = formik

   function changeCartName() {
      if (values.cartName === summaryData?.cartDetailsDTO.cartName) {
         addNotification('TRANSLATION:apiErrors.nothingChanged', 'error')
      } else if (values.cartUuid) {
         const payload: UpdateCartInfoPayload = {
            payload: {
               cartName: values.cartName,
            },
            cartUuid: values.cartUuid,
         }
         updateCartInfoMutate(payload)
      }
   }

   function changeCartDescription() {
      if (values.cartDescription === summaryData?.cartDetailsDTO.cartDescription) {
         addNotification('TRANSLATION:apiErrors.nothingChanged', 'error')
      } else if (values.cartUuid) {
         const payload: UpdateCartInfoPayload = {
            payload: {
               cartDescription: values.cartDescription,
            },
            cartUuid: values.cartUuid,
         }
         updateCartInfoMutate(payload)
      }
   }

   function handleRemoveProduct(uuid: string, removeFromUnavailable?: boolean) {
      if (values.cartUuid) {
         const payload: RemoveProductPayload = {
            cartUuid: values.cartUuid,
            uuid,
            removeFromUnavailable,
         }
         removeProductFromCartMutate(payload, {
            onSettled: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         })
      }
   }

   function handleUpdateCart(cartUuid: string) {
      setOldData(summaryData)
      updatePricesMutate(cartUuid, {
         onSuccess: (data) => checkDiffs(data.products),
      })
   }

   function checkDiffs(data: Product[]) {
      const updatePriceDiffs: UpdatePricesDiffType = []
      if (oldData && data) {
         oldData.products
            .filter((item) => item.status === 'AVAILABLE')
            .forEach((item) => {
               const newProduct: Product | undefined = data
                  .filter((newItem) => newItem.status === 'AVAILABLE')
                  .find((newItem) => item.uuid === newItem.uuid)
               if (newProduct) {
                  if (newProduct.cartWholesaleDTO.netPrice !== item.cartWholesaleDTO.netPrice) {
                     updatePriceDiffs.push({
                        wholesaleName: newProduct.cartWholesaleName,
                        productIndex: newProduct.index,
                        reason: 'PRICE',
                        priceBefore:
                           profileData?.priceType === 'NET'
                              ? item.cartWholesaleDTO.formattedNetPrice
                              : item.cartWholesaleDTO.formattedGrossPrice,
                        priceAfter:
                           profileData?.priceType === 'NET'
                              ? newProduct.cartWholesaleDTO.formattedNetPrice
                              : newProduct.cartWholesaleDTO.formattedGrossPrice,
                     })
                  } else if (newProduct.cartWholesaleDTO.quantity !== item.cartWholesaleDTO.quantity) {
                     updatePriceDiffs.push({
                        wholesaleName: newProduct.cartWholesaleName,
                        productIndex: newProduct.index,
                        reason: 'AVAILABILITY',
                     })
                  }
               }
            })
      }
      if (updatePriceDiffs.length > 0) {
         setModalInfo({
            modalType: 'AFTER_UPDATE',
            updatePricesDiffs: updatePriceDiffs,
         })
      }
   }

   useEffect(() => {
      if (summaryData) {
         setOldData(summaryData)
         setValues({
            ...summaryData.cartDetailsDTO,
         })
      }
   }, [summaryData, isFetching])

   useEffect(() => {
      setModalInfo(undefined)
   }, [profileData?.locationUuid])

   return (
      <Box>
         <FormikProvider value={formik}>
            <Form>
               {isFetched && summaryData ? (
                  <>
                     <Typography variant="body1" sx={{ mb: 1, opacity: 0.5 }}>
                        {t('MAIN_CART:availableProducts')}
                     </Typography>
                     <RadiusTableWrapper sx={{ width: '100%' }}>
                        <Table
                           sx={{
                              backgroundColor: 'rgba(255,255,255,0.7)',
                           }}
                        >
                           <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                              <TableRow>
                                 <TableCell colSpan={clientView ? 10 : 11} sx={{ margin: 1 }}>
                                    <Box
                                       sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                                    >
                                       <InputField<CartDetailsType>
                                          name="cartName"
                                          onBlur={changeCartName}
                                          disabled={updateCartIsLoading}
                                          fullWidth={false}
                                          inputProps={{
                                             maxLength: 50,
                                          }}
                                          variant="standard"
                                          onFocus={(e) => e.target.select()}
                                          label={t('MAIN_CART:cartName')}
                                       />
                                       <Box sx={{ display: 'flex', gap: 1 }}>
                                          {clientView ? (
                                             <ContainedButton
                                                type="button"
                                                variant="contained"
                                                onClick={() => setClientView((prevState) => !prevState)}
                                             >
                                                {t('MAIN_CART:clientView')}
                                             </ContainedButton>
                                          ) : (
                                             <OutlinedButton
                                                type="button"
                                                variant="outlined"
                                                onClick={() => setClientView((prevState) => !prevState)}
                                             >
                                                {t('MAIN_CART:clientView')}
                                             </OutlinedButton>
                                          )}
                                          <ContainedButton
                                             type="button"
                                             disabled={createCartIsLoading}
                                             onClick={createNewCartMutate}
                                             variant="contained"
                                             color="primary"
                                          >
                                             {t('MAIN_CART:createCart')}
                                          </ContainedButton>
                                       </Box>
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <StyledTitleRow>
                                 {clientView
                                    ? ClientTableHeaders.map((item) => (
                                         <StyledTitleTableCell key={item}>
                                            {t(`TRANSLATION:columnNames.${item}`)}
                                         </StyledTitleTableCell>
                                      ))
                                    : UserTableHeaders.map((item) => (
                                         <StyledTitleTableCell key={item}>
                                            {t(`TRANSLATION:columnNames.${item}`)}
                                         </StyledTitleTableCell>
                                      ))}
                              </StyledTitleRow>
                           </TableHead>
                           <TableBody>
                              {clientView ? (
                                 <TableForClientDesktop
                                    products={summaryData.products.filter(
                                       (product) => product.status !== 'NOT_AVAILABLE'
                                    )}
                                    handleProductUpdate={updateProduct}
                                    setModalInfo={setModalInfo}
                                    isProductUpdating={isProductUpdating}
                                 />
                              ) : (
                                 <MainTableDesktop
                                    setModalInfo={setModalInfo}
                                    products={summaryData.products.filter(
                                       (product) => product.status !== 'NOT_AVAILABLE'
                                    )}
                                    handleProductUpdate={updateProduct}
                                    isProductUpdating={isProductUpdating}
                                 />
                              )}
                              <StyledTitleRow>
                                 <StyledFooterTableCell colSpan={clientView ? 6 : 7} />
                                 <StyledFooterTableCell
                                    sx={{
                                       display: 'flex',
                                       gap: 1,
                                    }}
                                 >
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.netSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {clientView
                                          ? summaryData?.formattedClientNetValue
                                          : summaryData?.formattedNetValue}
                                    </p>
                                 </StyledFooterTableCell>
                                 <StyledFooterTableCell />
                                 <StyledFooterTableCell
                                    sx={{
                                       display: 'flex',
                                       gap: 1,
                                    }}
                                 >
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.grossSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {clientView
                                          ? summaryData?.formattedClientGrossValue
                                          : summaryData?.formattedGrossValue}
                                    </p>
                                 </StyledFooterTableCell>
                              </StyledTitleRow>
                              <AdditionalServicesDesktop
                                 additionalServices={summaryData.additionalServices}
                                 cartUuid={summaryData.cartDetailsDTO.cartUuid}
                                 setModalInfo={setModalInfo}
                                 clientView={clientView}
                              />
                           </TableBody>
                           {summaryData.additionalServices && summaryData.additionalServices.length > 0 && (
                              <StyledTitleRow>
                                 <StyledFooterTableCell colSpan={clientView ? 6 : 7} />
                                 <StyledFooterTableCell
                                    sx={{
                                       display: 'flex',
                                       gap: 1,
                                    }}
                                 >
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.netSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {summaryData.formattedAdditionalServicesNetValue}
                                    </p>
                                 </StyledFooterTableCell>
                                 <StyledFooterTableCell />
                                 <StyledFooterTableCell
                                    sx={{
                                       display: 'flex',
                                       gap: 1,
                                    }}
                                 >
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.grossSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {summaryData.formattedAdditionalServicesGrossValue}
                                    </p>
                                 </StyledFooterTableCell>
                              </StyledTitleRow>
                           )}
                        </Table>
                     </RadiusTableWrapper>
                     {!clientView &&
                        summaryData.products.filter((item) => item.status === 'NOT_AVAILABLE').length > 0 && (
                           <Box>
                              <UnavailableProductTable
                                 products={summaryData.products.filter((item) => item.status === 'NOT_AVAILABLE')}
                                 setModalInfo={setModalInfo}
                              />
                           </Box>
                        )}
                     {clientView && (
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              gap: 5,
                              margin: '0 auto',
                              paddingTop: 2,
                           }}
                        >
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <Typography
                                 color="grayText"
                                 variant="h6"
                              >{`${t('TRANSLATION:columnNames.netSum')}:`}</Typography>
                              <Typography variant="h6">
                                 {summaryData.formattedClientNetValueWithAdditionalServices}
                              </Typography>
                           </Box>
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <Typography
                                 variant="h6"
                                 color="grayText"
                              >{`${t('TRANSLATION:columnNames.grossSum')}:`}</Typography>
                              <Typography variant="h6">
                                 {summaryData.formattedClientGrossValueWithAdditionalServices}
                              </Typography>
                           </Box>
                        </Box>
                     )}
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'flex-end',
                           gap: 2,
                           margin: '0 auto',
                           paddingTop: 2,
                        }}
                     >
                        <OutlinedButton
                           sx={{ alignSelf: 'flex-start' }}
                           onClick={() =>
                              setModalInfo({
                                 modalType: 'SERVICES',
                                 uuid: summaryData.cartDetailsDTO.cartUuid,
                              })
                           }
                           variant="outlined"
                        >
                           {t('MAIN_CART:services.addService')}
                        </OutlinedButton>
                        <OutlinedButton
                           disabled={summaryData?.products.length === 0}
                           onClick={() =>
                              generateReflink({
                                 summaryData,
                                 setModalInfo,
                                 onFailed: () => addNotification(t('MAIN_CART:generateFailed'), 'error'),
                              })
                           }
                           variant="outlined"
                        >
                           <LinkIcon />
                        </OutlinedButton>
                        <OutlinedButton
                           disabled={summaryData?.products.length === 0}
                           onClick={() =>
                              setModalInfo({
                                 modalType: 'PRINT',
                                 pricingVariant: clientView ? 'CLIENT' : 'FULL',
                              })
                           }
                           variant="outlined"
                        >
                           <PrintIcon color={summaryData?.products.length === 0 ? 'disabled' : 'primary'} />
                        </OutlinedButton>
                        {!user.roles.includes(UserRoles.DEMO) ? (
                           <OutlinedButton
                              disabled={updatePriceLoading || summaryData?.products.length === 0}
                              onClick={() => {
                                 if (summaryData) {
                                    handleUpdateCart(summaryData.cartDetailsDTO.cartUuid)
                                 }
                              }}
                              variant="outlined"
                           >
                              {t('MAIN_CART:updatePrices')}
                           </OutlinedButton>
                        ) : (
                           <OutlinedButton variant="outlined">{t('MAIN_CART:updatePrices')}</OutlinedButton>
                        )}
                        <ContainedButton
                           onClick={handleOrderSubmit}
                           disabled={
                              isProductUpdating ||
                              summaryData?.products.length === 0 ||
                              !summaryData?.products.find((item) => item.includedProductInOrder) ||
                              isOrdering ||
                              user?.roles.includes(UserRoles.SALES_REP)
                           }
                           variant="contained"
                        >
                           {t('MAIN_CART:order')}
                        </ContainedButton>
                     </Box>
                     {!clientView && (
                        <Box
                           sx={{
                              display: 'flex',
                              gap: 3,
                              margin: '0 auto',
                              marginTop: 6,
                              minHeight: 200,
                           }}
                        >
                           <RadiusTableWrapper sx={{ width: '70%' }}>
                              <Table
                                 sx={{
                                    backgroundColor: 'rgba(255,255,255,0.7)',
                                 }}
                              >
                                 <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.5)' }}>
                                    <StyledTitleRow>
                                       <StyledTitleTableCell>
                                          {t('TRANSLATION:columnNames.wholesaler')}
                                       </StyledTitleTableCell>
                                       <StyledTitleTableCell>
                                          {t('MAIN_CART:remainLogisticMinimumsNet')}
                                       </StyledTitleTableCell>
                                       <StyledTitleTableCell>
                                          {t('MAIN_CART:timeUntilDeliveryStart')}
                                       </StyledTitleTableCell>
                                    </StyledTitleRow>
                                 </TableHead>
                                 <TableBody>
                                    {isLogisticMinimumsFetched && logisticMinimums ? (
                                       logisticMinimums.logisticMinimums.map((minimum) => (
                                          <TableRow key={minimum.wholesaleUuid}>
                                             <StyledTableCell>
                                                <HiddenWholesalerLogo
                                                   wholesalerName={minimum.wholesaleName}
                                                   imgHeight="20px"
                                                   imgWidth="auto"
                                                />
                                             </StyledTableCell>
                                             <StyledTableCell>{minimum.formattedRemainingAmount}</StyledTableCell>
                                             {returnMinimumDeliveryTableCell(minimum.minutesToDeliveryStart)}
                                          </TableRow>
                                       ))
                                    ) : (
                                       <TableRow>
                                          <StyledTableCell colSpan={3}>
                                             <CircularProgress color="primary" />
                                          </StyledTableCell>
                                       </TableRow>
                                    )}
                                 </TableBody>
                              </Table>
                           </RadiusTableWrapper>
                           <InputField<CartDetailsType>
                              name="cartDescription"
                              onBlur={changeCartDescription}
                              disabled={updateCartIsLoading}
                              sx={{ minHeight: 200, width: '30%' }}
                              multiline
                              rows={7}
                              label={t('MAIN_CART:cartDescription')}
                           />
                        </Box>
                     )}
                     <Modal
                        modalInfo={modalInfo}
                        data={summaryData}
                        handleClose={() => setModalInfo(undefined)}
                        handleProductUpdate={updateProduct}
                        handleRemoveProductFromCart={handleRemoveProduct}
                     />
                  </>
               ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                     <CircularProgress />
                  </Box>
               )}
            </Form>
         </FormikProvider>
      </Box>
   )
}
