import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

import background from 'assets/svg/background.svg'

import Footer from 'views/Authorized/components/Footer'
import Header from '../Header'
import Sidebar from '../Sidebar'

function Main() {
   const [sidebar, handleSidebar] = useState(true)

   function handleSidebarChange(open: boolean) {
      handleSidebar(open)
      if (open === true) {
         window.localStorage.setItem('sidebarVariant', 'opened')
      } else window.localStorage.setItem('sidebarVariant', 'closed')
   }

   useEffect(() => {
      const shouldCloseTab = window.localStorage.getItem('sidebarVariant') === 'closed'
      if (shouldCloseTab) {
         handleSidebar(false)
      }
   }, [])

   return (
      <>
         <Header />
         <Box component="main" display="flex" minHeight="calc(100vh - 139px)" mt={8}>
            <Sidebar open={sidebar} handleOpen={handleSidebarChange} />
            <Box
               sx={{
                  px: { xs: 2, md: 5 },
                  py: 5,
                  flexGrow: 1,
                  backgroundImage: `url(${background})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
               }}
            >
               <Outlet />
            </Box>
         </Box>
         <Footer open={sidebar} />
      </>
   )
}

export default Main
