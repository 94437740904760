import React, { useState } from 'react'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import ReactMarkdown from 'react-markdown'
import Fade from '@mui/material/Fade'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import GrayButton from 'shared/components/Buttons/GrayButton'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import PhotoBrowser from 'shared/components/PhotoBrowser'
import Hidden from 'shared/components/Hidden'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { ProductDetails, TabPanelProps } from '../../types'
import {
   BlackTypography,
   Container,
   GrayTypography,
   InformationsContainer,
   ProductDetailsContainer,
   StyledImage,
   TabsContainer,
} from '../../styles'
import EditDescriptionModal from '../EditDescriptionModal'
import { addToWatchlistService } from '../../services'

interface Props {
   data?: ProductDetails
}

export default function ProductInfo({ data }: Props) {
   const [openPhoto, setOpenPhoto] = useState(false)
   const { user } = useLogin()
   const queryClient = useQueryClient()
   const [openModal, setOpenModal] = useState(false)
   const [activeTab, setActiveTab] = useState(0)
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const { addNotification } = useNotificationContext()

   async function handleAddToWatchList() {
      if (data) {
         if (data.uuid) {
            await addToWatchlistService(data.uuid)
               .then(({ status }) => {
                  if (status === 204) {
                     queryClient.invalidateQueries('watchlist')
                     addNotification(t('PRODUCT_DETAILS:successAdd'), 'success')
                     queryClient.invalidateQueries('watchlist')
                  }
               })
               .catch((error) => {
                  if (isAxiosError<ErrorType>(error)) {
                     addNotification(error.response?.data.errors[0].code || 'failedSave', 'error')
                  } else {
                     addNotification('failedSave', 'error')
                  }
               })
         }
      }
   }

   function returnDescription(name: string, desc: string) {
      switch (true) {
         case !name && !desc:
            return t('PRODUCT_DETAILS:productInfo.noDesc')
         case name && !desc:
            return name
         case desc.includes(name):
         case desc && !desc.includes(name):
            return (
               <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                  {desc}
               </ReactMarkdown>
            )
         default:
            return t('PRODUCT_DETAILS:productInfo.noDesc')
      }
   }
   function returnName() {
      if (data) {
         if (data.name) {
            return data.name.split(' ').slice(0, 2).join(' ')
         }
         return data.index.length > 50 ? data.index.slice(0, 50) : data.index
      }
      return null
   }

   return (
      <Container>
         {data && (
            <Box>
               <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h5" sx={{ mb: 1.5, textTransform: 'uppercase' }}>
                     {returnName()}
                  </Typography>
                  <GrayButton sx={{ mb: 1.5 }} onClick={handleAddToWatchList} variant="outlined">
                     {t('PRODUCT_DETAILS:tables.addToWatchList')}
                     <CircleNotificationsIcon sx={{ marginLeft: 2 }} />
                  </GrayButton>
               </Box>
               <ProductDetailsContainer>
                  <Box sx={{ display: 'flex' }}>
                     <StyledImage
                        onClick={() => setOpenPhoto(data.imagesLinks.length > 0)}
                        alt="partPhoto"
                        height="180px"
                        width="180px"
                        src={
                           data.imagesLinks.length > 0
                              ? `${process.env.REACT_APP_FILES_URL}/assets/producers/${data.imagesLinks[0]}`
                              : `${process.env.REACT_APP_FILES_URL}/img/no-product-image.jpg`
                        }
                     />

                     <InformationsContainer>
                        <span>
                           <GrayTypography>
                              {`${t('TRANSLATION:columnNames.partIndex')}: `}
                              <BlackTypography>
                                 {data.index.length > 50 ? (
                                    <Tooltip title={data.index}>
                                       <span>{data.index.slice(0, 50)}</span>
                                    </Tooltip>
                                 ) : (
                                    data.index
                                 )}
                              </BlackTypography>
                           </GrayTypography>
                        </span>
                        <span>
                           <GrayTypography>
                              {`${t('TRANSLATION:columnNames.producer')}: `}
                              <BlackTypography>{data.producer}</BlackTypography>
                              {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                 <CopyToClipboard text={data.producer}>
                                    <FileCopyOutlinedIcon
                                       onClick={(e) => {
                                          e.stopPropagation()
                                       }}
                                    />
                                 </CopyToClipboard>
                              )}
                           </GrayTypography>
                        </span>
                        {user.roles.includes(UserRoles.DATA_MANAGER) && (
                           <ContainedButton
                              sx={{ alignSelf: 'flex-start' }}
                              onClick={() => setOpenModal(true)}
                              variant="contained"
                           >
                              Aktualizuj opis
                           </ContainedButton>
                        )}
                        {/* 
                     Will be used in future
                     <span style={{ borderTop: '1px solid #D3D3D3', paddingTop: '10px' }}>
                     <GrayTypography>
                     Average rating:
                     <Tooltip
                     componentsProps={{
                        tooltip: {
                           sx: {
                              backgroundColor: '#F5F5F5',
                              },
                              },
                              }}
                              placement="bottom"
                              title={
                                 <DetailedRatingContainer>
                                 <GrayTooltipTypography>
                                 Quality Rate:
                                 <StyledRating
                                 icon={<FullStar />}
                                 emptyIcon={<EmptyStar />}
                                 readOnly
                                 value={data.rating.productionQualityRate}
                                 precision={0.5}
                                 />
                                 </GrayTooltipTypography>
                                 <GrayTooltipTypography>
                                       Conform Rate:
                                       <StyledRating
                                          icon={<FullStar />}
                                          emptyIcon={<EmptyStar />}
                                          readOnly
                                          value={data.rating.conformRate}
                                          precision={0.5}
                                          />
                                          </GrayTooltipTypography>
                                          <GrayTooltipTypography>
                                          Price/Quality Rate:
                                          <StyledRating
                                          icon={<FullStar />}
                                          emptyIcon={<EmptyStar />}
                                          readOnly
                                          value={data.rating.priceToQualityRate}
                                          precision={0.5}
                                          />
                                          </GrayTooltipTypography>
                                          </DetailedRatingContainer>
                                          }
                                          >
                                          <span>
                                          <StyledRating
                                          icon={<FullStar />}
                                          emptyIcon={<EmptyStar />}
                                          readOnly
                                          value={data.rating.averageRate}
                                          precision={0.5}
                                          />
                                          </span>
                                          </Tooltip>
                                          ({Math.ceil(data.rating.averageRate)})
                                          </GrayTypography>
                                          </span> */}
                     </InformationsContainer>
                  </Box>
                  <Hidden width="smDown">
                     <TabsContainer>
                        <Box>
                           <Tabs
                              TabIndicatorProps={{
                                 style: { height: 4, borderRadius: 5 },
                              }}
                              sx={{
                                 borderBottom: '2px solid #D3D3D3',
                                 '& button': { color: 'grayText', fontWeight: 'normal' },
                                 '& button.Mui-selected': { color: 'black', fontWeight: 'bold' },
                              }}
                              variant="fullWidth"
                              value={activeTab}
                              onChange={(event, value) => setActiveTab(value)}
                           >
                              <Tab label={t('PRODUCT_DETAILS:productInfo.productDescription')} />
                              <Tab label={t('PRODUCT_DETAILS:productInfo.dimensions')} />
                              <Tab label={t('PRODUCT_DETAILS:productInfo.application')} />
                              <Tab label={t('PRODUCT_DETAILS:productInfo.instructions')} />
                           </Tabs>
                           <TabPanel value={activeTab} index={0}>
                              <Box
                                 sx={{
                                    maxHeight: 200,
                                    overflowY: 'scroll',
                                    '& img': { maxHeight: 200, width: 'auto' },
                                 }}
                              >
                                 {returnDescription(data.name, data.description)}
                              </Box>
                           </TabPanel>
                           <TabPanel value={activeTab} index={1}>
                              {/* in future there would be dimensions, when Sebastian add backend */}
                              <span>{t('PRODUCT_DETAILS:productInfo.noDimensions')}</span>
                           </TabPanel>
                           <TabPanel value={activeTab} index={2}>
                              {/* in future there would be applications, when Sebastian add backend */}
                              <span>{t('PRODUCT_DETAILS:productInfo.noApplication')}</span>
                           </TabPanel>
                           <TabPanel value={activeTab} index={3}>
                              {/* in future there would be instructions, when Sebastian add backend */}
                              <span>{t('PRODUCT_DETAILS:productInfo.noInstructions')}</span>
                           </TabPanel>
                        </Box>
                     </TabsContainer>
                  </Hidden>
               </ProductDetailsContainer>
               <PhotoBrowser open={openPhoto} handleClose={() => setOpenPhoto(false)} imagesLinks={data.imagesLinks} />
            </Box>
         )}
         {data && user.roles.includes(UserRoles.DATA_MANAGER) && (
            <EditDescriptionModal
               open={openModal}
               productUuid={data?.uuid || ''}
               handleClose={() => setOpenModal(false)}
            />
         )}
      </Container>
   )
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index } = props
   return (
      <Box sx={{ marginY: 3, fontSize: 13 }}>
         {value === index && (
            <Fade in>
               <div>{children}</div>
            </Fade>
         )}
      </Box>
   )
}
