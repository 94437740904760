import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'

interface Props {
   open: boolean
}

export default function AppVersionModal({ open }: Props) {
   const { t } = useTranslation('AUTHORIZED')

   return (
      <Dialog open={open} sx={{ alignSelf: 'flex-start', textAlign: 'center' }} maxWidth="xl">
         <DialogTitle color="error">{t('appVersionDialog.title')}</DialogTitle>
         <DialogContent>
            <Typography variant="body1" fontWeight="bold">
               {t('appVersionDialog.text')}
            </Typography>
         </DialogContent>
      </Dialog>
   )
}
