import React from 'react'
import TableCell from '@mui/material/TableCell'

import { GreenHour, RedHour, StyledTableCell, YellowHour } from '../styles'
import getFormattedDeliveryTime from './getFormattedDeliveryTime'

export default function returnMinimumDeliveryTableCell(minutesToDeliveryStart: number) {
   switch (true) {
      case minutesToDeliveryStart === -1:
         return <StyledTableCell />
      case minutesToDeliveryStart < 5:
         return (
            <TableCell>
               <RedHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</RedHour>
            </TableCell>
         )
      case minutesToDeliveryStart > 60:
         return (
            <TableCell>
               <GreenHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</GreenHour>
            </TableCell>
         )
      default:
         return (
            <TableCell>
               <YellowHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</YellowHour>
            </TableCell>
         )
   }
}
