import { Button } from '@mui/material'
import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useTranslation } from 'react-i18next'

import { useProfileQuery } from 'shared/store/useProfile'

interface Props {
   updateDirection: (columnIndex: number) => void
   value: string
   index: number
   sortName: string
   sortDirection: string
   shouldShowPriceType?: boolean
}

export default function HeadLabel({
   updateDirection,
   value,
   sortDirection,
   index,
   sortName,
   shouldShowPriceType,
}: Props) {
   const { t } = useTranslation('TRANSLATION')
   const { data } = useProfileQuery()
   const isFocused = sortName === value && sortDirection !== 'none'

   const priceType = data?.priceType

   return (
      <th
         style={{
            backgroundColor: 'rgba(33,147,224, 0.1)',
            margin: 0,
            padding: 0,
            paddingTop: 15,
            paddingBottom: 15,
            maxWidth: '140px',
         }}
      >
         <Button
            onClick={() => updateDirection(index)}
            style={{
               color: isFocused ? 'black' : 'gray',
               cursor: 'pointer',
               fontSize: 13,
               textAlign: 'center',
            }}
         >
            {`${t(`columnNames.${value}`)} ${priceType && shouldShowPriceType ? t(priceType) : ''}`}
            {isFocused && (
               <ArrowDownwardIcon
                  sx={{
                     fontSize: 13,
                     ml: 0.5,
                     transition: '300ms',
                     transform: sortDirection === 'asc' ? 'rotate(180deg)' : 'none',
                  }}
               />
            )}
         </Button>
      </th>
   )
}
