import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import { Form, FormikProvider, useFormik } from 'formik'
import { Base64 } from 'js-base64'

import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { httpClient, Yup } from 'shared/lib'
import InputField from 'shared/components/InputField'
import useLogin from 'shared/store/useLogin'
import { useNotificationContext } from 'shared/context/Notifications'

interface Props {
   open: boolean
   handleClose: () => void
}

const validPassword = /^(?=.*[0-9])(?=.*[A-Z-äÄöÖüÜßčČřŠšŽžě])[a-zA-Z0-9äÄöÖüÜßčČřŠšŽžě!@#$%^&*()]{6,32}$/

const resetPasswordSchema = Yup.object().shape({
   password: Yup.string().matches(validPassword, 'validation.passwordPattern').required(),
   repassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'validation.confirmPassword'),
})

type ResetPasswordType = {
   password: string
   repassword: string
}

export default function ChangePasswordModal({ open, handleClose }: Props) {
   const { t } = useTranslation('TRANSLATION')
   const { user } = useLogin()
   const { addNotification } = useNotificationContext()

   const formik = useFormik<ResetPasswordType>({
      initialValues: {
         password: '',
         repassword: '',
      },
      validationSchema: resetPasswordSchema,
      onSubmit: handleSubmit,
   })

   const { isValid, values } = formik

   function handleSubmit() {
      httpClient
         .put(`companies/users/${user.uuid}/change-password`, {
            password: Base64.encode(values.password),
            repassword: Base64.encode(values.repassword),
         })
         .then((res) => {
            if (res.status === 200) {
               addNotification(t('TRANSLATION:successSave'), 'success')
               handleClose()
            }
         })
         .catch(() => addNotification(t('TRANSLATION:failedSave'), 'error'))
   }

   return (
      <Dialog open={open} sx={{ alignSelf: 'center', textAlign: 'center' }} maxWidth="xl">
         <DialogTitle sx={{ textAlign: 'start' }} color="primary">
            {t('TRANSLATION:changePassword')}
         </DialogTitle>
         <FormikProvider value={formik}>
            <Form>
               <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 300 }}>
                  <InputField name="password" type="password" label={t('TRANSLATION:formFields.password')} />
                  <InputField name="repassword" type="password" label={t('TRANSLATION:formFields.confirmPassword')} />
               </DialogContent>
               <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <OutlinedButton type="button" variant="outlined" onClick={handleClose}>
                     {t('TRANSLATION:cancel')}
                  </OutlinedButton>
                  <ContainedButton type="submit" disabled={!isValid} variant="contained">
                     {t('TRANSLATION:save')}
                  </ContainedButton>
               </DialogActions>
            </Form>
         </FormikProvider>
      </Dialog>
   )
}
