import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'

export const StyledDeleteIcon = styled(DeleteIcon)({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: 'red',
   },
})

export const StyledEditIcon = styled(EditIcon)(({ theme }) => ({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
   },
}))

export const StyledLockIcon = styled(LockIcon)(({ theme }) => ({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
   },
}))
