import { styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

export const StyledTable = styled(Table)({
   minWidth: 500,
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   color: 'grayText',
})

export const StyledTableCell = styled(TableCell)({
   textAlign: 'center',
})

export const StyledTableHeadCell = styled(TableCell)({
   textAlign: 'center',
   color: 'grayText',
})

export const StyledBoldTableCell = styled(TableCell)({
   textAlign: 'center',
   fontWeight: 'bold',
})

export const ClickableTableRow = styled(TableRow)({
   '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f0f4fc',
   },
})

export const StyledLink = styled('a')(({ theme }) => ({
   color: 'black',
   textDecoration: 'none',
   '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
   },
}))

export const TableContainer = styled(Box)({
   display: 'flex',
   flexDirection: 'column',
   gap: 7,
})

export const AvailabilityDot = styled(FiberManualRecordIcon)<InputProps>(({ dotcolor }) => ({
   marginLeft: 15,
   color: dotcolor,
}))

export const ProductDetailsContainer = styled(Box)({
   display: 'flex',
   justifyContent: 'space-between',
   paddingTop: 15,
   paddingBottom: 40,
   borderTop: '2px solid #A9A9A9',
})

export const StyledImage = styled('img')(({ theme }) => ({
   '&:hover': {
      boxShadow: `0px 0px 20px -3px ${theme.palette.primary.dark}`,
      cursor: 'pointer',
   },
}))

export const TabsContainer = styled(Box)({
   flex: 1,
   marginTop: -16,
   padding: '20px 40px 20px 40px',
   minHeight: 196,
   maxWidth: '70%',
   backgroundColor: 'rgba(245, 245, 245, 0.8)',
})

export const InformationsContainer = styled(Box)({
   display: 'flex',
   flexDirection: 'column',
   gap: 10,
   minWidth: 250,
   paddingTop: 7,
   fontSize: 14,
})

export const GrayTypography = styled(Typography)({
   display: 'flex',
   gap: 4,
   color: '#A9A9A9',
   fontSize: 'inherit',
})

export const BlackTypography = styled(Typography)({
   color: 'black',
   textTransform: 'uppercase',
   fontSize: 'inherit',
})

type InputProps = {
   dotcolor: string
}
