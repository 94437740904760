import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FormikProvider, useFormik } from 'formik'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'

import { useQueryClient } from 'react-query'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { SmsApiType } from 'shared/store/useWholesalers/types'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import { Yup } from 'shared/lib'

import { createSmsApiConfigService, deleteSmsApiConfigService, updateSmsApiConfigService } from '../../services'
import { SmsApiFormikType } from '../../types'

interface Props {
   open: boolean
   data: SmsApiType
   type: 'new' | 'edit' | 'delete'
   handleClose: () => void
}

export default function Modal({ open, type, data, handleClose }: Props) {
   const theme = useTheme()
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()
   const [loading, setLoading] = useState(true)
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])

   const formik = useFormik<SmsApiFormikType>({
      initialValues: {
         token: '',
         categoriesTemplates: {},
      },
      validationSchema: Yup.object().shape({
         token: Yup.string().required(),
      }),
      onSubmit: () => {},
   })

   const { setValues, values } = formik

   function handleIntegrationCreate() {
      if (values.token) {
         createSmsApiConfigService({
            token: values.token,
            categoriesTemplates: {
               CLIENT_CART_REFLINK_NOTIFICATION: '',
               ORDER_REFLINK_NOTIFICATION: '',
            },
         }).then((res) => {
            if (res.status === 200) {
               queryClient.invalidateQueries('wholesalers')
               handleClose()
            }
         })
      }
   }

   function handleIntegrationEdit() {
      if (data.uuid) {
         updateSmsApiConfigService(data.uuid, values.token, values.categoriesTemplates)
            .then((res) => {
               if (res.status === 200) {
                  addNotification(t('WHOLESALERS:externalTool.editSuccess'), 'success')
                  queryClient.invalidateQueries('wholesalers')
                  handleClose()
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data.errors[0].code || 'WHOLESALERS:externalTool.editFailed', 'error')
               } else {
                  addNotification('WHOLESALERS:externalTool.editFailed', 'error')
               }
            })
      }
   }

   function handleIntegrationDelete() {
      if (data.uuid) {
         deleteSmsApiConfigService(data.uuid)
            .then((res) => {
               if (res.status === 204) {
                  addNotification(t('WHOLESALERS:externalTool.deleteSuccess'), 'success')
                  queryClient.invalidateQueries('wholesalers')
                  handleClose()
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(
                     error.response?.data.errors[0].code || 'WHOLESALERS:externalTool.deleteFailed',
                     'error'
                  )
               } else {
                  addNotification('WHOLESALERS:externalTool.deleteFailed', 'error')
               }
            })
      }
   }

   function renderContent() {
      switch (type) {
         case 'new':
            return (
               <Box
                  sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'space-beetwen', alignItems: 'center' }}
               >
                  <FormikProvider value={formik}>
                     <Typography sx={{ mb: 1 }} variant="body1">
                        {t('WHOLESALERS:smsApi.addSmsApiText')}
                     </Typography>
                     <InputField
                        name="token"
                        variant="standard"
                        type="password"
                        fullWidth
                        label={t('WHOLESALERS:externalTool.tokenLabel')}
                     />
                     <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                        <OutlinedButton onClick={handleClose} variant="outlined">
                           {t('TRANSLATION:cancel')}
                        </OutlinedButton>
                        <ContainedButton onClick={handleIntegrationCreate} disabled={!values.token} variant="contained">
                           {t('TRANSLATION:save')}
                        </ContainedButton>
                     </Box>
                  </FormikProvider>
               </Box>
            )
         case 'edit':
            return (
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyItems: 'space-beetwen' }}>
                  <Typography variant="h6">{t('WHOLESALERS:baselinker.editStatusesText')}</Typography>
                  {!loading ? (
                     <FormikProvider value={formik}>
                        <InputField
                           name="token"
                           variant="standard"
                           type="password"
                           fullWidth
                           label={t('WHOLESALERS:externalTool.tokenLabel')}
                        />
                        <Typography variant="h6">{t('WHOLESALERS:smsApi.configureTemplatesTitle')}</Typography>
                        <Typography variant="body1" fontWeight="bold">
                           {t('WHOLESALERS:smsApi.orderReflinkTemplate')}
                        </Typography>
                        <InputField
                           multiline
                           rows={5}
                           name="categoriesTemplates[ORDER_REFLINK_NOTIFICATION]"
                           label=""
                        />
                        <Typography variant="body1" fontWeight="bold">
                           {t('WHOLESALERS:smsApi.clientCartReflinkTemplate')}
                        </Typography>
                        <InputField
                           multiline
                           rows={5}
                           name="categoriesTemplates[CLIENT_CART_REFLINK_NOTIFICATION]"
                           label=""
                        />
                        <Typography variant="body1" fontWeight="bold">
                           {t('WHOLESALERS:smsApi.configuratingTemplatesRules')}
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line', lineHeight: 2 }}>
                           {t('WHOLESALERS:smsApi.configureTemplates')}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 2 }}>
                           <OutlinedButton onClick={handleClose} variant="outlined">
                              {t('TRANSLATION:cancel')}
                           </OutlinedButton>
                           <ContainedButton
                              onClick={handleIntegrationEdit}
                              disabled={!values.token}
                              variant="contained"
                           >
                              {t('TRANSLATION:save')}
                           </ContainedButton>
                        </Box>
                     </FormikProvider>
                  ) : (
                     <CircularProgress sx={{ margin: '0 auto' }} />
                  )}
               </Box>
            )
         case 'delete':
            return (
               <Box
                  sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'space-beetwen', alignItems: 'center' }}
               >
                  <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                     <OutlinedButton onClick={handleClose} variant="outlined">
                        {t('TRANSLATION:no')}
                     </OutlinedButton>
                     <ContainedButton onClick={handleIntegrationDelete} variant="contained">
                        {t('TRANSLATION:yes')}
                     </ContainedButton>
                  </Box>
               </Box>
            )
         default:
            return null
      }
   }

   function renderTitle() {
      switch (type) {
         case 'new':
            return t('WHOLESALERS:smsApi.newTitle')
         case 'delete':
            return t('WHOLESALERS:smsApi.deleteTitle')
         case 'edit':
            return t('WHOLESALERS:smsApi.editTitle')
         default:
            return null
      }
   }

   useEffect(() => {
      setLoading(true)
      if (type === 'edit' && data) {
         setValues({
            token: data.token,
            categoriesTemplates: data.categoriesTemplates,
         })
         setLoading(false)
      }
   }, [type, data.uuid])

   return (
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>{renderTitle()}</Typography>
         </DialogTitle>
         <DialogContent>{renderContent()}</DialogContent>
      </Dialog>
   )
}
