import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import NotFound from 'shared/components/404'
import NotificationProvider from 'shared/context/Notifications'
import CustomSnackbar from 'shared/components/CustomSnackbar'

import ResetPassword from './views/ResetPassword'
import OrderReflink from './views/OrderReflink'
import RegisterPage from './views/Register'
import Homepage from './views/Homepage'
import { en, pl, de } from './locales'
import OrderReflinkComplete from './views/OrderReflinkComplete'
import UnauthorizedLayout from './components/Layout'
import CooperationReflink from './views/CooperationReflink'
import CooperationFormCompleted from './views/CooperationFormCompleted'
import SessionEnd from './views/SessionEnd'
import DemoRegister from './views/DemoRegister'
import DemoRegisterCompleted from './views/DemoRegisterCompleted'
import ClientCartReflink from './views/ClientCartReflink'

export default function Unauthorized() {
   const { i18n } = useTranslation(['UNAUTHORIZED', 'GLOBALS'])

   i18n.addResourceBundle('pl-PL', 'UNAUTHORIZED', pl)
   i18n.addResourceBundle('en-EN', 'UNAUTHORIZED', en)
   i18n.addResourceBundle('de-DE', 'UNAUTHORIZED', de)

   return (
      <NotificationProvider>
         <Routes>
            <Route path="/" element={<UnauthorizedLayout />}>
               <Route index element={<Homepage />} />
               <Route path="reset-password" element={<ResetPassword />} />
               <Route path="register" element={<RegisterPage />} />
               <Route path="demo-application" element={<DemoRegister />} />
               <Route path="session-end" element={<SessionEnd />} />
               <Route path="demo-register-completed" element={<DemoRegisterCompleted />} />
               <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="client-cart-reflink" element={<ClientCartReflink />} />
            <Route path="cooperation-form" element={<CooperationReflink />} />
            <Route path="cooperation-form/*" element={<CooperationReflink />} />
            <Route path="cooperation-form-completed" element={<CooperationFormCompleted />} />
            <Route path="order-reflink" element={<OrderReflink />} />
            <Route path="order-reflink-complete" element={<OrderReflinkComplete />} />
         </Routes>
         <CustomSnackbar />
      </NotificationProvider>
   )
}
