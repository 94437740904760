import React from 'react'

import { StatusType } from '../types'
import { AvailabilityDot } from '../styles'

export function GetAvailabilityDot(availability: StatusType) {
   switch (availability) {
      case 'UNAVAILABLE':
         return <AvailabilityDot dotcolor="red" />
      case 'NO_DATA':
         return <AvailabilityDot dotcolor="gray" />
      case 'AVAILABLE':
         return <AvailabilityDot dotcolor="green" />
      case 'LAST_PIECE':
         return <AvailabilityDot dotcolor="orange" />
      default:
         return <AvailabilityDot dotcolor="gray" />
   }
}
