import 'react-international-phone/style.css'
import { InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CountryIso2, defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone'
import { useTranslation } from 'react-i18next'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'

interface Props {
   value: string
   disabled?: boolean
   label?: string
   variant: 'outlined' | 'standard' | 'filled'
   onChange: (phone: string, country: string) => void
   handleOnKeyDown?: () => void
}

export default function MuiPhone({ value, onChange, label, variant, disabled, handleOnKeyDown, ...restProps }: Props) {
   const { t } = useTranslation()
   const [isInvalid, setIsInvalid] = useState(false)
   const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
      defaultCountry: 'pl',
      value,
      countries: defaultCountries,
      onChange: (data) => {
         onChange(data.inputValue, data.country.iso2)
      },
   })

   const countriesWhitelist = [
      'al',
      'ad',
      'at',
      'by',
      'be',
      'ba',
      'bg',
      'hr',
      'me',
      'cz',
      'dk',
      'ee',
      'fi',
      'fr',
      'gr',
      'es',
      'nl',
      'ie',
      'is',
      'xk',
      'li',
      'lt',
      'lu',
      'lv',
      'mk',
      'mt',
      'md',
      'mc',
      'de',
      'no',
      'pl',
      'pt',
      'ru',
      'ro',
      'sm',
      'rs',
      'sk',
      'si',
      'ch',
      'se',
      'tr',
      'ua',
      'hu',
      'gb',
      'it',
      'va',
   ]

   function renderHelperText() {
      if (isInvalid) {
         return t('TRANSLATION:validation.telephoneNumberPattern')
      }
      if (!value.replace(`+${country.dialCode} `, '')) {
         return t('TRANSLATION:validation.required')
      }
      return null
   }

   useEffect(() => {
      const selectedCountry = countriesWhitelist.find((item) => item === country.iso2)
      if (selectedCountry) {
         if (value.replace(`+${country.dialCode} `, '') === '') {
            setIsInvalid(false)
         } else {
            setIsInvalid(!validatePhoneNumber(value, country.iso2))
         }
      }
   }, [value])

   useEffect(() => {
      if (!phone && value) {
         onChange(phone, country.iso2)
      }
   }, [])

   return (
      <TextField
         fullWidth
         variant={variant}
         label={label || t('TRANSLATION:formFields.phoneNumber')}
         required
         onKeyDown={(e) => {
            if (e.key === 'Enter') {
               if (handleOnKeyDown) {
                  handleOnKeyDown()
               }
            }
         }}
         color="primary"
         helperText={renderHelperText()}
         placeholder="Phone number"
         value={phone}
         disabled={disabled}
         error={isInvalid || !value.replace(`+${country.dialCode} `, '')}
         onChange={handlePhoneValueChange}
         inputRef={inputRef}
         InputProps={{
            startAdornment: (
               <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
                  <Select
                     MenuProps={{
                        style: {
                           height: '300px',
                           width: '360px',
                           top: '10px',
                           left: '-34px',
                        },
                        transformOrigin: {
                           vertical: 'top',
                           horizontal: 'left',
                        },
                     }}
                     sx={{
                        width: 'max-content',
                        fieldset: {
                           display: 'none',
                        },
                        '&.Mui-focused:has(div[aria-expanded="false"])': {
                           fieldset: {
                              display: 'block',
                           },
                        },
                        '.MuiSelect-select': {
                           padding: '8px',
                           paddingRight: '24px !important',
                        },
                        svg: {
                           right: 0,
                        },
                     }}
                     value={country.iso2}
                     onChange={(e) => setCountry(e.target.value as CountryIso2)}
                     renderValue={(val) => <FlagImage iso2={val} style={{ display: 'flex' }} />}
                  >
                     {defaultCountries
                        .filter((item) => countriesWhitelist.includes(item[1]))
                        .map((c) => {
                           const parsedCountry = parseCountry(c)
                           return (
                              <MenuItem key={parsedCountry.iso2} value={parsedCountry.iso2}>
                                 <FlagImage iso2={parsedCountry.iso2} style={{ marginRight: '8px' }} />
                                 <Typography marginRight="8px">{parsedCountry.name}</Typography>
                                 <Typography sx={{ minWidth: '360px' }} color="gray">
                                    +{parsedCountry.dialCode}
                                 </Typography>
                              </MenuItem>
                           )
                        })}
                  </Select>
               </InputAdornment>
            ),
         }}
         {...restProps}
      />
   )
}
