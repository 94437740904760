import React from 'react'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { styled } from '@mui/material'
import Badge from '@mui/material/Badge'

import { useCartSummaryQuery } from 'shared/store/useCartSummary'
import { useProfileQuery } from 'shared/store/useProfile'

interface Props {
   badgeType: 'CART' | 'MESSAGES' | 'CART_HEADER'
}

export default function BadgeIcon({ badgeType }: Props) {
   const { data: cartData } = useCartSummaryQuery()
   const { data: profileData } = useProfileQuery()
   const cartProductsCount = cartData?.products.length
   const messagesCount = profileData?.unreadMessages

   function renderBadge() {
      switch (badgeType) {
         case 'CART': {
            return (
               <StyledBadge badgeContent={cartProductsCount} color="primary">
                  <ShoppingCartOutlinedIcon />
               </StyledBadge>
            )
         }
         case 'CART_HEADER': {
            return (
               <StyledBadge badgeContent={cartProductsCount} color="primary">
                  <ShoppingCartOutlinedIcon sx={{ fontSize: 27 }} />
               </StyledBadge>
            )
         }
         case 'MESSAGES': {
            return (
               <StyledBadge badgeContent={messagesCount} color="primary">
                  <EmailOutlinedIcon />
               </StyledBadge>
            )
         }
         default:
            return null
      }
   }

   return renderBadge()
}
const StyledBadge = styled(Badge)({
   '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' },
})
