import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import React from 'react'

import { StyledTableCell } from 'shared/components/StyledTableComponents'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'

interface Props {
   data: OrderProductType[]
}

export default function MissingProducts({ data }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDER_SUMMARY'])

   return (
      <Box>
         <Typography variant="body2" color="text.secondary">
            {t('ORDER_SUMMARY:tables.missingProducts')}
         </Typography>
         <RadiusTableWrapper>
            <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)', width: 1200 }}>
               <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <TableRow>
                     <StyledTableCell>{t('TRANSLATION:columnNames.partIndex')}</StyledTableCell>
                     <StyledTableCell>{t('ORDER_SUMMARY:tables.orderNumber')}</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.wholesaler')}</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.quantity')}</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.netPrice')}</StyledTableCell>
                     <StyledTableCell>VAT</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.grossValue')}</StyledTableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {data.map((item) => (
                     <TableRow key={item.productUuid}>
                        <StyledTableCell>{item.index}</StyledTableCell>
                        <StyledTableCell>{item.wholesaleOrderNumber}</StyledTableCell>
                        <StyledTableCell>
                           <img
                              height="20px"
                              width="auto"
                              src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${item.wholesaleName}.png`}
                              alt={item.wholesaleName}
                           />
                        </StyledTableCell>
                        <StyledTableCell>{item.amount}</StyledTableCell>
                        <StyledTableCell>
                           {item.formattedPrice} {item.formattedConvertedPrice && `(${item.formattedConvertedPrice})`}
                        </StyledTableCell>
                        <StyledTableCell>{item.taxRate}%</StyledTableCell>
                        <StyledTableCell>
                           {item.formattedGrossValue}{' '}
                           {item.formattedConvertedGrossValue && `(${item.formattedConvertedGrossValue})`}
                        </StyledTableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </RadiusTableWrapper>
      </Box>
   )
}
