import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { fetchWatchedProducts, removeWatchedProduct } from './services'
import { DeleteWatchedProductPayload, FetchWatchedProductsResult } from './types'

export function useWatchedProductsQuery(): UseQueryResult<FetchWatchedProductsResult> {
   return useQuery('watchlist', fetchWatchedProducts, { staleTime: 60 * (60 * 1000) })
}

export function useRemoveWatchedProduct() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, Error | AxiosError<ErrorType>, DeleteWatchedProductPayload, unknown>(
      (payload) => removeWatchedProduct(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('watchlist')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
