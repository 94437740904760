import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import ReactApexChart from 'react-apexcharts'
import { useTheme } from '@mui/material'
import Grow from '@mui/material/Grow'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { merge } from 'lodash'
import Typography from '@mui/material/Typography'

import { StatisticsType } from 'shared/store/useStatistics/types'

import chartSettings from './chartSettings'

interface Props {
   chartData: PropType<StatisticsType, 'spentMoney'>
   formattedCurrency: string
   statsFailed: boolean
}

export default function ShoppingChart({ chartData, statsFailed, formattedCurrency }: Props) {
   const [seriesData, setSeriesData] = useState(Object.keys(chartData.years)[Object.keys(chartData.years).length - 1])
   const { t } = useTranslation(['TRANSLATION', 'HOMEPAGE'])
   const theme = useTheme()

   function calculatePercentDifference() {
      const previousYear = chartData.years[(Number(seriesData) - 1).toString()]
      if (previousYear) {
         return (
            (chartData.years[seriesData].reduce((acc, current) => acc + current) * 100) /
               previousYear.reduce((acc, current) => acc + current) -
            100
         )
      }
      return 0
   }

   function handleChangeSeriesData(event: React.ChangeEvent<HTMLInputElement>) {
      setSeriesData(event.target.value)
   }

   const chartOptions = merge(chartSettings(theme), {
      xaxis: {
         categories: [
            t('HOMEPAGE:months.january'),
            t('HOMEPAGE:months.february'),
            t('HOMEPAGE:months.march'),
            t('HOMEPAGE:months.april'),
            t('HOMEPAGE:months.may'),
            t('HOMEPAGE:months.june'),
            t('HOMEPAGE:months.july'),
            t('HOMEPAGE:months.august'),
            t('HOMEPAGE:months.september'),
            t('HOMEPAGE:months.october'),
            t('HOMEPAGE:months.november'),
            t('HOMEPAGE:months.december'),
         ],
      },
      yaxis: {
         labels: {
            formatter: (value: number) => {
               return value.toFixed(0)
            },
         },
      },
   })

   return (
      <Grow in timeout={1400}>
         <Box sx={{ height: '100%' }}>
            <Card sx={{ minHeight: 350 }}>
               <CardHeader
                  title={t('HOMEPAGE:yourPurchases')}
                  subheader={
                     calculatePercentDifference() > 0 &&
                     t('HOMEPAGE:moreThanLastYear', { percent: calculatePercentDifference().toFixed() })
                  }
                  sx={{ '.MuiCardHeader-title': { color: theme.palette.primary.light } }}
                  action={
                     <TextField
                        select
                        fullWidth
                        value={seriesData}
                        SelectProps={{ native: true }}
                        onChange={handleChangeSeriesData}
                        sx={{
                           '& fieldset': { border: '0 !important' },
                           '& select': {
                              pl: 1,
                              py: 0.5,
                              pr: '24px !important',
                              typography: 'subtitle2',
                           },
                           '& .MuiOutlinedInput-root': {
                              borderRadius: 0.75,
                              bgcolor: 'background.neutral',
                           },
                           '& .MuiNativeSelect-icon': {
                              top: 4,
                              right: 0,
                              width: 20,
                              height: 20,
                           },
                        }}
                     >
                        {Object.keys(chartData.years).map((option) => (
                           <option key={option} value={option}>
                              {option}
                           </option>
                        ))}
                     </TextField>
                  }
               />
               <Box sx={{ mt: 3, mx: 3, height: '100%' }} dir="ltr">
                  {!statsFailed ? (
                     <ReactApexChart
                        type="line"
                        series={[{ name: formattedCurrency, data: chartData.years[seriesData] || [] }]}
                        options={chartOptions}
                        height={240}
                     />
                  ) : (
                     <Typography variant="body2">{t('HOMEPAGE:loadingFailed')}</Typography>
                  )}
               </Box>
            </Card>
         </Box>
      </Grow>
   )
}
