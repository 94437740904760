import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { AddLocationPayload, FetchLocationResult, RemoveLocationPayload, SaveLocationDetailsPayload } from './types'

export function fetchLocations(): Promise<FetchLocationResult> {
   let errMessage = ''
   return httpClient
      .get('locations')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('LOCATIONS fetchLocations service', errMessage, 'error')
         throw error
      })
}

export function saveLocationDetails(payload: SaveLocationDetailsPayload, uuid: string) {
   let errMessage = ''
   const url = `locations/${uuid}`

   return httpClient
      .put<Result>(url, payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('LOCATIONS saveLocationDetails service', errMessage, 'error')
         throw error
      })
}

export function addLocation(payload: AddLocationPayload) {
   let errMessage = ''
   return httpClient
      .post<Result>('locations/', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('LOCATIONS addLocation service', errMessage, 'error')
         throw error
      })
}

export function removeLocation(payload: RemoveLocationPayload) {
   let errMessage = ''
   return httpClient
      .delete<Result>(`locations/${payload.locationUuid}`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('LOCATIONS removeLocation service', errMessage, 'error')
         throw error
      })
}
