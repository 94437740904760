import { styled } from '@mui/material'

export const HourDiv = styled('div')(({ backgroundColor, color }: { backgroundColor: string; color: string }) => ({
   margin: '0 auto',
   padding: 3,
   maxWidth: '90px',
   borderRadius: 5,
   textAlign: 'center',
   fontWeight: 'bold',
   backgroundColor,
   color,
}))
