/* eslint-disable no-nested-ternary */
import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import GrayButton from 'shared/components/Buttons/GrayButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { ButtonContainer } from './styles'

interface Props {
   onBuy: () => void
   onAddToCart: () => void
   status: CooperatingWholesalerStatus
   totalAvailability: Nullable<number>
   errorMessage: Nullable<string>
   isLoading: boolean
   wholesaleName: string
   netPrice: Nullable<number>
   onReportMissingProduct: () => void
   onEditWholesaler: () => void
   onRemoveWholesaler: () => void
}

export default function Actions({
   onAddToCart,
   onBuy,
   onEditWholesaler,
   onReportMissingProduct,
   onRemoveWholesaler,
   status,
   netPrice,
   errorMessage,
   wholesaleName,
   totalAvailability,
   isLoading,
}: Props) {
   const { t } = useTranslation('PRODUCT_DETAILS')
   const { user } = useLogin()

   function renderEditButton() {
      if (user.roles.includes(UserRoles.DATA_MANAGER)) {
         return (
            <>
               <IconButton onClick={onEditWholesaler}>
                  <EditIcon />
               </IconButton>
               <IconButton onClick={onRemoveWholesaler}>
                  <DeleteIcon color="error" />
               </IconButton>
            </>
         )
      }
      return null
   }

   function renderConnectionProblemContent() {
      if (errorMessage && !errorMessage.includes('NOT_HANDLED')) {
         return (
            <>
               <Typography
                  sx={{ fontWeight: 'bold', color: 'red', border: '1px solid red', p: 1, borderRadius: '5px' }}
                  variant="body1"
               >
                  {errorMessage}
               </Typography>
               {renderEditButton()}
            </>
         )
      }
      return (
         <>
            <Typography
               sx={{ fontWeight: 'bold', color: 'red', border: '1px solid red', p: 1, borderRadius: '5px' }}
               variant="body1"
            >
               {t('tables.wholesaleReconnectFailed')}
            </Typography>
            {renderEditButton()}
         </>
      )
   }

   if (
      status === 'ONLINE_CONNECTION_PROBLEM' ||
      status === 'PRODUCT_NOT_EXIST_IN_WHOLESALE' ||
      (status === 'OK_ONLINE_AVAILABILITY' && !netPrice)
   ) {
      return <ButtonContainer>{renderConnectionProblemContent()}</ButtonContainer>
   }
   if (!totalAvailability || totalAvailability < 0) {
      return (
         <ButtonContainer>
            <Tooltip
               placement="right"
               disableHoverListener={status !== 'OK_ONLINE_AVAILABILITY'}
               title={t('tables.sendReportTooltip') || ''}
            >
               <span>
                  <GrayButton
                     variant="outlined"
                     type="button"
                     onClick={onReportMissingProduct}
                     disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
                  >
                     {t('tables.reportButton')}
                  </GrayButton>
               </span>
            </Tooltip>
            {renderEditButton()}
         </ButtonContainer>
      )
   }
   return (
      <ButtonContainer>
         {wholesaleName !== 'Motorro' && (
            <ContainedButton
               variant="contained"
               onClick={onBuy}
               disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading || user?.roles.includes(UserRoles.SALES_REP)}
            >
               {t('tables.buyNowButton')}
            </ContainedButton>
         )}
         <OutlinedButton
            variant="outlined"
            onClick={onAddToCart}
            disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
         >
            {t('tables.addToCartButton')}
         </OutlinedButton>
         {renderEditButton()}
      </ButtonContainer>
   )
}
