import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTheme } from '@mui/material'

import { StyledTableHead } from 'shared/components/StyledTableComponents'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useSetActiveCartMutation } from 'shared/store/useSavedCarts'
import useClientReflinksQuery from 'shared/store/useClientReflinks'
import { useProfileQuery } from 'shared/store/useProfile'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { httpClient } from 'shared/lib'

import Modal from './components/Modal'
import { pl, en, de } from './locales'
import {
   StyledTable,
   StyledTableCell,
   StyledTableRow,
   StyledContentTableCell,
   StyledLink,
   StyledCopyIcon,
   StyledDeleteIcon,
} from './styles'
import { ModalInfoType } from './types'

export default function ClientsCarts() {
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()
   const { mutate } = useSetActiveCartMutation()
   const { addNotification } = useNotificationContext()
   const { user } = useLogin()
   const { i18n, t } = useTranslation(['TRANSLATION', 'CLIENTS_CARTS'])
   i18n.addResourceBundle('pl-PL', 'CLIENTS_CARTS', pl)
   i18n.addResourceBundle('en-EN', 'CLIENTS_CARTS', en)
   i18n.addResourceBundle('de-DE', 'CLIENTS_CARTS', de)
   const theme = useTheme()
   const { data, refetch } = useClientReflinksQuery()
   const { data: userData } = useProfileQuery()
   const [showSmsAccess, setShowSmsAccess] = useState(false)
   const navigate = useNavigate()
   const [collapse, setCollapse] = useState<string[]>([])

   function handleCollapseProduct(uuid: string) {
      if (collapse.includes(uuid)) {
         setCollapse(collapse.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapse]
         tempCollapseProducts.push(uuid)
         setCollapse([...tempCollapseProducts])
      }
   }

   function handleRowClick(status: string, cartUuid: Nullable<string>) {
      if (status === 'SENT' || status === 'IN_PROGRESS') {
         setModalInfo({
            open: true,
            modalType: status,
         })
      } else if (cartUuid) {
         mutate(cartUuid, {
            onSuccess: () => {
               navigate('/dashboard/main-cart')
            },
         })
      }
   }

   useEffect(() => {
      httpClient
         .get('/sms-api/')
         .then(() => setShowSmsAccess(true))
         .catch(() => setShowSmsAccess(false))
   }, [])

   function renderContent() {
      if (data) {
         if (data.length > 0) {
            return (
               <Box>
                  <StyledTable>
                     <StyledTableHead>
                        <TableRow>
                           <StyledTableCell>{t('CLIENTS_CARTS:cartLink')}</StyledTableCell>
                           <StyledTableCell>{t('CLIENTS_CARTS:clientPhoneNumber')}</StyledTableCell>
                           <StyledTableCell>{t('TRANSLATION:columnNames.cartName')}</StyledTableCell>
                           <StyledTableCell>{t('TRANSLATION:columnNames.removeCart')}</StyledTableCell>
                        </TableRow>
                     </StyledTableHead>
                     <TableBody sx={{ padding: 0 }}>
                        {data.map((item) => (
                           <React.Fragment key={item.uuid}>
                              <StyledTableRow onClick={() => handleCollapseProduct(item.uuid)}>
                                 <StyledContentTableCell>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 0.5,
                                          justifyContent: 'center',
                                       }}
                                    >
                                       <StyledLink
                                          onClick={(e) => {
                                             e.stopPropagation()
                                          }}
                                          href={item.url}
                                          target="_blank"
                                       >
                                          Link
                                       </StyledLink>
                                       <CopyToClipboard text={item.url}>
                                          <StyledCopyIcon
                                             onClick={(e) => {
                                                addNotification(t('CLIENTS_CARTS:copied'), 'success')
                                                e.stopPropagation()
                                             }}
                                          />
                                       </CopyToClipboard>
                                       {showSmsAccess && (
                                          <IconButton
                                             onClick={(e) => {
                                                setModalInfo({
                                                   modalType: 'SMS',
                                                   open: true,
                                                   notificationSentDate: item.notificationSentDate,
                                                   reflinkUuid: item.url,
                                                   phoneNumber: item.phoneNumber!,
                                                })
                                                e.stopPropagation()
                                             }}
                                          >
                                             <SmsOutlinedIcon sx={{ fontSize: 17 }} />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </StyledContentTableCell>
                                 <StyledContentTableCell>{item.phoneNumber}</StyledContentTableCell>
                                 <StyledContentTableCell>{item.cartName}</StyledContentTableCell>
                                 <StyledContentTableCell>
                                    <IconButton
                                       onClick={(e) => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          setModalInfo({ open: true, modalType: 'DELETE', reflinkUuid: item.uuid })
                                       }}
                                    >
                                       <StyledDeleteIcon />
                                    </IconButton>
                                 </StyledContentTableCell>
                              </StyledTableRow>
                              <TableRow>
                                 <TableCell colSpan={4} sx={{ padding: 0 }}>
                                    <Collapse
                                       style={{ overflowY: 'scroll', maxHeight: '300px' }}
                                       in={collapse.includes(item.uuid)}
                                       timeout="auto"
                                    >
                                       <Table>
                                          <StyledTableHead>
                                             <TableRow>
                                                <StyledContentTableCell>
                                                   {t('TRANSLATION:columnNames.cartName')}
                                                </StyledContentTableCell>
                                                <StyledContentTableCell>
                                                   {userData?.priceType === 'GROSS'
                                                      ? t('TRANSLATION:columnNames.grossValue')
                                                      : t('TRANSLATION:columnNames.netValue')}
                                                </StyledContentTableCell>
                                                <StyledContentTableCell>Status</StyledContentTableCell>
                                                <StyledContentTableCell>
                                                   {t('CLIENTS_CARTS:confirmationDate')}
                                                </StyledContentTableCell>
                                             </TableRow>
                                          </StyledTableHead>
                                          <TableBody>
                                             {item.carts.map((cart) => (
                                                <StyledTableRow
                                                   onClick={() => handleRowClick(cart.status, cart.cartUuid)}
                                                   key={cart.uuid}
                                                >
                                                   <StyledContentTableCell>{cart.cartName}</StyledContentTableCell>
                                                   <StyledContentTableCell>
                                                      {userData?.priceType === 'GROSS'
                                                         ? cart.formattedGrossClientPriceSum
                                                         : cart.formattedNetClientPriceSum}
                                                   </StyledContentTableCell>
                                                   <StyledContentTableCell>
                                                      {t(`CLIENTS_CARTS:status.${cart.status}`)}
                                                   </StyledContentTableCell>
                                                   <StyledContentTableCell>
                                                      {cart.confirmationDate || '-'}
                                                   </StyledContentTableCell>
                                                </StyledTableRow>
                                             ))}
                                          </TableBody>
                                       </Table>
                                    </Collapse>
                                 </TableCell>
                              </TableRow>
                           </React.Fragment>
                        ))}
                     </TableBody>
                  </StyledTable>
               </Box>
            )
         }
         return (
            <Typography textAlign="center" color="primary" variant="body1" fontWeight="bold">
               {t('CLIENTS_CARTS:noReflinks')}
            </Typography>
         )
      }
      return <CircularProgress sx={{ alignSelf: 'center' }} />
   }

   function renderView() {
      if (user.roles.includes(UserRoles.BASIC)) {
         return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: 10 }}>
               <Typography variant="h4" color="red">
                  {t('CLIENTS_CARTS:notForBasic')}
               </Typography>
               <ContainedButton variant="contained" onClick={() => navigate('/dashboard/payments')}>
                  {t('CLIENTS_CARTS:paymentLink')}
               </ContainedButton>
            </Box>
         )
      }
      if (user.roles.includes(UserRoles.PRO) || user.roles.includes(UserRoles.ULTRA)) {
         return (
            <>
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                  <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal' }}>
                     {t('CLIENTS_CARTS:title')}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>{renderContent()}</Box>
                  <OutlinedButton
                     sx={{ alignSelf: 'center' }}
                     variant="outlined"
                     onClick={() => setModalInfo({ modalType: 'CREATE', open: true })}
                  >
                     {t('CLIENTS_CARTS:createCartButton')}
                  </OutlinedButton>
               </Box>
               <Typography sx={{ mt: 2, display: 'flex', fontWeight: 'bold' }} variant="body1">
                  *{t('CLIENTS_CARTS:mustHaveSettings')}
                  <Typography
                     sx={{
                        ml: 0.5,
                        fontWeight: 'bold',
                        '&:hover': { color: theme.palette.primary.dark, cursor: 'pointer' },
                     }}
                     onClick={() => navigate('/dashboard/locations?clientCarts=true')}
                     color="primary"
                     variant="body1"
                  >
                     {t('CLIENTS_CARTS:goToSettings')}
                  </Typography>
               </Typography>
            </>
         )
      }
      return (
         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: 10 }}>
            <Typography variant="h4" color="red">
               {t('CLIENTS_CARTS:notForBasic')}
            </Typography>
            <ContainedButton variant="contained" onClick={() => navigate('/dashboard/payments')}>
               {t('CLIENTS_CARTS:paymentLink')}
            </ContainedButton>
         </Box>
      )
   }

   useEffect(() => {
      refetch()
   }, [userData?.locationUuid])

   return (
      <>
         {renderView()}
         {modalInfo && <Modal modalInfo={modalInfo} handleClose={() => setModalInfo(undefined)} />}
      </>
   )
}
