import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

import { useDeleteServiceMutation } from 'shared/store/useAddtionalServices'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import { ModalInfoType } from '../types'
import CreateServiceModal from './components/CreateServiceModal'
import EditServiceModal from './components/EditServiceModal'

interface Props {
   handleClose: () => void
   modalInfo?: ModalInfoType
}

export default function Modal({ handleClose, modalInfo }: Props) {
   const { t } = useTranslation(['SERVICES', 'TRANSLATION'])
   const { mutate, isLoading } = useDeleteServiceMutation()

   const theme = useTheme()

   function removeService() {
      if (modalInfo?.locationUuid && modalInfo.serviceUuid) {
         mutate(
            { locationUuid: modalInfo.locationUuid, serviceUuid: modalInfo.serviceUuid },
            {
               onSuccess: () => {
                  handleClose()
               },
            }
         )
      }
   }

   function renderContent() {
      switch (modalInfo?.modalType) {
         case 'DELETE':
            return (
               <>
                  <DialogContent>
                     <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {t('SERVICES:deleteSureText', {
                           serviceName: `${modalInfo?.serviceName}`,
                        })}
                     </Typography>
                  </DialogContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 1, mb: 2 }}>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:no')}
                     </OutlinedButton>
                     <ContainedButton variant="contained" disabled={isLoading} onClick={() => removeService()}>
                        {t('TRANSLATION:yes')}
                     </ContainedButton>
                  </Box>
               </>
            )
         case 'EDIT':
            return (
               <EditServiceModal
                  serviceUuid={modalInfo.serviceUuid || ''}
                  locationUuid={modalInfo.locationUuid}
                  handleClose={handleClose}
                  serviceInfo={modalInfo.serviceInfo}
               />
            )
         case 'CREATE':
            return <CreateServiceModal locationUuid={modalInfo.locationUuid} handleClose={handleClose} />
         default:
            return null
      }
   }
   return (
      <Dialog open={!!modalInfo?.modalType} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
               {t(`${modalInfo?.modalType?.toLowerCase()}`)}
            </Typography>
         </DialogTitle>
         {renderContent()}
      </Dialog>
   )
}
