import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

interface Props {
   imagesLinks: string[]
   open: boolean
   handleClose: () => void
}

export default function PhotoBrowser({ open, handleClose, imagesLinks }: Props) {
   const [imageIndex, setImageIndex] = useState(0)

   function nextPhoto() {
      if (imageIndex === imagesLinks.length - 1) {
         setImageIndex(0)
      } else setImageIndex((prevState) => prevState + 1)
   }

   function prevPhoto() {
      if (imageIndex === 0) {
         setImageIndex(imagesLinks.length - 1)
      } else setImageIndex((prevState) => prevState - 1)
   }

   return (
      <Dialog maxWidth="lg" open={open} onClose={handleClose}>
         <Box sx={{ display: 'flex' }}>
            <IconButton
               onClick={prevPhoto}
               disabled={imagesLinks.length === 0 || imagesLinks.length === 1}
               sx={{ alignSelf: 'center' }}
            >
               <ArrowBackIosIcon />
            </IconButton>
            <img
               style={{ userSelect: 'none', maxHeight: 400, width: 'auto' }}
               src={`${process.env.REACT_APP_FILES_URL}/assets/producers/${imagesLinks[imageIndex]}`}
               alt="partPhoto"
            />
            <IconButton
               sx={{ transform: 'rotate(180deg)', alignSelf: 'center' }}
               onClick={nextPhoto}
               disabled={imagesLinks.length === 0 || imagesLinks.length === 1}
            >
               <ArrowBackIosIcon />
            </IconButton>
         </Box>
      </Dialog>
   )
}
