/* eslint-disable react/no-array-index-key */
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import Grow from '@mui/material/Grow'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import React from 'react'

import { useCartSummaryQuery } from 'shared/store/useCartSummary'
import { useProfileQuery } from 'shared/store/useProfile'

export default function ActiveCart() {
   const { t } = useTranslation(['TRANSLATION', 'HOMEPAGE'])
   const theme = useTheme()
   const { data, isFetching, isError } = useCartSummaryQuery()
   const { data: profileData } = useProfileQuery()

   function renderContent() {
      if (data) {
         switch (true) {
            case isFetching:
               return (
                  <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                     <CircularProgress />
                  </Box>
               )
            case isError:
               return <Typography variant="body2">{t('HOMEPAGE:loadingFailed')}</Typography>
            case data.products.length === 0:
               return <Typography>{t('HOMEPAGE:noProducts')}</Typography>
            default:
               return data.products
                  .filter((item) => item.status === 'AVAILABLE')
                  .map((item) => (
                     <div style={{ display: 'flex', gap: 1 }} key={item.uuid}>
                        <Typography>
                           {t('HOMEPAGE:product', {
                              index: item.index,
                              wholesaler: item.cartWholesaleName,
                              producer: item.producerName,
                           })}
                        </Typography>
                        {data.products.find(
                           (product) => product.status === 'NOT_AVAILABLE' && product.uuid === item.uuid
                        ) && <Typography color="red">{t('HOMEPAGE:partiallyNotAvailable')}</Typography>}
                     </div>
                  ))
         }
      }
      return null
   }

   return (
      <Grow in timeout={800}>
         <Card
            sx={{
               minHeight: 350,
               maxHeight: 350,
               minWidth: 400,
               flex: 1,
               display: 'flex',
               flexDirection: 'column',
               overflowY: 'auto',
            }}
         >
            <CardHeader
               title={t('HOMEPAGE:usingCart', { cartName: data?.cartDetailsDTO.cartName || '' })}
               sx={{ '.MuiCardHeader-title': { color: theme.palette.primary.light } }}
            />
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: 2,
                  pt: 1,
                  height: '100%',
                  paddingX: 3,
                  overflowY: 'scroll',
               }}
            >
               <Box>{renderContent()}</Box>
               {data && !isFetching && data.products.length > 0 && (
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'rpw',
                        justifyContent: 'space-between',
                        gap: 3,
                        mb: 3,
                        fontSize: 15,
                     }}
                  >
                     <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                        {`${t('TRANSLATION:columnNames.sum')} ${t(
                           `TRANSLATION:${profileData?.priceType}`
                        )}:${profileData?.priceType === 'NET' ? data.formattedNetValue : data.formattedGrossValue}`}
                     </Typography>
                     <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                        {`${t('TRANSLATION:columnNames.clientValue')} ${t(
                           `TRANSLATION:${profileData?.priceType}`
                        )}:${profileData?.priceType === 'NET' ? data.formattedClientNetValue : data.formattedClientGrossValue}`}
                     </Typography>
                  </Box>
               )}
            </Box>
         </Card>
      </Grow>
   )
}
