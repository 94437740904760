import React from 'react'
import { useTheme } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { LocationType } from 'shared/store/useLocations/types'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

interface Props {
   active?: LocationType
   list?: LocationType[]
   onChangeLocation: (uuid: string) => void
}

export default function LocationList({ list = [], onChangeLocation, active }: Props) {
   const { user } = useLogin()
   const theme = useTheme()

   return (
      <Grid item sm={12} xs={12} md={2} lg={2}>
         <Box sx={{ textAlign: 'center', alignSelf: 'flex-start' }}>
            <Box>
               {active && (
                  <Tabs sx={{ width: '100%' }} orientation="vertical" value={active.locationSettings.uuid}>
                     {list?.map((location) => (
                        <Tab
                           key={location.locationSettings.uuid}
                           value={location.locationSettings.uuid}
                           disabled={
                              !user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) &&
                              location.locationSettings.adminUuid !== user.uuid
                           }
                           label={location.locationSettings.name}
                           sx={{
                              minWidth: '100%',
                              lineBreak: 'loose',
                              px: 4,
                              color:
                                 location.locationSettings.uuid === active?.locationSettings.uuid ? 'main' : 'grayText',
                              '&:hover': {
                                 color: theme.palette.primary.main,
                                 backgroundColor: '#E4EEF7',
                              },
                           }}
                           onClick={() => onChangeLocation(location.locationSettings.uuid)}
                        />
                     ))}
                  </Tabs>
               )}
            </Box>
         </Box>
      </Grid>
   )
}
