import React from 'react'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import AlternateEmailSharpIcon from '@mui/icons-material/AlternateEmailSharp'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import GroupIcon from '@mui/icons-material/Group'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import ApartmentIcon from '@mui/icons-material/Apartment'
import ConstructionIcon from '@mui/icons-material/Construction'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'

import { UserRoles } from 'shared/consts'
import { RouteType } from 'shared/types'

import UsersAndSub from 'views/Authorized/views/UsersAndSubscription'
import ErpProductCard from 'views/Authorized/views/ErpProductCard'
import ProductDetails from 'views/Authorized/views/ProductDetails'
import OrdersHistory from 'views/Authorized/views/OrdersHistory'
import Notifications from 'views/Authorized/views/Notifications'
import ProductsList from 'views/Authorized/views/ProductsList'
import Wholesalers from 'views/Authorized/views/Wholesalers'
import SavedCarts from 'views/Authorized/views/SavedCarts'
import Locations from 'views/Authorized/views/Locations'
import Watchlist from 'views/Authorized/views/Watchlist'
import Messages from 'views/Authorized/views/Messages'
import Settings from 'views/Authorized/views/Settings'
import MainCart from 'views/Authorized/views/MainCart'
import Pricing from 'views/Authorized/views/Pricing'
import BadgeIcon from 'shared/components/BadgeIcon'
import OrderSummary from 'views/Authorized/views/OrderSummary'
import CartFromCsv from 'views/Authorized/views/CartFromCSV'
import BaselinkerOrderSummary from 'views/Authorized/views/BaselinkerOrderSummary'
import InternalProductsList from 'views/Authorized/views/InternalProductsList'
import Contact from 'views/Authorized/views/Contact'
import ClientsCarts from 'views/Authorized/views/ClientsCarts'
import AdditionalServices from 'views/Authorized/views/AdditionalServices'
import Company from 'views/Authorized/views/Company'

const routes: RouteType[] = [
   {
      name: 'routes.mainCart',
      group: 'cart',
      path: 'main-cart',
      icon: React.createElement(BadgeIcon, { badgeType: 'CART' }),
      authority: [UserRoles.SUBSCRIPTION, UserRoles.DEMO],
      inSidebar: true,
      element: MainCart,
   },
   {
      name: 'routes.ordersHistory',
      group: 'cart',
      path: 'orders-history',
      icon: React.createElement(HistoryIcon),
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.SUBSCRIPTION,
         UserRoles.SALES_REP,
         UserRoles.REGISTERED_USER,
         UserRoles.LOCALIZATION_ADMIN,
      ],
      inSidebar: true,
      element: OrdersHistory,
   },
   {
      name: 'routes.savedCarts',
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.SALES_REP,
         UserRoles.PZU,
         UserRoles.REGISTERED_USER,
         UserRoles.SUBSCRIPTION,
         UserRoles.DEMO,
         UserRoles.LOCALIZATION_ADMIN,
      ],
      group: 'cart',
      icon: React.createElement(SaveIcon),
      path: 'saved-carts',
      inSidebar: true,
      element: SavedCarts,
   },
   {
      name: 'routes.clientsCarts',
      group: 'cart',
      icon: React.createElement(ShoppingCartCheckoutIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN, UserRoles.LOCALIZATION_ADMIN],
      path: 'clients-carts',
      inSidebar: true,
      element: ClientsCarts,
   },
   {
      name: 'routes.csvCart',
      authority: [UserRoles.PRO, UserRoles.ULTRA],
      inSidebar: true,
      element: CartFromCsv,
      group: 'cart',
      path: 'import-cart',
      icon: React.createElement(UploadFileIcon),
   },
   {
      name: 'routes.company',
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN],
      inSidebar: true,
      element: Company,
      group: 'settings',
      path: 'company',
      icon: React.createElement(ApartmentIcon),
   },
   {
      name: 'routes.locations',
      group: 'settings',
      icon: React.createElement(MyLocationOutlinedIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN, UserRoles.SUBSCRIPTION, UserRoles.LOCALIZATION_ADMIN],
      path: 'locations',
      inSidebar: true,
      element: Locations,
   },
   {
      name: 'routes.wholesalers',
      group: 'settings',
      icon: React.createElement(EmojiTransportationIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN, UserRoles.LOCALIZATION_ADMIN],
      path: 'wholesalers',
      needSub: true,
      inSidebar: true,
      element: Wholesalers,
   },
   {
      name: 'routes.additionalServices',
      authority: [UserRoles.LOCALIZATION_ADMIN, UserRoles.MAIN_LOCALIZATION_ADMIN],
      icon: React.createElement(ConstructionIcon),
      inSidebar: true,
      element: AdditionalServices,
      group: 'settings',
      path: 'additional-services',
   },
   {
      name: 'routes.watchlist',
      group: 'settings',
      icon: React.createElement(SearchOutlinedIcon),
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.SUBSCRIPTION,
         UserRoles.SALES_REP,
         UserRoles.LOCALIZATION_ADMIN,
         UserRoles.DEMO,
      ],
      path: 'watchlist',
      inSidebar: true,
      element: Watchlist,
   },
   {
      name: 'routes.settings',
      group: 'settings',
      icon: React.createElement(SettingsOutlinedIcon),
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.PZU,
         UserRoles.SALES_REP,
         UserRoles.SUBSCRIPTION,
         UserRoles.LOCALIZATION_ADMIN,
         UserRoles.DEMO,
      ],
      path: 'settings',
      inSidebar: true,
      element: Settings,
   },
   {
      name: 'routes.usersAndSubs',
      group: 'settings',
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.SUBSCRIPTION,
         UserRoles.PZU,
         UserRoles.SALES_REP,
         UserRoles.LOCALIZATION_ADMIN,
      ],
      icon: React.createElement(GroupIcon),
      path: 'users-and-subscription',
      inSidebar: true,
      element: UsersAndSub,
   },
   {
      name: 'routes.payments',
      group: 'settings',
      icon: React.createElement(AttachMoneyOutlinedIcon),
      authority: [UserRoles.MAIN_LOCALIZATION_ADMIN],
      path: 'payments',
      inSidebar: true,
      element: Pricing,
   },
   {
      name: 'routes.contact',
      group: 'settings',
      icon: React.createElement(AlternateEmailSharpIcon),
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.SUBSCRIPTION,
         UserRoles.PZU,
         UserRoles.SALES_REP,
         UserRoles.LOCALIZATION_ADMIN,
         UserRoles.DEMO,
      ],
      path: 'contact',
      inSidebar: true,
      element: Contact,
   },
   {
      name: 'routes.messages',
      group: 'messages',
      icon: React.createElement(BadgeIcon, { badgeType: 'MESSAGES' }),
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.PZU,
         UserRoles.SALES_REP,
         UserRoles.SUBSCRIPTION,
         UserRoles.LOCALIZATION_ADMIN,
         UserRoles.DEMO,
      ],
      inSidebar: false,
      path: 'messages',
      element: Messages,
   },
   {
      name: 'notifications',
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.PZU,
         UserRoles.SALES_REP,
         UserRoles.SUBSCRIPTION,
         UserRoles.LOCALIZATION_ADMIN,
         UserRoles.DEMO,
      ],
      path: 'notifications',
      inSidebar: false,
      element: Notifications,
   },
   {
      name: 'productsList',
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.SALES_REP,
         UserRoles.REGISTERED_USER,
         UserRoles.LOCALIZATION_ADMIN,
         UserRoles.DEMO,
      ],
      path: 'search',
      inSidebar: false,
      element: ProductsList,
   },
   {
      name: 'productDetails',
      authority: [UserRoles.SUBSCRIPTION, UserRoles.DEMO],
      path: 'product',
      inSidebar: false,
      element: ProductDetails,
   },
   {
      name: 'erpProductCard',
      authority: [UserRoles.PZU],
      path: 'erp-product',
      inSidebar: false,
      element: ErpProductCard,
   },
   {
      name: 'orderSummary',
      authority: [
         UserRoles.MAIN_LOCALIZATION_ADMIN,
         UserRoles.SALES_REP,
         UserRoles.SUBSCRIPTION,
         UserRoles.LOCALIZATION_ADMIN,
         UserRoles.DEMO,
      ],
      inSidebar: false,
      element: OrderSummary,
      path: 'order-summary',
   },
   // {
   //    name: 'routes.ourProducts',
   //    authority: [
   //       UserRoles.MAIN_LOCALIZATION_ADMIN,
   //       UserRoles.SALES_REP,
   //       UserRoles.SUBSCRIPTION,
   //       UserRoles.LOCALIZATION_ADMIN,
   //       UserRoles.DEMO,
   //    ],
   //    group: 'shopping',
   //    inSidebar: true,
   //    element: MotorroProducts,
   //    icon: React.createElement('img', { src: logo, style: { maxHeight: 15, width: 'auto' } }),
   //    path: 'our-products',
   // },
   {
      name: 'baselinker-order-summary',
      authority: [UserRoles.REGISTERED_USER],
      inSidebar: false,
      element: BaselinkerOrderSummary,
      path: 'baselinker-summary',
   },
   {
      name: 'internal-search',
      authority: [UserRoles.DATA_MANAGER],
      inSidebar: false,
      element: InternalProductsList,
      path: 'internal-search',
   },
]

export default routes
