import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { AddLocationPayload, RemoveLocationPayload, SaveLocationDetailsPayload, StateType } from './types'
import { addLocation, fetchLocations, removeLocation, saveLocationDetails } from './services'

export function useLocationsQuery(): UseQueryResult<StateType> {
   return useQuery('locations', fetchLocations)
}

export function useDataLocationsMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      AxiosError<ErrorType> | Error,
      { payload: SaveLocationDetailsPayload; uuid: string },
      unknown
   >(
      ({ payload, uuid }) => {
         return saveLocationDetails(payload, uuid)
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
               queryClient.invalidateQueries('userProfile')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useAddLocationMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, AddLocationPayload, unknown>(
      (payload) => addLocation(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useRemoveLocationMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, RemoveLocationPayload, unknown>(
      (payload) => removeLocation(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
