import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { FetchMinimumsResult } from './types'

export function fetchMinimums(hideUnchanged?: boolean): Promise<FetchMinimumsResult> {
   let errMessage = ''
   return httpClient
      .get(`wholesale/carts/logisticMinimums?hideUnchanged=${hideUnchanged || false}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('MINIMUMS fetchMinimums service', errMessage, 'error')
         throw error
      })
}
