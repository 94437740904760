import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'

import { LocationType } from 'shared/store/useLocations/types'
import StyledLocationTab from 'shared/components/LocationTab'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

interface Props {
   active?: LocationType
   list?: LocationType[]
   onChangeLocation: (id: string) => void
}

export default function LocationsList({ list = [], onChangeLocation, active }: Props) {
   const { user } = useLogin()

   return (
      <Grid item xs={12} md={2}>
         <Box sx={{ mt: 1 }}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
               }}
            >
               {active && (
                  <Tabs sx={{ width: '100%' }} orientation="vertical" value={active.locationSettings.uuid}>
                     {list?.map((location) => (
                        <StyledLocationTab
                           activeLocationUuid={active.locationSettings.uuid}
                           locationUuid={location.locationSettings.uuid}
                           key={location.locationSettings.uuid}
                           value={location.locationSettings.uuid}
                           disabled={
                              !user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) &&
                              location.locationSettings.adminUuid !== user.uuid
                           }
                           label={location.locationSettings.name}
                           onClick={() => onChangeLocation(location.locationSettings.uuid)}
                        />
                     ))}
                  </Tabs>
               )}
            </Box>
         </Box>
      </Grid>
   )
}
