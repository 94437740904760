import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import React from 'react'

import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import { Product } from 'shared/store/useCartSummary/types'

import { UnavailableTableHeaders } from '../../../data/TableHeaders'
import { ModalInfoType } from '../../../types'
import {
   StyledDeleteIcon,
   StyledTableCell,
   StyledTableRow,
   StyledTitleRow,
   StyledTitleTableCell,
} from '../../../styles'

interface Props {
   products: Product[]
   setModalInfo: (modalInfo: ModalInfoType) => void
}

export default function UnavailableProductTable({ products, setModalInfo }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
         <Box sx={{ mt: 3, mb: 1, width: '100%' }}>
            <Typography variant="body1" sx={{ color: 'red' }}>
               {t('MAIN_CART:unavailableProducts')}
            </Typography>
         </Box>
         <RadiusTableWrapper sx={{ width: '100%' }}>
            <Table
               sx={{
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  margin: '0 auto',
                  mt: 1,
               }}
            >
               <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <StyledTitleRow>
                     {UnavailableTableHeaders.map((item) => (
                        <StyledTitleTableCell key={item}>{t(`TRANSLATION:columnNames.${item}`)}</StyledTitleTableCell>
                     ))}
                  </StyledTitleRow>
               </TableHead>
               <TableBody>
                  {products.map((product) => (
                     <StyledTableRow key={product.uuid}>
                        <Tooltip title={product.cartProductName}>
                           <StyledTableCell>{product.index}</StyledTableCell>
                        </Tooltip>
                        <StyledTableCell>
                           <img
                              title={product.producerName}
                              height="20px"
                              width="auto"
                              src={`https://files.motorro.eu/img?name=${product.producerNameWithoutSpecialChars.toLowerCase()}`}
                              alt={product.producerName}
                           />
                        </StyledTableCell>
                        <StyledTableCell>
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <HiddenWholesalerLogo
                                 wholesalerName={product.cartWholesaleName}
                                 imgHeight="20px"
                                 imgWidth="auto"
                              />
                              {product.requirePrepayment && (
                                 <Tooltip title={t('TRANSLATION:requirePrepayment')}>
                                    <AttachMoneyIcon color="success" />
                                 </Tooltip>
                              )}
                           </Box>
                        </StyledTableCell>
                        <StyledTableCell>{product.cartWholesaleDTO.quantity}</StyledTableCell>
                        <StyledTableCell>{product.cartWholesaleDTO.formattedNetPrice}</StyledTableCell>

                        <StyledTableCell>{product.cartWholesaleDTO.taxRate}%</StyledTableCell>
                        <StyledTableCell>{product.cartWholesaleDTO.formattedGrossValue}</StyledTableCell>
                        <StyledTableCell>
                           <StyledDeleteIcon
                              onClick={() =>
                                 setModalInfo({
                                    modalType: 'DELETE',
                                    uuid: product.uuid,
                                    removeFromUnavailable: true,
                                    index: product.index,
                                 })
                              }
                           />
                        </StyledTableCell>
                        <StyledTableCell>
                           <IconButton
                              onClick={() =>
                                 setModalInfo({
                                    modalType: 'SWAP',
                                    defaultQuantity: product.cartWholesaleDTO.quantity,
                                    uuid: product.productEntityUuid,
                                    productToRemoveUuid: product.uuid,
                                    removeFromUnavailable: true,
                                 })
                              }
                           >
                              <SwapHorizIcon />
                           </IconButton>
                        </StyledTableCell>
                     </StyledTableRow>
                  ))}
               </TableBody>
            </Table>
         </RadiusTableWrapper>
      </Box>
   )
}
