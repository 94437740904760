import { styled } from '@mui/material'
import Button from '@mui/material/Button'

const GrayButton = styled(Button)({
   borderRadius: 3,
   textTransform: 'uppercase',
   fontSize: 11,
   fontWeight: 'bold',
   backgroundColor: 'white',
   borderColor: 'grayText',
   color: 'grayText',
})

export default GrayButton
