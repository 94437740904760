import React from 'react'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material'

import DeleteModal from './components/DeleteModal'
import { ModalInfoType } from '../../types'

interface Props {
   handleClose: () => void
   modalInfo: ModalInfoType
}

export default function Modal({ handleClose, modalInfo }: Props) {
   const { t } = useTranslation('SAVED_CARTS')
   const theme = useTheme()

   function renderForm() {
      switch (modalInfo.modalType) {
         case 'alreadyInUse':
            return <div>{t('alreadyInUseText', { userName: modalInfo.userName })}</div>
         case 'inProcessing':
            return <div>{t('inProcessingText')}</div>
         case 'delete':
            return <DeleteModal cartName={modalInfo.cartName} uuid={modalInfo.uuid} handleClose={handleClose} />
         default:
            return null
      }
   }
   return (
      <Dialog open={modalInfo.open} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
               {t(`${modalInfo.modalType}`)}
            </Typography>
         </DialogTitle>
         <DialogContent>{renderForm()}</DialogContent>
      </Dialog>
   )
}
