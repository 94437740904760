import React, { useState } from 'react'

import { SmsApiType } from 'shared/store/useWholesalers/types'

import NonCoopCard from './components/NonCoopCard'
import CoopCard from './components/CoopCard'
import { ModalType } from './types'
import Modal from './components/Modal'

interface Props {
   data: SmsApiType
}

export default function SmsApi({ data }: Props) {
   const [openModal, setOpenModal] = useState(false)
   const [modalType, setModalType] = useState<ModalType>('new')

   function handleModalType(type: ModalType) {
      setModalType(type)
      setOpenModal(true)
   }

   return (
      <>
         {data.token ? (
            <CoopCard handleModalType={handleModalType} />
         ) : (
            <NonCoopCard handleModalType={handleModalType} />
         )}
         <Modal data={data} open={openModal} handleClose={() => setOpenModal(false)} type={modalType} />
      </>
   )
}
