import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

function AccessDenied() {
   const { t } = useTranslation('TRANSLATION')

   return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
         <Typography variant="h5" color="primary">
            {t('accessDenied')}
         </Typography>
      </Box>
   )
}

export default AccessDenied
