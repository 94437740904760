import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import logo from 'assets/images/logo-large.png'

import { ReflinkProblemType } from '../../types'

type Props = {
   problemType: ReflinkProblemType
}

export default function ReflinkProblemView({ problemType }: Props) {
   const { t } = useTranslation('COOPERATION_REFLINK')
   const navigate = useNavigate()

   useEffect(() => {
      const timer = setTimeout(() => navigate('/'), 6000)
      return () => clearTimeout(timer)
   }, [])

   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            minHeight: 'calc(100vh - 103px)',
            textAlign: 'center',
            paddingTop: '10%',
         }}
      >
         <img style={{ width: 400, height: 'auto', margin: '0 auto' }} src={logo} alt="Motorro" />
         <Typography variant="h3">{t(`${problemType}`)}</Typography>
         <Typography variant="h4">{t('redirect')}</Typography>
      </Box>
   )
}
