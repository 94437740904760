import React, { useState, createContext, ReactNode, useMemo, useContext } from 'react'

type NotificationContextType = {
   alert: AlertInfo | null
   addNotification: (
      message: string,
      status: PropType<AlertInfo, 'status'>,
      alignment?: PropType<AlertInfo, 'alignment'>
   ) => void
   removeNotification: () => void
}

export const NotificationContext = createContext<NotificationContextType>({
   alert: null,
   addNotification: () => {
      // console.log('INITIAL VALUE')
   },
   removeNotification: () => {
      // console.log('INITIAL VALUE')
   },
})

export function useNotificationContext() {
   const { alert, addNotification, removeNotification } = useContext(NotificationContext)
   return { alert, addNotification, removeNotification }
}

function NotificationProvider({ children }: { children: ReactNode }) {
   const [alert, setAlert] = useState<AlertInfo | null>(null)

   function removeNotification() {
      setAlert(null)
   }

   function addNotification(
      message: string,
      status: PropType<AlertInfo, 'status'>,
      alignment?: PropType<AlertInfo, 'alignment'>
   ) {
      setAlert({ message, status, alignment })
   }

   const contextValue: NotificationContextType = useMemo(
      () => ({
         alert,
         addNotification,
         removeNotification,
      }),
      [alert]
   )

   return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
}

export default NotificationProvider
