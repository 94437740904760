import React from 'react'

import { BaselinkerType, ExternalTool, SmsApiType, StateType } from 'shared/store/useWholesalers/types'

import SmsApi from './components/SmsApi'
import Baselinker from './components/Baselinker'

interface Props {
   externalTools: PropType<StateType, 'externalTools'>
}

export default function ExternalTools({ externalTools }: Props) {
   function renderTools(name: string, data: ExternalTool) {
      switch (name) {
         case 'Baselinker':
            return <Baselinker data={data as BaselinkerType} />
         case 'SMS API':
            return <SmsApi data={data as SmsApiType} />
         default:
            return null
      }
   }

   return <>{externalTools.map((item) => renderTools(item.name, item))}</>
}
