import React from 'react'
import Slider, { Settings } from 'react-slick'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'

import useAwardedProducers from 'shared/store/useAwardedProducers'

const SLIDER_SETTINGS: Settings = {
   dots: false,
   arrows: false,
   infinite: true,
   autoplay: true,
   autoplaySpeed: 3000,
   cssEase: 'linear',
   slidesToScroll: 1,
   pauseOnHover: true,
   variableWidth: true,
   responsive: [
      {
         breakpoint: 1200,
         settings: {
            variableWidth: true,
         },
      },
      {
         breakpoint: 900,
         settings: {
            slidesToShow: 4,
            variableWidth: false,
         },
      },
      {
         breakpoint: 600,
         settings: {
            slidesToShow: 3,
            variableWidth: false,
         },
      },
      {
         breakpoint: 400,
         settings: {
            slidesToShow: 2,
            variableWidth: false,
         },
      },
   ],
}

export default function ProducersSlider() {
   const { data } = useAwardedProducers()

   return (
      <Section>
         {data ? (
            <div style={{ flex: '1 1 100%', width: '100%' }}>
               <Slider {...SLIDER_SETTINGS}>
                  {data.map((element) => (
                     <div key={element.uuid}>
                        <SlideBox>
                           <img
                              src={`${process.env.REACT_APP_FILES_URL}/awarded/${element.imageFilePath}`}
                              alt={element.name}
                           />
                        </SlideBox>
                     </div>
                  ))}
               </Slider>
            </div>
         ) : (
            <CircularProgress />
         )}
      </Section>
   )
}

const Section = styled(Box)(({ theme }) => ({
   width: '100%',
   [theme.breakpoints.up('md')]: {
      bottom: 75,
   },
   [theme.breakpoints.down('md')]: {
      bottom: 203,
   },
   textAlign: 'center',
   boxShadow: '0px 0px 10px -4px #000000',
   minHeight: 96,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}))

const SlideBox = styled(Box)(({ theme }) => ({
   height: 96,
   margin: '0 10px',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   cursor: 'pointer',
   filter: 'grayscale(100)',

   [theme.breakpoints.up('md')]: {
      margin: '0 20px',
   },

   '&:hover': {
      transition: 'transform .2s',
      filter: 'grayscale(0)',
      transform: 'scale(1.1)',
   },

   '& > img': {
      height: 'auto',
      maxHeight: 40,
      maxWidth: '100%',

      [theme.breakpoints.up('md')]: {
         maxWidth: '80%',
      },
   },
}))
