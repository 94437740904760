import { httpClient } from 'shared/lib'

export default function getCompanyUsersService(showStats: boolean) {
   return httpClient
      .get<UsersType>(`companies/users?showStats=${showStats}`)
      .then((res) => ({ status: res.status, data: res.data }))
      .catch(() => {
         throw new Error('apiErrors.500')
      })
}
