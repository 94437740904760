import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useRemoveNotification } from 'shared/store/useNotifications'
import { NotificationActionsPayload } from 'shared/store/useNotifications/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

interface Props {
   notificationUuid?: string
   handleClose: () => void
}

export default function DeleteNotification({ notificationUuid, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'NOTIFICATIONS'])
   const { mutate, isLoading } = useRemoveNotification()

   function handleRemove() {
      if (notificationUuid) {
         const payload: NotificationActionsPayload = { uuid: notificationUuid }
         mutate(payload, {
            onSettled: handleClose,
         })
      }
   }

   return (
      <div>
         <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
            {t('NOTIFICATIONS:deleteText')}
         </Typography>

         <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton disabled={isLoading} variant="contained" onClick={handleRemove}>
               {t('TRANSLATION:delete')}
            </ContainedButton>
         </Box>
      </div>
   )
}
