/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'
import i18n from 'locales/i18n'

import { httpClient } from 'shared/lib'
import newIcon from 'assets/svg/new.svg'

import { ImportantIcon, StyledCheck } from '../../styles'
import { NewsType } from '../../types'

export default function Features() {
   const { t } = useTranslation('HOMEPAGE')
   const [news, setNews] = useState<NewsType>()
   const [newsFailed, setNewsFailed] = useState(false)

   useEffect(() => {
      httpClient
         .get<NewsType>('/news/')
         .then((res) => {
            setNews(res.data)
         })
         .catch(() => setNewsFailed(true))
   }, [])

   return (
      <Grow in timeout={1800}>
         <Card sx={{ padding: 2, px: 5, overflowY: 'auto', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
               <Typography variant="h5" color="primary">
                  {t('news')}
               </Typography>
               <img height={50} width="auto" src={newIcon} alt="newIcon" />
            </Box>
            {news && !newsFailed ? (
               <Box sx={{ ml: 2, mt: 1 }}>
                  {news[i18n.language]?.map((item) =>
                     item.includes('IMPORTANT') ? (
                        <p style={{ color: 'red', fontWeight: 'bold' }} key={item}>
                           <ImportantIcon /> {item.replace('IMPORTANT ', '')}
                        </p>
                     ) : (
                        <p key={item}>
                           <StyledCheck /> {item}
                        </p>
                     )
                  )}
               </Box>
            ) : (
               <Typography sx={{ mt: 2 }} variant="body2">
                  {t('loadingFailed')}
               </Typography>
            )}
         </Card>
      </Grow>
   )
}
