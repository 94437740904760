import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { StyledCard, StyledImg } from './styles'
import { ModalType } from '../../types'

interface Props {
   handleModalType: (type: ModalType) => void
}

export default function NonCoopCard({ handleModalType }: Props) {
   const { user } = useLogin()
   const { t } = useTranslation('WHOLESALERS')

   function handleOnClick() {
      if (user.roles.includes(UserRoles.ULTRA)) {
         handleModalType('new')
      }
   }

   return (
      <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
         <StyledCard onClick={handleOnClick}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, textAlign: 'center' }}>
               <StyledImg src={`${process.env.REACT_APP_FILES_URL}/img/integrations/baselinker.png`} alt="Baselinker" />
               {!user.roles.includes(UserRoles.ULTRA) && (
                  <Typography variant="body1" color="red" fontWeight="bolder">
                     {t('baselinker.onlyInUltra')}
                  </Typography>
               )}
            </Box>
         </StyledCard>
      </Grid>
   )
}
