import { styled } from '@mui/material'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'

export const StyledBox = styled(Card)(() => ({
   padding: 10,
   width: '100%',
   marginTop: 2,
   backgroundColor: 'rgba(255,255,255,0.7)',
   '&:hover': {
      backgroundColor: 'rgba(106,174,217,0.7)',
      cursor: 'pointer',
   },
}))

export const StyledTypography = styled(Typography)(() => ({
   textTransform: 'uppercase',
   color: 'GrayText',
}))

export const StyledDeleteIcon = styled(DeleteIcon)(() => ({
   fontSize: 30,
   '&:hover': {
      color: 'red',
      cursor: 'pointer',
   },
}))
