import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { Base64 } from 'js-base64'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import { httpClient } from 'shared/lib'

import ProducersSlider from '../Homepage/components/ProducersSlider'
import { newPasswordService } from './services'
import { resetPasswordSchema } from './schema'
import { pl, en, de } from './locales'

const StyledBox = styled(Box)({
   width: 500,
   position: 'relative',
   margin: 'auto',
})

export default function ResetPassword() {
   const { t, i18n } = useTranslation(['RESET_PASSWORD', 'TRANSLATION'])
   i18n.addResourceBundle('pl-PL', 'RESET_PASSWORD', pl)
   i18n.addResourceBundle('en-EN', 'RESET_PASSWORD', en)
   i18n.addResourceBundle('de-DE', 'RESET_PASSWORD', de)

   const { addNotification } = useNotificationContext()
   const [searchParams] = useSearchParams()
   const navigate = useNavigate()
   const formik = useFormik<ResetPasswordInitialValues>({
      initialValues: {
         password: '',
         confirmPassword: '',
      },
      validationSchema: resetPasswordSchema,
      onSubmit: handleSubmit,
   })
   const { values } = formik
   const token = searchParams.get('token') || ''

   function handleSubmit() {
      const payload: NewPasswordPayloadType = {
         password: Base64.encode(values.password),
         confirmPassword: Base64.encode(values.confirmPassword),
         resetPasswordToken: token,
      }

      newPasswordService(token, payload)
         .then((res) => {
            if (res.status === 200) {
               addNotification(t('RESET_PASSWORD:successSave'), 'success')
               setTimeout(() => navigate('/'), 3000)
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
   }

   useEffect(() => {
      if (token) {
         httpClient
            .get(`/users/reset-password/${token}`)
            .then((res) => {
               if (!res.data.valid) {
                  navigate('/')
               }
            })
            .catch(() => navigate('/'))
      } else {
         navigate('/')
      }
   }, [token])

   return (
      <>
         <Container
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               alignContent: 'center',
               height: 'calc(100vh - 309px)',
            }}
         >
            <StyledBox>
               <FormikProvider value={formik}>
                  <Typography sx={{ marginBottom: 3 }} variant="h4">
                     {t('RESET_PASSWORD:changePassword')}
                  </Typography>
                  <Form>
                     <Stack spacing={3} sx={{ marginBottom: 5 }}>
                        <InputField<ResetPasswordInitialValues>
                           type="password"
                           name="password"
                           required
                           label={t('TRANSLATION:formFields.password')}
                        />
                        <InputField<ResetPasswordInitialValues>
                           type="password"
                           name="confirmPassword"
                           required
                           label={t('TRANSLATION:formFields.confirmPassword')}
                        />
                        <ContainedButton type="submit" variant="contained">
                           {t('RESET_PASSWORD:changePassword')}
                        </ContainedButton>
                     </Stack>
                  </Form>
               </FormikProvider>
            </StyledBox>
         </Container>
         <ProducersSlider />
      </>
   )
}
