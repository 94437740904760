import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { SettingsResponseType } from './types'

export function fetchSettingsData(): Promise<SettingsResponseType> {
   let errMessage = ''
   return httpClient
      .get('user/settings')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SETTINGS fetchSettingsData service', errMessage, 'error')
         throw error
      })
}

export function saveSettingsData(payload: SettingsType) {
   let errMessage = ''
   return httpClient
      .put<Result>('user/settings', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SETTINGS saveSettingsData service', errMessage, 'error')
         throw error
      })
}
