import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import Card from '@mui/material/Card'

export const GrayTypography = styled(Typography)({
   color: 'GrayText',
   fontWeight: 'bold',
})

export const FlexCard = styled(Card)({
   flexGrow: 1,
   padding: 30,
})

export const SmallSpan = styled('span')({
   fontWeight: 'normal',
})
