import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import React from 'react'

import { LocationType } from 'shared/store/useLocations/types'

interface Props {
   values?: LocationType
}

export default function UserForm({ values }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])

   return (
      <Grid
         sx={{
            pb: 2,
            px: 6,
            minHeight: 400,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
         }}
         item
         xs={12}
         md={10}
      >
         {values && (
            <>
               <Typography variant="h6" color="primary" sx={{ pt: 3 }}>
                  {t('LOCATIONS:tabsLabels.locationData')}
               </Typography>
               <Stack sx={{ pl: 2, pt: 3 }} direction="column" spacing={{ xs: 2, md: 3 }}>
                  <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
                     <TextField
                        value={values.locationSettings.name}
                        disabled
                        required
                        fullWidth
                        label={t('TRANSLATION:formFields.locationName')}
                     />
                     <TextField
                        value={values.locationSettings.address.country}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.country')}
                     />
                  </Stack>
                  <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
                     <TextField
                        fullWidth
                        value={values.locationSettings.address.voivodeship}
                        disabled
                        label={t('TRANSLATION:formFields.voivodeship')}
                     />
                     <TextField
                        fullWidth
                        value={values.locationSettings.address.county}
                        disabled
                        label={t('TRANSLATION:formFields.county')}
                     />
                     <TextField
                        value={values.locationSettings.address.city}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.city')}
                     />
                  </Stack>
                  <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
                     <TextField
                        value={values.locationSettings.address.zipCode}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.postCode')}
                     />
                     <TextField
                        value={values.locationSettings.address.street}
                        disabled
                        fullWidth
                        label={t('TRANSLATION:formFields.street')}
                        required
                     />
                     <TextField
                        value={values.locationSettings.address.houseNumber}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.houseNumber')}
                     />
                  </Stack>
               </Stack>
               <Typography variant="h6" color="primary" sx={{ py: 3 }}>
                  {t('LOCATIONS:shippingData')}
               </Typography>
               <Stack sx={{ pl: 2 }} direction={{ md: 'row', sm: 'column', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
                  <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
                     <TextField
                        value={values.locationSettings.shippingAddress.city}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.city')}
                     />
                     <TextField
                        value={values.locationSettings.shippingAddress.street}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.street')}
                     />
                  </Stack>
                  <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
                     <TextField
                        value={values.locationSettings.shippingAddress.zipCode}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.postCode')}
                     />
                     <TextField
                        value={values.locationSettings.shippingAddress.houseNumber}
                        disabled
                        fullWidth
                        required
                        label={t('TRANSLATION:formFields.houseNumber')}
                     />
                  </Stack>
               </Stack>
               <Typography variant="h6" color="primary" sx={{ py: 3 }}>
                  {t('LOCATIONS:clientPricingData')}
               </Typography>
               <Stack sx={{ pl: 2 }} direction={{ md: 'row', sm: 'column', xs: 'column' }} spacing={{ xs: 2, md: 3 }}>
                  <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
                     <TextField
                        value={values.locationSettings.orderReflinkSettings?.companyName}
                        disabled
                        label={t('TRANSLATION:formFields.companyName')}
                     />
                     <TextField
                        value={values.locationSettings.orderReflinkSettings?.phoneNumber}
                        disabled
                        label={t('TRANSLATION:formFields.phoneNumber')}
                     />
                  </Stack>
                  <Stack sx={{ width: { md: '50%', xs: '100%' } }} spacing={{ xs: 2, md: 3 }} direction="column">
                     <TextField
                        value={values.locationSettings.orderReflinkSettings?.address}
                        disabled
                        label={t('TRANSLATION:formFields.address')}
                     />
                     <TextField
                        value={values.locationSettings.orderReflinkSettings?.defaultCartDescription}
                        disabled
                        label={t('LOCATIONS:defaultCartDesc')}
                        multiline
                     />
                  </Stack>
               </Stack>
            </>
         )}
      </Grid>
   )
}
