import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material'

import ContainedButton from 'shared/components/Buttons/ContainedButton'

import { NotificationInfo } from '../../../../types'

type Props = {
   modalInfo?: NotificationInfo
   handleClose: () => void
}

const StyledLink = styled('a')(({ theme }) => ({
   fontWeight: 'bold',
   textDecoration: 'none',
   color: theme.palette.primary.dark,
   '&:hover': {
      color: theme.palette.primary.main,
   },
}))

export default function PaymentNotification({ modalInfo, handleClose }: Props) {
   const { t } = useTranslation('NOTIFICATIONS')

   return (
      <Stack direction="column" spacing={2} sx={{ minWidth: 500, padding: 1 }}>
         {modalInfo?.message && (
            <>
               <Typography variant="body1">{t('paymentContent')}</Typography>
               <StyledLink target="_blank" href={modalInfo.message}>
                  {t('paymentLink')}
               </StyledLink>
            </>
         )}
         <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <ContainedButton variant="contained" onClick={handleClose}>
               OK
            </ContainedButton>
         </div>
      </Stack>
   )
}
