/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FieldArray, FormikProvider, useFormik } from 'formik'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'

import { useDeliveryTimesMutation, useDeliveryTimesQuery } from 'shared/store/useWholesalers'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import SelectField from 'shared/components/SelectField'
import InputField from 'shared/components/InputField'

import schema from './schema'

type TimeRecord = {
   time: string
   weekDay: 'MONDAY_FRIDAY' | 'SATURDAY'
}

type InitialFormValues = {
   times: TimeRecord[]
}

interface Props {
   cooperationUuid: string
   locationUuid: string
   closeModal: () => void
}

const initialValues: InitialFormValues = {
   times: [
      {
         time: '',
         weekDay: 'MONDAY_FRIDAY',
      },
   ],
}

export default function EditTimesModal({ cooperationUuid, locationUuid, closeModal }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])
   const { data } = useDeliveryTimesQuery(cooperationUuid, locationUuid)
   const { mutate, isLoading } = useDeliveryTimesMutation()
   const formik = useFormik<InitialFormValues>({
      initialValues,
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { values, setValues } = formik

   async function handleSubmit(val: InitialFormValues) {
      const { times } = val
      const deliveryTimes = times.map((item) => {
         const splitedTime = item.time.split(':')
         return {
            deliveryDays: item.weekDay,
            deliveryHour: {
               hour: Number(splitedTime[0]),
               minute: Number(splitedTime[1]),
            },
         }
      })

      if (cooperationUuid) {
         mutate(
            { cooperationUuid, locationUuid, deliveryTimes },
            {
               onSuccess: closeModal,
            }
         )
      }
   }

   const handleRemoveElement = useCallback(
      (timeIndex: number) => {
         const newValues = values.times.filter((_, index) => index !== timeIndex)
         setValues({ times: newValues })
      },
      [values]
   )

   useEffect(() => {
      if (data) {
         const newTimes: InitialFormValues = {
            times: data.deliveryTimes.map(({ deliveryDays, deliveryHour }) => {
               const parsedHours = deliveryHour.hour < 10 ? `0${deliveryHour.hour}` : deliveryHour.hour
               const parsedMinutes = deliveryHour.minute < 10 ? `0${deliveryHour.minute}` : deliveryHour.minute
               const time = `${parsedHours}:${parsedMinutes}`
               return {
                  time,
                  weekDay: deliveryDays,
               }
            }),
         }
         setValues(newTimes)
      }
   }, [data])

   return (
      <div>
         <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
            {t('WHOLESALERS:editTimesDialog')}
         </Typography>
         <div style={{ marginTop: 30 }}>
            <FormikProvider value={formik}>
               <Form>
                  <FieldArray name="times">
                     {({ push }) => (
                        <div>
                           {values.times.length > 0 &&
                              values.times.map((_, index) => (
                                 <Stack key={`timeRecord_${index}`} direction="column" spacing={5} sx={{ mb: 2 }}>
                                    <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
                                       <InputField sx={{ maxWidth: 100 }} name={`times.${index}.time`} type="time" />
                                       <SelectField
                                          sx={{ maxWidth: 100 }}
                                          label={t('WHOLESALERS:deliveryDays')}
                                          name={`times.${index}.weekDay`}
                                          options={[
                                             {
                                                value: 'MONDAY_FRIDAY',
                                                name: t('WHOLESALERS:mondayFriday'),
                                             },
                                             {
                                                value: 'SATURDAY',
                                                name: t('WHOLESALERS:saturday'),
                                             },
                                          ]}
                                       />

                                       <Tooltip
                                          disableHoverListener={values.times.length === 1}
                                          title={t('WHOLESALERS:deleteTooltip') || ''}
                                       >
                                          <IconButton
                                             sx={{ width: 50, height: 50, paddingTop: 2 }}
                                             onClick={() => handleRemoveElement(index)}
                                          >
                                             <DeleteIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </Stack>
                                 </Stack>
                              ))}
                           <div style={{ width: '50%', textAlign: 'center', margin: '0 auto' }}>
                              <Tooltip title={t('WHOLESALERS:addTooltip') || ''}>
                                 <IconButton
                                    disabled={values.times.length === 20}
                                    sx={{ border: '0.5px solid gray', marginBottom: 2 }}
                                    onClick={() => {
                                       if (values.times.length < 20) {
                                          push({ time: '', weekDay: '' })
                                       }
                                    }}
                                 >
                                    <AddIcon
                                       sx={{ fontSize: 36 }}
                                       color={values.times.length === 20 ? 'inherit' : 'primary'}
                                    />
                                 </IconButton>
                              </Tooltip>
                              {values.times.length === 20 && (
                                 <Alert severity="error" sx={{ mb: 3 }}>
                                    {t('WHOLESALERS:maxEditTimesError')}
                                 </Alert>
                              )}
                           </div>
                        </div>
                     )}
                  </FieldArray>
                  <Box sx={{ display: 'flex', gap: 1, pb: 1, float: 'right' }}>
                     <OutlinedButton variant="outlined" onClick={closeModal}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton disabled={isLoading} variant="contained" type="submit">
                        {t('TRANSLATION:save')}
                     </ContainedButton>
                  </Box>
               </Form>
            </FormikProvider>
         </div>
      </div>
   )
}
