import { styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'

export const StyledTable = styled(Table)({
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   maxWidth: '80%',
   margin: '0 auto',
   marginTop: 10,
   color: 'grayText',
})

export const StyledBox = styled(Box)({
   display: 'flex',
   flexDirection: 'column',
   gap: 3,
   margin: '0 auto',
   paddingTop: 50,
})

export const PayBox = styled(Box)({
   position: 'relative',
   alignItems: 'center',
   zIndex: 10,
   top: -150,
   display: 'flex',
   margin: '0 auto',
   width: '70%',
   minHeight: 300,
})

export const ButtonWrapper = styled('div')({
   display: 'flex',
   justifyContent: 'flex-end',
   marginTop: 20,
   marginBottom: 20,
   width: '90%',
})

export const StyledTableCell = styled(TableCell)({
   textAlign: 'center',
   color: 'grayText',
})

export const StyledContentTableCell = styled(TableCell)({
   padding: '4px 6px 4px 6px',
   textAlign: 'center',
})

export const ProductTableRow = styled(TableRow)({
   background: 'white',
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.05)',
      cursor: 'pointer',
   },
})

export const StyledSpan = styled('span')(({ theme }) => ({
   marginLeft: 5,
   color: theme.palette.primary.dark,
   fontSize: 17,
   fontWeight: 'bolder',
}))

export const StyledAvailabilityDot = styled('div')(({ color }) => ({
   width: 15,
   margin: '0 auto',
   height: 15,
   borderRadius: 50,
   backgroundColor: color,
}))
