import { Yup } from 'shared/lib'

const validPostCode = /^(?:[A-Za-z]{2})?(?:\d{5}|\d{6}|\d{2}-\d{3}|\d{3}\s?\d{2})$/
const validCity =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž",-][.]?[\s]?)*([^\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž",-]\.?)$/

export const addressSchema = Yup.object().shape({
   street: Yup.string()
      .max(100)
      .when('deliveryType', {
         is: (value: string) => value === 'ADDRESS',
         then: (schema) => schema.required(),
      }),
   houseNumber: Yup.string()
      .max(15)
      .when('deliveryType', {
         is: (value: string) => value === 'ADDRESS',
         then: (schema) => schema.required(),
      }),
   zipCode: Yup.string()
      .matches(validPostCode, 'validation.postCodePattern')
      .when('deliveryType', {
         is: (value: string) => value === 'ADDRESS',
         then: (schema) => schema.required(),
      }),
   city: Yup.string()
      .matches(validCity, 'validation.cityPattern')
      .when('deliveryType', {
         is: (value: string) => value === 'ADDRESS',
         then: (schema) => schema.required(),
      }),
   country: Yup.string().when('deliveryAddress', {
      is: (value: string) => value === 'ADDRESS',
      then: (schema) => schema.required(),
   }),
   deliveryType: Yup.string().oneOf(['PICK_UP', 'ADDRESS']),
})
