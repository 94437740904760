import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useSetActiveCartMutation } from 'shared/store/useSavedCarts'

import { FormType } from '../../../../types'

interface Props {
   handleClose: () => void
   cartUuid?: string
   orderType?: FormType
}

export default function OversizeOrderNotification({ handleClose, cartUuid, orderType }: Props) {
   const { mutate } = useSetActiveCartMutation()
   const navigate = useNavigate()
   const { t } = useTranslation('NOTIFICATIONS')

   function renderContent() {
      if (orderType === 'ORDER_CREATED') {
         if (cartUuid) {
            return <Typography variant="body1">{t('orderedWithProblems')}</Typography>
         }
         return <Typography variant="body1">{t('ordered')}</Typography>
      }
      return <Typography variant="body1">{t('notOrdered')}</Typography>
   }

   function handleForward() {
      if (cartUuid) {
         mutate(cartUuid, {
            onSuccess: () => navigate('/dashboard/main-cart'),
         })
      }
   }

   return (
      <Stack direction="column" spacing={2} sx={{ minWidth: 500, padding: 1 }}>
         {renderContent()}
         <div style={{ display: 'flex', justifyContent: 'center', gap: 3, marginTop: 20 }}>
            {cartUuid && (
               <ContainedButton variant="contained" onClick={handleForward}>
                  {t('goToCart')}
               </ContainedButton>
            )}
            <ContainedButton variant="contained" onClick={handleClose}>
               OK
            </ContainedButton>
         </div>
      </Stack>
   )
}
