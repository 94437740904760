import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { useTranslation } from 'react-i18next'
import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'
import React from 'react'

import { useStatisticsQuery } from 'shared/store/useStatistics'
import BannersSlider from 'shared/components/BannersSlider'

import PointsAndSavedTime from './components/PointsAndSavedTime'
import ShoppingChart from './components/ShoppingChart'
import ActiveCart from './components/ActiveCart'
import Features from './components/Features'
import { en, pl, de } from './locales'
import { Grid } from './styles'

export default function Homepage(): JSX.Element {
   const { i18n } = useTranslation()
   const { data, isLoading, isError } = useStatisticsQuery()

   i18n.addResourceBundle('pl-PL', 'HOMEPAGE', pl)
   i18n.addResourceBundle('en-EN', 'HOMEPAGE', en)
   i18n.addResourceBundle('de-DE', 'HOMEPAGE', de)

   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
         }}
      >
         {data && !isLoading ? (
            <>
               <Box
                  sx={{
                     display: 'flex',
                     gap: '25px',
                     width: '100%',
                     mb: '25px',
                     flexDirection: { lg: 'row', md: 'column', xs: 'column' },
                     minHeight: { lg: 350 },
                  }}
               >
                  <ActiveCart />
                  <Grow in timeout={1000}>
                     <Box>
                        <BannersSlider variant="HOMEPAGE" />
                     </Box>
                  </Grow>
               </Box>
               <Grid>
                  <Box maxHeight={300} gridColumn={{ lg: '1/ span 8', md: '1 / span 8', xs: '1 / span 8' }} gridRow="1">
                     <ShoppingChart
                        formattedCurrency={data.spentMoney.formattedCurrency}
                        chartData={data.spentMoney}
                        statsFailed={isError}
                     />
                  </Box>
                  <Box gridColumn={{ lg: '1/4', md: '1/9', xs: '1/9' }} gridRow="2">
                     <PointsAndSavedTime savedTime={data.savedTime} statsFailed={isError} />
                  </Box>
                  <Box gridColumn={{ lg: '4/9', md: '1/9', xs: '1/9' }} gridRow={{ lg: '2', md: '3', xs: '3' }}>
                     <Features />
                  </Box>
               </Grid>
            </>
         ) : (
            <CircularProgress />
         )}
      </Box>
   )
}
