import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

import { locales } from 'config/languages'
import { ReactComponent as EnIcon } from 'assets/svg/en-EN.svg'
import { ReactComponent as PlIcon } from 'assets/svg/pl-PL.svg'
import { ReactComponent as DeIcon } from 'assets/svg/de-DE.svg'
import MenuPopover from 'shared/components/MenuPopover'

function getLanguageName(lang: string) {
   switch (lang) {
      case 'en-EN':
         return 'English'
      case 'pl-PL':
         return 'Polski'
      case 'de-DE':
         return 'Deutsch'
      default:
         return lang
   }
}

function getLanguageIcon(lang?: string) {
   switch (lang) {
      case 'en-EN':
         return <EnIcon />
      case 'pl-PL':
         return <PlIcon />
      case 'de-DE':
         return <DeIcon />
      default:
         return null
   }
}

export default function UnauthorizedLanguagePopover() {
   const anchorRef = useRef(null)
   const { i18n } = useTranslation()
   const [open, setOpen] = useState(false)

   function onChangeLang(lang: string) {
      i18n.changeLanguage(lang)
      window.localStorage.setItem('prefLang', lang)
      setOpen(false)
   }

   function handleOpenMenu() {
      setOpen(true)
   }

   function handleCloseMenu() {
      setOpen(false)
   }

   useEffect(() => {
      const prefLang = window.localStorage.getItem('prefLang')
      if (prefLang) {
         onChangeLang(prefLang)
      }
   }, [i18n.language, window.localStorage])

   return (
      <>
         <IconButton
            ref={anchorRef}
            onClick={handleOpenMenu}
            sx={{
               padding: 0,
            }}
         >
            {getLanguageIcon(i18n.language)}
         </IconButton>

         <MenuPopover
            open={open}
            onClose={handleCloseMenu}
            anchorEl={anchorRef.current}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
         >
            <Box sx={{ py: 1 }}>
               {locales?.map((lang) => (
                  <MenuItem
                     selected={lang.prefix === i18n.language}
                     key={lang.prefix}
                     onClick={() => onChangeLang(lang.prefix)}
                     sx={{ py: 1, px: 2.5 }}
                  >
                     <ListItemIcon sx={{ maxWidth: 25, maxHeight: 25 }}>{getLanguageIcon(lang.prefix)}</ListItemIcon>

                     <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                        {getLanguageName(lang.name)}
                     </ListItemText>
                  </MenuItem>
               ))}
            </Box>
         </MenuPopover>
      </>
   )
}
