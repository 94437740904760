import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import StarIcon from '@mui/icons-material/Star'
import Rating from '@mui/material/Rating'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'

export const Container = styled(Box)({
   padding: 5,
})

export const ProductDetailsContainer = styled(Box)(({ theme }) => ({
   display: 'flex',
   paddingTop: 17,
   justifyContent: 'space-between',
   [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
   },
   paddingBottom: 40,
   borderTop: '2px solid #A9A9A9',
}))

export const FullStar = styled(StarIcon)(({ theme }) => ({
   color: theme.palette.primary.dark,
}))

export const CenteredTableCell = styled(TableCell)({
   textAlign: 'center',
   padding: '6px 4px 6px 4px',
})

export const EmptyStar = styled(StarIcon)({
   color: '#A9A9A9',
})

export const StyledRating = styled(Rating)({
   marginTop: '-2px',
})

export const StyledImage = styled('img')(({ theme }) => ({
   transition: '500ms',
   '&:hover': {
      boxShadow: `0px 0px 20px -3px ${theme.palette.primary.dark}`,
      cursor: 'pointer',
   },
}))

export const TabsContainer = styled(Box)(({ theme }) => ({
   backgroundColor: 'rgba(245, 245, 245, 0.8)',
   minHeight: 196,
   [theme.breakpoints.up('lg')]: {
      marginTop: -16,
      width: '60%',
   },
   [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      width: '100%',
   },
   padding: '20px 40px 20px 40px',
   height: '100%',
}))

export const DetailedRatingContainer = styled(Box)({
   display: 'flex',
   gap: 1,
   flexDirection: 'column',
})

export const InformationsContainer = styled(Box)({
   display: 'flex',
   flexDirection: 'column',
   gap: 10,
   paddingTop: 15,
   paddingLeft: 15,
   fontSize: 14,
})

export const GrayTypography = styled(Box)({
   display: 'flex',
   gap: 4,
   color: '#A9A9A9',
   fontSize: 'inherit',
})

export const GrayTooltipTypography = styled(Typography)({
   display: 'flex',
   justifyContent: 'space-between',
   gap: 4,
   color: '#A9A9A9',
   fontSize: 'inherit',
})

export const BlackTypography = styled(Typography)({
   color: 'black',
   textTransform: 'uppercase',
   fontSize: 'inherit',
})

export const StyledCenteredContent = styled('div')({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
})

export const ButtonContainer = styled('div')({
   display: 'flex',
   gap: 12,
   justifyContent: 'center',
})

export const StyledTableWrapper = styled('div')({
   '.MuiTableCell-root': {
      padding: '6px 8px 6px 8px',
      textAlign: 'center',
   },
   '.MuiTableCell-head': {
      backgroundColor: 'rgba(33,147,224, 0.1)',
      color: 'grayText',
   },
})
