export default [
   {
      subscription: 'BASIC',
      lists: [
         { text: 'basic.text' },
         { text: 'connectText' },
         { text: 'implementationText' },
         { text: 'technicalText' },
      ],
      labelAction: 'buyButton',
      lastPrice: '119.20 zł',
   },
   {
      subscription: 'PRO',
      lists: [
         { text: 'pro.text' },
         { text: 'connectText' },
         { text: 'csvText' },
         { text: 'clientsCartsText' },
         { text: 'implementationText' },
         { text: 'technicalText' },
      ],
      labelAction: 'buyButton',
      lastPrice: '248 zł',
   },
   {
      subscription: 'ULTRA',
      lists: [
         { text: 'ultra.text1' },
         { text: 'ultra.text2' },
         { text: 'baselinkerIntegration' },
         { text: 'connectText' },
         { text: 'csvText' },
         { text: 'clientsCartsText' },
         { text: 'implementationText' },
         { text: 'technicalText' },
      ],
      labelAction: 'buyButton',
      lastPrice: '448 zł',
   },
]
