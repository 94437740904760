import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'

import { reportMissingProductService } from 'shared/services/reportMissingProduct'
import { useNotificationContext } from 'shared/context/Notifications'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import GrayButton from 'shared/components/Buttons/GrayButton'
import SalerLogo from 'shared/components/SalerLogo'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { CenteredTableCell, ButtonContainer } from '../../styles'
import AddWholesalerModal from '../AddWholesalerModal'
import { AddModal } from '../../types'

interface Props {
   data: CooperatingWholesaler[]
   productID: Nullable<string>
}

export default function NoProductTable({ data, productID }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const [isLoading, setIsLoading] = useState(false)
   const { addNotification } = useNotificationContext()
   const [modal, setModal] = useState<AddModal>()
   const { user } = useLogin()

   async function onReportMissingProduct(payload: MissingProductsService) {
      if (productID) {
         setIsLoading(true)
         await reportMissingProductService({
            productUuid: payload.productUuid,
            wholesalerUuid: payload.wholesalerUuid,
         })
            .then(({ status }) => {
               if (status === 201) {
                  addNotification('productReported', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   return data.length ? (
      <Box sx={{ mt: 3 }}>
         <Typography variant="body1" sx={{ ml: 1, opacity: 0.5 }}>
            {t('PRODUCT_DETAILS:tables.noProductHeading')}
         </Typography>
         <RadiusTableWrapper>
            <Table
               sx={{
                  border: '1px solid rgba(224, 224, 224, 1)',
                  width: 700,
               }}
            >
               <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <TableRow>
                     <CenteredTableCell>
                        <Typography variant="body1" sx={{ opacity: 0.5 }}>
                           {t('TRANSLATION:columnNames.wholesaler')}
                        </Typography>
                     </CenteredTableCell>
                     <TableCell colSpan={3} />
                  </TableRow>
               </TableHead>
               <TableBody>
                  {data.map((item) => (
                     <TableRow key={item.wholesaleUuid}>
                        <CenteredTableCell>
                           <SalerLogo value={item.wholesaleName} />
                        </CenteredTableCell>
                        <TableCell />
                        <TableCell />
                        <CenteredTableCell>
                           <ButtonContainer>
                              <Tooltip placement="right" title={t('PRODUCT_DETAILS:tables.sendReportTooltip') || ''}>
                                 <GrayButton
                                    sx={{ marginX: 4 }}
                                    fullWidth
                                    variant="outlined"
                                    disabled={isLoading}
                                    type="button"
                                    onClick={() =>
                                       onReportMissingProduct({
                                          wholesalerUuid: item.wholesaleUuid,
                                          productUuid: item.productUuid,
                                       })
                                    }
                                 >
                                    {t('PRODUCT_DETAILS:tables.reportButton')}
                                 </GrayButton>
                              </Tooltip>
                              <IconButton onClick={() => setModal({ data: item, productUuid: productID || '' })}>
                                 <AddCircleIcon />
                              </IconButton>
                           </ButtonContainer>
                        </CenteredTableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </RadiusTableWrapper>
         {user.roles.includes(UserRoles.DATA_MANAGER) && (
            <AddWholesalerModal modalInfo={modal} handleClose={() => setModal(undefined)} />
         )}
      </Box>
   ) : null
}
