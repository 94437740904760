import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

import { WholesalerType } from 'shared/store/useWholesalers/types'

import { StyledCard, EditCorner, StyledImg, RejectedCorner } from './styles'
import { WholesalerActionType } from '../../types'

type Props = {
   activeWholesaler: WholesalerType
   handleWholesaler: (wholesalerId: string, type: WholesalerActionType) => void
}

export default function RejectedWholesalerCard({ activeWholesaler, handleWholesaler }: Props) {
   const { t } = useTranslation('WHOLESALERS')

   return (
      <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
         <Tooltip title={t('wholesaleRejectedTooltip') || ''}>
            <StyledCard>
               <RejectedCorner>
                  <CloseIcon sx={{ color: 'red', mt: '3px', ml: '4px' }} />
               </RejectedCorner>
               <EditCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'addWholesaler')}>
                  <EditIcon />
               </EditCorner>
               <StyledImg
                  src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${activeWholesaler.wholesaleName}.png`}
                  alt={activeWholesaler.wholesaleName}
               />
            </StyledCard>
         </Tooltip>
      </Grid>
   )
}
