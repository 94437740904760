import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Slider from 'react-slick'

export const Wrapper = styled(Card)({
   position: 'relative',
   width: '100%',
   height: '100%',
   maxHeight: 400,
})

export const CenteredSlider = styled(Slider)(({ theme }) => ({
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   maxHeight: 350,
   width: '860px', // Dopasuj szerokość do potrzeb
   height: 'auto', // Ustaw na 'auto' lub dopasuj do potrzeb
   '& .slick-slider': {
      width: '100%',
      height: '100%',
      maxHeight: 350,
   },
   '& .slick-list, & .slick-track, & .slick-slide, & .slick-slide img': {
      width: '100%',
      height: '100%',
      maxHeight: 350,
   },
   '& .slick-dots': {
      bottom: '10px',
   },
   '& .slick-prev, & .slick-next': {
      top: '50%',
   },
   '& .slick-prev': {
      left: '10px',
   },
   '& .slick-next': {
      right: '10px',
   },
   '& .slick-prev, & .slick-next, & .slick-prev:hover, & .slick-next:hover': {
      zIndex: 1,
   },
   '& .slick-prev:before, & .slick-next:before': {
      fontSize: '24px',
   },
   '& .slick-prev:before': {
      content: '"❮"',
   },
   '& .slick-next:before': {
      content: '"❯"',
   },
   '& .slick-dots li button': {
      width: '12px',
      height: '12px',
      fontSize: '10px',
      borderRadius: '100%',
      border: 'none',
      backgroundColor: theme.palette.primary.main,
      lineHeight: 1,
      margin: '0 5px',
      cursor: 'pointer',
   },
   '& .slick-dots li.slick-active button': {
      backgroundColor: theme.palette.primary.dark,
   },
}))

export const SliderPagination = styled('ul')(({ theme }) => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'center',
   padding: 0,
   margin: 0,
   position: 'absolute',
   left: '50%',
   transform: 'translateX(-50%)',
   bottom: '20px',
   listStyle: 'none',
   '& > li.slick-active button': {
      backgroundColor: theme.palette.primary.dark,
   },
}))

export const LoginSliderPagination = styled('ul')(({ theme }) => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'center',
   padding: 0,
   margin: 0,
   position: 'absolute',
   left: '50%',
   transform: 'translateX(-50%)',
   bottom: '20px',
   listStyle: 'none',
   '& > li.slick-active button': {
      backgroundColor: theme.palette.primary.dark,
   },
}))

export const SliderDot = styled('button')(({ theme }) => ({
   width: 12,
   height: 12,
   fontSize: 10,
   borderRadius: '100%',
   border: 'none',
   backgroundColor: theme.palette.primary.main,
   lineHeight: 1,
   margin: '0 5px',
   cursor: 'pointer',
}))
