import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useTranslation } from 'react-i18next'

interface Props {
   openModal: boolean
   handleClose: () => void
   handleReject: () => void
}

export default function ConfirmRejectModal({ openModal, handleClose, handleReject }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'COOPERATION_REFLINK'])

   return (
      <Dialog open={openModal}>
         <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography variant="h4" color="primary">
               {t('COOPERATION_REFLINK:confirmReject')}
            </Typography>
         </DialogTitle>
         <DialogActions sx={{ justifyContent: 'center' }}>
            <ContainedButton variant="contained" onClick={handleClose}>
               {t('TRANSLATION:no')}
            </ContainedButton>
            <ContainedButton variant="contained" onClick={handleReject}>
               {t('TRANSLATION:yes')}
            </ContainedButton>
         </DialogActions>
      </Dialog>
   )
}
