import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { BuyNowServicePayload, EstablishCoopService, ProductDetails } from './types'

export async function fetchProductDetails(productId: string) {
   let errMessage = ''
   return httpClient
      .get<ProductDetails>(`products/${productId}`)
      .then((request) => {
         if (request.data) {
            return request.data
         }
         throw new Error('There is no data')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_DETAILS fetchProductDetails service', errMessage, 'error')
         throw error
      })
}

export async function buyNowService(payload: BuyNowServicePayload) {
   let errMessage = ''
   return httpClient
      .post<BuyNowSummaryType>('/orders/buy-now', payload)
      .then((res) => res)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_DETAILS buyNow service', errMessage, 'error')
         throw error
      })
}

export async function reportMissingProductService(payload: MissingProductsService) {
   let errMessage = ''
   return httpClient
      .post('/missingProducts/', payload)
      .then((res) => res)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_DETAILS reportMissingProducts service', errMessage, 'error')
         throw error
      })
}

export async function establishCooperationService(locationUuid: string, payload: EstablishCoopService) {
   let errMessage = ''
   return httpClient
      .post(`location/${locationUuid}/cooperation/establish/`, payload)
      .then((res) => res)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_DETAILS establishCooperation service', errMessage, 'error')
         throw error
      })
}

export async function addToWatchlistService(productID: string) {
   let errMessage = ''
   return httpClient
      .post(`/products/observed/anyWholesale?productUuid=${productID}`)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_DETAILS addToWatchlist service', errMessage, 'error')
         throw error
      })
}
