import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { StandardTextFieldProps } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'

interface Props extends StandardTextFieldProps {
   name: string
   label: string
}

function SwitchField({ name, label }: Props) {
   const { t } = useTranslation('TRANSLATION')
   const [fieldProps, meta] = useField(name)
   return (
      <FormControl required error={!!meta.error && meta.touched} component="fieldset" variant="standard">
         <FormControlLabel
            control={<Switch checked={fieldProps.value} onChange={fieldProps.onChange} name={name} />}
            label={label}
         />
         <FormHelperText>{meta.error && meta.touched && t(meta.error)}</FormHelperText>
      </FormControl>
   )
}
export default SwitchField
