import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { Link } from 'react-router-dom'

export function LastStep() {
   const { t } = useTranslation('REGISTER')
   return (
      <Container>
         <Grid justifyContent="center" container sx={{ my: 7 }}>
            <Typography
               variant="h3"
               align="center"
               sx={(theme) => ({
                  color: theme.palette.primary.main,
               })}
            >
               {t('mainSite.summaryTitle')}
            </Typography>
            <Typography align="center" my={3}>
               {t('mainSite.summaryMsg')}
            </Typography>
            <ContainedButton variant="contained" size="large" type="submit">
               <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                  {t('mainSite.loginButton')}
               </Link>
            </ContainedButton>
         </Grid>
      </Container>
   )
}
