import { Yup } from 'shared/lib'

const schema = Yup.object().shape({
   typeMessage: Yup.string()
      .oneOf(['Application of new wholesaler', 'System functionality problems', 'Suggestions'])
      .required(),
   message: Yup.string().required(),
})

export default schema
