import CircularProgress from '@mui/material/CircularProgress'
import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'

import { LocationType } from 'shared/store/useLocations/types'
import { useLocationsQuery } from 'shared/store/useLocations'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { httpClient } from 'shared/lib'

import LocationsSettings from './components/LocationsSettings'
import LocationsList from './components/LocationsList'
import UserForm from './components/UserForm'
import { pl, en, de } from './locales'
import Modal from './components/Modal'
import { RootStyle } from './styles'
import { FormType } from './types'

export default function Locations() {
   const [searchParams, setSearchParams] = useSearchParams()
   const { t, i18n } = useTranslation('LOCATIONS')
   i18n.addResourceBundle('pl-PL', 'LOCATIONS', pl)
   i18n.addResourceBundle('en-EN', 'LOCATIONS', en)
   i18n.addResourceBundle('de-DE', 'LOCATIONS', de)
   const { data } = useLocationsQuery()
   const { user } = useLogin()

   const [activeLocation, setActiveLocation] = useState<LocationType>()
   const [modalType, setModalType] = useState<FormType>()
   const [open, setOpen] = useState(false)

   function openModal(type: FormType) {
      if (!type) return
      setOpen(true)
      setModalType(type)
   }

   const handleOnChangeLocation = (uuid: string) => {
      if (data) {
         const newActiveLocation = data.locations.find((item) => item.locationSettings.uuid === uuid)
         setActiveLocation(newActiveLocation)
         if (newActiveLocation) {
            setSearchParams({ uuid: newActiveLocation.locationSettings.uuid })
         }
      }
   }

   useEffect(() => {
      if (data) {
         if (searchParams.get('uuid')) {
            setActiveLocation(data.locations.find((item) => item.locationSettings.uuid === searchParams.get('uuid')))
         } else {
            setActiveLocation(data.locations[0])
         }
      }
   }, [data, httpClient, searchParams])

   return (
      <>
         <Typography variant="h4" color="primary" sx={{ pb: 3, fontWeight: 'normal' }}>
            {t('LOCATIONS:locationsSettings')}
         </Typography>
         <RootStyle>
            {activeLocation ? (
               <Grid container>
                  <LocationsList
                     active={activeLocation}
                     onChangeLocation={handleOnChangeLocation}
                     list={data?.locations}
                     openModal={() => openModal('newLocation')}
                  />
                  {user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ||
                  user.uuid === activeLocation.locationSettings.adminUuid ? (
                     <LocationsSettings
                        mainLocationUuid={data?.mainLocationUuid || ''}
                        values={activeLocation}
                        roles={user.roles}
                        openDeleteLocationModal={() => openModal('deleteLocation')}
                     />
                  ) : (
                     <UserForm values={activeLocation} />
                  )}
               </Grid>
            ) : (
               <div style={{ width: '100%', textAlign: 'center' }}>
                  <CircularProgress color="primary" />
               </div>
            )}
            <Modal
               open={open}
               locationUuid={activeLocation?.locationSettings.uuid}
               type={modalType}
               country={activeLocation?.locationSettings.address.country}
               handleClose={() => setOpen(false)}
            />
         </RootStyle>
      </>
   )
}
