import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { GetTokenResult, GetSmsApiConfigResult, CreateSmsApiPayload } from '../types'

export function getSmsApiConfigService() {
   let errMessage = ''

   return httpClient
      .get<GetSmsApiConfigResult>('sms-api/')
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SMS API getSmsApiConfigService service', errMessage, 'error')
         throw error
      })
}

export function createSmsApiConfigService(payload: CreateSmsApiPayload) {
   let errMessage = ''
   return httpClient
      .post<GetSmsApiConfigResult>('sms-api/', payload)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SMS API createSmsApiConfigService service', errMessage, 'error')
         throw error
      })
}

export function getSmsApiSpecificConfigService(configUuid: string) {
   let errMessage = ''
   return httpClient
      .get<GetSmsApiConfigResult>(`sms-api/${configUuid}`)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SMS API getSmsApiSpecificConfigService service', errMessage, 'error')
         throw error
      })
}

export function updateSmsApiConfigService(
   configUuid: string,
   token: string,
   categoriesTemplates: { [key: string]: string }
) {
   let errMessage = ''
   return httpClient
      .patch<GetSmsApiConfigResult>(`sms-api/${configUuid}`, { token, categoriesTemplates })
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SMS API updateSmsApiConfigService service', errMessage, 'error')
         throw error
      })
}

export function deleteSmsApiConfigService(configUuid: string) {
   let errMessage = ''
   return httpClient
      .delete<GetTokenResult>(`sms-api/${configUuid}`)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SMS API deleteSmsApiConfigService service', errMessage, 'error')
         throw error
      })
}
