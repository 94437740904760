import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material'
import React from 'react'

import ContainedButton from 'shared/components/Buttons/ContainedButton'

import DeleteReflinkModalContent from './components/DeleteReflinkModalContent'
import AddReflinkModalContent from './components/AddReflinkModalContent'
import { ModalInfoType } from '../../types'
import SmsModal from './components/SmsModalContent'

interface Props {
   handleClose: () => void
   modalInfo: ModalInfoType
}

export default function Modal({ handleClose, modalInfo }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'CLIENTS_CARTS'])
   const theme = useTheme()

   function renderForm() {
      switch (modalInfo.modalType) {
         case 'IN_PROGRESS':
         case 'SENT':
            return (
               <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ContainedButton onClick={handleClose} variant="contained">
                     {t('TRANSLATION:understand')}
                  </ContainedButton>
               </DialogActions>
            )
         case 'CREATE':
            return <AddReflinkModalContent handleClose={handleClose} />
         case 'DELETE':
            return <DeleteReflinkModalContent reflinkUuid={modalInfo.reflinkUuid || ''} handleClose={handleClose} />
         case 'SMS':
            return (
               <SmsModal
                  notificationSentDate={modalInfo.notificationSentDate || null}
                  phone={modalInfo.phoneNumber!}
                  handleClose={handleClose}
                  reflink={modalInfo.reflinkUuid!}
               />
            )
         default:
            return null
      }
   }
   return (
      <Dialog open={modalInfo.open} fullWidth={modalInfo.modalType === 'SMS'} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
               {t(`CLIENTS_CARTS:${modalInfo.modalType}`)}
            </Typography>
         </DialogTitle>
         {renderForm()}
      </Dialog>
   )
}
