/* eslint-disable no-nested-ternary */
import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { UnAuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import CooperationDetailsForm from './components/CooperationDetailsForm/CooperationDetailsForm'
import ReflinkProblemView from './components/ReflinkProblemView.tsx'
import { GrayTypography, FlexCard, SmallSpan } from './styles'
import { ReflinkInfoType, ReflinkProblemType } from './types'
import { getReflinkDetailsService } from './services'
import Footer from '../../components/Footer'
import { pl, en, de } from './locales'

export default function CooperationReflink() {
   const [searchParams] = useSearchParams()
   const { t, i18n } = useTranslation(['TRANSLATION', 'COOPERATION_REFLINK'])
   i18n.addResourceBundle('pl-PL', 'COOPERATION_REFLINK', pl)
   i18n.addResourceBundle('en-EN', 'COOPERATION_REFLINK', en)
   i18n.addResourceBundle('de-DE', 'COOPERATION_REFLINK', de)
   const navigate = useNavigate()
   const [formDetails, setFormDetails] = useState<ReflinkInfoType>()
   const [reflinkStatus, setReflinkStatus] = useState<ReflinkProblemType>('OK')

   useEffect(() => {
      const uuid = searchParams.get('uuid') || ''
      if (uuid === '') {
         navigate('/')
      } else {
         getReflinkDetailsService(uuid)
            .then((res) => {
               setFormDetails(res)
               setReflinkStatus('OK')
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  if (error.response?.data.errors[0].code === 'errors.cooperation.reflink-not-found') {
                     setReflinkStatus('NOT_FOUND')
                  }
                  if (error.response?.data.errors[0].code === 'errors.cooperation.reflink-expired') {
                     setReflinkStatus('EXPIRED')
                  } else if (error.response?.data.errors[0].code === 'errors.cooperation.reflink-used') {
                     setReflinkStatus('USED')
                  } else if (error.response?.data.errors[0].code === 'errors.cooperation.reflink-deleted') {
                     setReflinkStatus('DELETED')
                  }
               }
            })
      }
   }, [window.location.href])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
         <Container style={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <LanguagePopover />
         </Container>
         {reflinkStatus !== 'OK' ? (
            <ReflinkProblemView problemType={reflinkStatus} />
         ) : formDetails ? (
            <Box sx={{ minHeight: 'calc(100vh - 103px)' }}>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     marginTop: 10,
                  }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        justifyContent: { md: 'space-between' },
                        gap: 5,
                        mx: 5,
                     }}
                  >
                     <FlexCard>
                        <Typography sx={{ pb: 1 }} color="primary" variant="h5">
                           {t('COOPERATION_REFLINK:billingData')}
                        </Typography>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: 20,
                              flexDirection: { md: 'row', xs: 'column' },
                           }}
                        >
                           <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <GrayTypography variant="body1">
                                 {t('COOPERATION_REFLINK:nameAndSurname')}:{' '}
                                 <SmallSpan>{`${formDetails.invoiceData.name} ${formDetails.invoiceData.surname}`}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 E-mail: <SmallSpan>{formDetails.invoiceData.email}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.phoneNumber')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.phoneNumber}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.companyName')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.companyName}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.taxID')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.taxId}</SmallSpan>
                              </GrayTypography>
                           </Box>
                           <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.country')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.address.country}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.city')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.address.city}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.postCode')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.address.zipCode}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.street')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.address.street}</SmallSpan>
                              </GrayTypography>
                              <GrayTypography variant="body1">
                                 {t('TRANSLATION:formFields.houseNumber')}:{' '}
                                 <SmallSpan>{formDetails.invoiceData.address.houseNumber}</SmallSpan>
                              </GrayTypography>
                           </Box>
                        </Box>
                     </FlexCard>
                     <FlexCard>
                        <Typography sx={{ pb: 1 }} color="primary" variant="h5">
                           {t('COOPERATION_REFLINK:shippingData')}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                           <GrayTypography variant="body1">
                              {t('TRANSLATION:formFields.country')}:{' '}
                              <SmallSpan>{formDetails.invoiceData.shippingAddress.country}</SmallSpan>
                           </GrayTypography>
                           <GrayTypography variant="body1">
                              {t('TRANSLATION:formFields.city')}:{' '}
                              <SmallSpan>{formDetails.invoiceData.shippingAddress.city}</SmallSpan>
                           </GrayTypography>
                           <GrayTypography variant="body1">
                              {t('TRANSLATION:formFields.postCode')}:{' '}
                              <SmallSpan>{formDetails.invoiceData.shippingAddress.zipCode}</SmallSpan>
                           </GrayTypography>
                           <GrayTypography variant="body1">
                              {t('TRANSLATION:formFields.street')}:{' '}
                              <SmallSpan>{formDetails.invoiceData.shippingAddress.street}</SmallSpan>
                           </GrayTypography>
                           <GrayTypography variant="body1">
                              {t('TRANSLATION:formFields.houseNumber')}:{' '}
                              <SmallSpan>{formDetails.invoiceData.shippingAddress.houseNumber}</SmallSpan>
                           </GrayTypography>
                        </Box>
                     </FlexCard>
                     <FlexCard>
                        <Typography sx={{ pb: 1 }} color="primary" variant="h5">
                           {t('COOPERATION_REFLINK:typeOfCompany')}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                           <GrayTypography variant="body1">
                              {t('TRANSLATION:formFields.shopEmployees')}:{' '}
                              <SmallSpan>{formDetails.invoiceData.shopPositions || '-'}</SmallSpan>
                           </GrayTypography>
                           <GrayTypography variant="body1">
                              {t('TRANSLATION:formFields.workshopEmployees')}:{' '}
                              <SmallSpan>{formDetails.invoiceData.workshopPositions || '-'}</SmallSpan>
                           </GrayTypography>
                        </Box>
                     </FlexCard>
                  </Box>
               </Box>
               <FlexCard sx={{ m: 5 }}>
                  <CooperationDetailsForm formDetails={formDetails} token={searchParams.get('uuid') || ''} />
               </FlexCard>
            </Box>
         ) : (
            <div
               style={{
                  marginTop: 50,
                  padding: 5,
                  width: '100%',
                  minHeight: 'calc(100vh - 153px)',
                  textAlign: 'center',
               }}
            >
               <CircularProgress />
            </div>
         )}
         <Footer />
      </Box>
   )
}
