import { ClientViewPricesType } from '../types'

export default function changeStateClientPrice(
   setClientPrices: (value: React.SetStateAction<ClientViewPricesType>) => void,
   newClientPrice: number,
   uuid: string,
   taxRate: number
) {
   setClientPrices((prevState) => {
      const newState = prevState.map((product) => {
         if (product.uuid === uuid) {
            return {
               ...product,
               clientPriceNet: Number(newClientPrice.toFixed(2)),
               clientPriceGross: Number((newClientPrice * (1 + taxRate / 100)).toFixed(2)),
            }
         }
         return product
      })

      return newState
   })
}
