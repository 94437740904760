import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNavigate } from 'react-router-dom'

interface Props {
   orderUuid: string
   handleClose: () => void
}

export default function ClientOrderCreatedNotification({ orderUuid, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'NOTIFICATIONS'])
   const navigate = useNavigate()

   return (
      <div>
         <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
            {t('NOTIFICATIONS:clientOrderCreatedText')}
         </Typography>

         <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton
               variant="contained"
               onClick={() => navigate(`/dashboard/orders-history?uuid=${orderUuid}`)}
            >
               {t('NOTIFICATIONS:showOrder')}
            </ContainedButton>
         </Box>
      </div>
   )
}
