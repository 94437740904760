import { styled } from '@mui/material'
import Card from '@mui/material/Card'

const DefaultCorner = styled('div')({
   width: 35,
   height: 35,
   position: 'absolute',
   transition: '400ms',
})

export const EditCorner = styled(DefaultCorner)(({ theme }) => ({
   paddingTop: 8,
   paddingLeft: 10,
   bottom: -45,
   left: 155,
   cursor: 'pointer',
   borderLeft: '0px solid grey',
   borderTop: '0px solid grey',
   borderTopLeftRadius: '75%',
   boxShadow: '0 0 0.5em grey',
   '&:hover': {
      boxShadow: `0 0 1em ${theme.palette.primary.main}`,
      color: `${theme.palette.primary.main}`,
   },
}))

export const StyledCard = styled(Card)(({ theme }) => ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   position: 'relative',
   width: 160,
   height: 160,
   borderRadius: 5,
   backgroundColor: 'rgba(255,255,255,0.7)',
   '&:hover': {
      boxShadow: `0 0 1.5em ${theme.palette.primary.dark}`,
      '& > div': {
         '&:nth-of-type(1)': {
            left: 0,
            bottom: 0,
         },
         '&:nth-of-type(2)': {
            left: 125,
            bottom: 0,
         },
      },
   },
}))

export const DeleteCorner = styled(DefaultCorner)({
   bottom: -30,
   left: -30,
   cursor: 'pointer',
   paddingTop: 8,
   paddingLeft: 6,
   borderRight: '0px solid grey',
   borderTop: '0px solid grey',
   borderTopRightRadius: '75%',
   boxShadow: '0 0 0.5em grey',
   transition: '400ms',
   '&:hover': {
      boxShadow: '0 0 1.2em red',
      color: 'red',
   },
})

export const StyledImg = styled('img')({
   height: 25,
})
