import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useRemoveLocationMutation } from 'shared/store/useLocations'
import { RemoveLocationPayload } from 'shared/store/useLocations/types'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'

interface Props {
   locationUuid?: string
   handleClose: () => void
}

export default function DeleteLocation({ locationUuid, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const { mutate, isLoading } = useRemoveLocationMutation()

   function deleteLocation() {
      const payload: RemoveLocationPayload = locationUuid ? { locationUuid } : {}
      mutate(payload, {
         onSettled: () => handleClose(),
      })
   }

   return (
      <div>
         <Typography variant="body1" sx={{ mt: 1, mb: 4, color: 'text.secondary' }}>
            {t('LOCATIONS:deleteLocationText')}
         </Typography>

         <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <OutlinedButton onClick={handleClose} variant="outlined">
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton disabled={isLoading} onClick={deleteLocation} variant="contained">
               {t('TRANSLATION:delete')}
            </ContainedButton>
         </Box>
      </div>
   )
}
