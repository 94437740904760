import { Yup } from 'shared/lib'

const schema = Yup.object().shape({
   loginParameter: Yup.string(),
   passwordParameter: Yup.string(),
   additionalParameter: Yup.string(),
   firstDepartment: Yup.string(),
   secondDepartment: Yup.string(),
   afterSalesBonus: Yup.number().nullable().min(0),
   thirdDepartment: Yup.string(),
   minLogisticsCurrency: Yup.string().oneOf(['PLN', 'EUR', 'CZK']),
   deliveryDepartment: Yup.string().required(),
   logisticMinimums: Yup.number().required(),
   fv: Yup.boolean(),
   deliveryType: Yup.string().nullable(),
   wz: Yup.boolean(),
   paymentsMethod: Yup.string().nullable().oneOf(['FV', 'WZ', 'DEFAULT', null]),
})

export default schema
