import React, { useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import RadioField from 'shared/components/RadioButton'
import useCountriesQuery from 'shared/store/useCountries'
import SelectField from 'shared/components/SelectField'
import InputField from 'shared/components/InputField'

import { DeliveryType, FormikDeliveryAddressType, ReflinkDataType, UpdateAddressPayload } from '../../types'
import { addressSchema } from './schema'
import { updateAddressService } from '../../services/updateAddressService'

interface Props {
   handleIsValid: (isValid: boolean) => void
   updateData: (data: ReflinkDataType) => void
   deliveryType: DeliveryType
   deliveryAddress: PropType<ReflinkDataType, 'deliveryAddress'>
}

export default function AddressData({ handleIsValid, updateData, deliveryAddress, deliveryType }: Props) {
   const { data: countriesData } = useCountriesQuery()
   const { t } = useTranslation(['TRANSLATION', 'CLIENT_CART'])
   const { addNotification } = useNotificationContext()
   const [countryOptions, setCountryOptions] = useState([
      {
         name: '',
         value: '',
      },
   ])

   const formik = useFormik<FormikDeliveryAddressType>({
      initialValues: {
         street: '',
         houseNumber: '',
         zipCode: '',
         city: '',
         country: 'Polska',
         deliveryType: 'PICK_UP',
      },
      validationSchema: addressSchema,
      onSubmit: handleSubmit,
   })

   const { values, isValid, setValues } = formik

   function handleSubmit() {
      const payload: UpdateAddressPayload = {
         deliveryType: values.deliveryType,
         deliveryAddress: {
            ...values,
         },
      }
      const uuid = window.localStorage.getItem('uuid')
      const token = window.localStorage.getItem('reflink-verify-token')
      if (uuid && token) {
         handleIsValid(false)
         updateAddressService(uuid, token, payload)
            .then((res) => {
               if (res) {
                  if (res.status === 200) {
                     addNotification('successSave', 'success')
                     updateData(res.data)
                  }
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => handleIsValid(true))
      }
   }

   useEffect(() => {
      if (countriesData && values && values.country === 'Poland') {
         const selectedCountry = countriesData[values.country]
         if (selectedCountry) {
            setCountryOptions(
               Object.keys(countriesData).map((item) => ({
                  name: countriesData[item].name,
                  value: item,
               }))
            )
         }
      }
   }, [countriesData, values])

   useEffect(() => {
      handleIsValid(isValid)
   }, [isValid])

   useEffect(() => {
      setValues({
         deliveryType: deliveryType || 'PICK_UP',
         street: deliveryAddress?.street || '',
         city: deliveryAddress?.city || '',
         country: deliveryAddress?.country || 'Poland',
         zipCode: deliveryAddress?.zipCode || '',
         houseNumber: deliveryAddress?.houseNumber || '',
      })
   }, [])

   return (
      <Box sx={{ width: '100%', p: 2 }}>
         <FormikProvider value={formik}>
            <Typography variant="h6" color="primary">
               {t('CLIENT_CART:addressData.deliveryOptions')}
            </Typography>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                  <RadioField
                     name="deliveryType"
                     options={[
                        { label: t('CLIENT_CART:addressData.personalPickUp'), value: 'PICK_UP' },
                        {
                           label: t('CLIENT_CART:addressData.shipping'),
                           value: 'ADDRESS',
                        },
                     ]}
                  />
                  <SelectField
                     inputDisabled={values.deliveryType === 'PICK_UP'}
                     name="country"
                     inputVariant="outlined"
                     label={t('TRANSLATION:formFields.country')}
                     options={countryOptions}
                  />
                  <Box
                     sx={{
                        display: 'flex',
                        gap: { md: 4, xs: 1 },
                        flexDirection: { md: 'row', xs: 'column' },
                     }}
                  >
                     <InputField
                        name="city"
                        disabled={values.deliveryType === 'PICK_UP'}
                        label={t('TRANSLATION:formFields.city')}
                     />
                     <InputField
                        name="zipCode"
                        disabled={values.deliveryType === 'PICK_UP'}
                        label={t('TRANSLATION:formFields.postCode')}
                     />
                     <InputField
                        name="street"
                        disabled={values.deliveryType === 'PICK_UP'}
                        label={t('TRANSLATION:formFields.street')}
                     />
                     <InputField
                        name="houseNumber"
                        disabled={values.deliveryType === 'PICK_UP'}
                        label={t('TRANSLATION:formFields.houseNumber')}
                     />
                  </Box>
                  <ContainedButton disabled={!isValid} variant="contained" type="submit">
                     {t('TRANSLATION:save')}
                  </ContainedButton>
               </Box>
            </Form>
         </FormikProvider>
      </Box>
   )
}
